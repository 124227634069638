import { NgModule } from '@angular/core';
import { TableItemSizeDirective } from './tableItemSize.directive';


@NgModule({
  declarations: [TableItemSizeDirective],
  imports: [],
  exports: [TableItemSizeDirective]
})
export class TableVirtualScrollModule {
}