import { Component, OnInit, ViewChild } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { LoggingService } from 'src/app/logging/logging.service';
import { Runs } from '../models/runs';
import { RunsService } from './runs.service';
import { AddrunsComponent } from '../addruns/addruns.component';
import { cvxActionConfiguration } from 'src/app/shared/cvxActionButton/cvxActionConfiguration';
import { TableVirtualScrollDataSource } from 'src/app/shared/tableVirtualScroll/tableVirtualScrollDataSource';
import { CvxProgressService } from 'src/app/shared/cvx-progress/cvx-progress.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { RolesService } from 'src/app/auth/roles.service';
import { rowfadeOut } from 'src/app/shared/animations/animations';
@Component({
  selector: 'app-runs',
  templateUrl: './runs.component.html',
  styleUrls: ['./runs.component.css'],
  animations: [rowfadeOut]
})
export class RunsComponent implements OnInit {

  filterValues = {};

  public dataSource: MatTableDataSource<Runs>;

  public displayedColumns: string[] = ['select', 'shoreBaseName', 'runName', 'isActive', "copy"];
  public selection = new SelectionModel<Runs>(true, []);
  public dataSource_confirmruns: MatTableDataSource<Runs>;

  public data: Runs[];
  public selectedData: Runs[];
  public step = 1;
  public filterValue = '';
  public hasDeletePermissions: boolean;
  public deleteRole: string;

  public actions: cvxActionConfiguration[];

  @ViewChild(MatSort, { static: false }) sort: MatSort;

  constructor(private runsService: RunsService,
    public dialog: MatDialog,
    private logger: LoggingService,
    private progress: CvxProgressService,
    private rolesService: RolesService) { }

  public ngOnInit() {
    try {
      this.initialize();

    } catch (error) {
      this.logger.log(`Error Loading Runs: ${error}`);
    }
  }

  private initialize() {
    this.selectedData = [];
    this.data = [];

    this.dataSource = new TableVirtualScrollDataSource<Runs>();
  }

  public async ngAfterViewInit() {
    try {
      this.getRuns();
      await this.initializeActions();

    } catch (error) {
      this.logger.log(`Error initializing data/actions: ${error}`);
    }
  }

  private async initializeActions(): Promise<void> {
    this.deleteRole = environment.adminRole;
    this.hasDeletePermissions = await this.rolesService.isInGroup(this.deleteRole);

    this.actions = [
      {
        name: 'add',
        icon: 'add',
        display: 'insert',
        disabled : false,
      },
      {
        name: 'delete',
        icon: 'delete',
        display: 'delete',
        disabled: !this.hasDeletePermissions
      }
    ];
  }

  public onAction(action: cvxActionConfiguration): void {
    if (action.name === 'add') {
      this.onAdd();
    } else {
      this.onDelete();
    }
  }

  public onFilter(value: string): void {
    this.dataSource.filter = value.trim().toLocaleLowerCase();
  }

  public onClear(): void {
    this.filterValue = '';
    this.onFilter(this.filterValue);
  }

  public onCopyClick($event: any, copyRunsRow: Runs) {
    $event.stopPropagation();
    let row:Runs = {...copyRunsRow};
    row.runID = 0;
    row.mode = "Add";

    let dialogConfig: MatDialogConfig = {
      maxWidth: '100vw',
      maxHeight: '100vh',
      width: '975px',
      disableClose: false,
      autoFocus: true,
      hasBackdrop: true,
      data: row
    };

    const dialogRef = this.dialog.open(AddrunsComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        let typeData: Runs = result;

        this.insert(typeData);
        return true;
      }
    });
  }

  public onAdd() {
    let dialogConfig: MatDialogConfig = {
      width: '550px',
      disableClose: false,
      autoFocus: true,
      hasBackdrop: true
    };

    const dialogRef = this.dialog.open(AddrunsComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        let run: Runs = result;

        this.insert(run);

        return true;
      }
    });
  }

  public edit($event: any, row: Runs) {
    row.mode = "Edit";
    let dialogConfig: MatDialogConfig = {
      width: '550px',
      disableClose: false,
      autoFocus: true,
      hasBackdrop: true,
      data: row
    };

    const dialogRef = this.dialog.open(AddrunsComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      row.shoreBaseName = result.shoreBaseName;
      row.runName = result.runName;
      row.activeInactive = result.activeInactive;
      row.isActive = result.isActive;

      this.update(row);

      return true;
    });
  }

  public onSelectRow($event: any, row: Runs) {
    if ($event.checked) {
      this.logger.log(row);
      this.selectedData.push(row);
    } else {
      this.logger.log(row);
      this.selectedData.splice(this.selectedData.indexOf(row), 1);
    }
  }

  public onConfirm() {
    this.dataSource = new MatTableDataSource<Runs>(this.selectedData);
  }

  public onDelete(): void {
    // TODO: confirm deletion if true then delete
    // if (onConfirm(deleteRecords) {
    //this.confirmedLocations.forEach(row => {
    //   this.delete(row);
    // });
    //})
    this.selectedData.forEach(row => {
      this.delete(row);
    });

    this.setTable();
  }


  private getRuns(): void {
    this.progress.spin$.next(true);
    this.runsService.get().subscribe(
      runsList => {
        this.data = runsList;
        this.setTable();
        this.progress.spin$.next(false);
      },
      error => {
        this.logger.log(`Errors occurred loading run, ${error}`);
        this.progress.spin$.next(false);
      }
    );
  }

  private setTable() {
    this.dataSource = new TableVirtualScrollDataSource<Runs>(this.data);
    this.dataSource.sort = this.sort;
  }

  public insert(run: Runs): void {
    this.progress.spin$.next(true)
    this.runsService.add(run).subscribe(
      result => {
        this.runsService.get(true).subscribe(
          runs => {
            this.data = [...runs];
            this.setTable();
            this.progress.spin$.next(false)
            this.logger.notify('Item Added', 'Dismiss');
          }
        );


      },
      error => {
        this.progress.spin$.next(false);
        this.logger.logError<Runs>(`Error while adding run: ${run.runID}
        |${error}`);
      }
    );
  }

  private update(run: Runs): void {
    this.progress.spin$.next(true);
    this.runsService.update(run.runID, run).subscribe(
      result => {
        this.setTable();
        this.progress.spin$.next(false);
        this.logger.notify('Item Updated', 'Dismiss');
      },
      error => {
        this.progress.spin$.next(false);
        this.logger.logError<Runs>(`Error while updating runs data: ${run.runID}
        |${error}`);
      }
    );
  }

  private delete(row: Runs): void {
    this.runsService.delete(row.runID, row).subscribe(
      _ => {
        this.selectedData.splice(this.selectedData.indexOf(row), 1);
        this.data.splice(this.data.indexOf(row), 1);
        this.setTable();
        this.logger.notify('Item Deleted', 'Dismiss');
      },
      error => this.logger.logError<Runs>(`Error deleting runs: ${row.runID}|${error}`)
    );
  }
}




