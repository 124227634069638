import { Runs } from "./runs";
import { Shorebase } from "./shorebase";

export class FlightRunSelection {

    public constructor(init?: Partial<FlightRunSelection>) {
        Object.assign(this, init);
    }
    heliport:Shorebase;
    flight:Runs;
    rundate:Date;

}