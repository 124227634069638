import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { Runs } from '../models/runs';
import { environment } from 'src/environments/environment';
import { LoggingService } from 'src/app/logging/logging.service';
import { RunsService } from '../runs/runs.service';
import { alphanumericValidator } from 'src/app/shared/validators/validators';

@Component({
  selector: 'app-addruns',
  templateUrl: './addruns.component.html',
  styleUrls: ['./addruns.component.css'],
  exportAs: 'app-addruns'
})


export class AddrunsComponent implements OnInit {
  public runs: Runs[];
  public runsForm: FormGroup;
  public runName: string = "";
  public showDupWarning: boolean = false;
  public statusList: string[] = ['Active', 'Inactive'];
  mode: string = "New";

  constructor(@Inject(MAT_DIALOG_DATA) public data: Runs,
    public dialogRef: MatDialogRef<AddrunsComponent>,
    private formBuilder: FormBuilder,
    private logger: LoggingService,
    private runsService: RunsService) { }

  public ngOnInit() {
    this.initialize();
  }

  public onSubmit(): void {
    this.showDupWarning = this.hasDuplicates();

    if (this.runsForm.valid && !this.showDupWarning) {
      this.dialogRef.close(this.save());
    }
  }

  private hasDuplicates(): boolean {
    this.runName = (this.runsForm.get('runName').value);
    return this.runs.some(row => {
      let IsDataDuplicate: boolean = (row.runName.toLowerCase() === this.runName.toLowerCase()) &&
        (row.shoreBaseName.toLowerCase() === (this.runsForm.get('shoreBaseName').value).toLowerCase()) 
      if (IsDataDuplicate) {
        if (this.data.runID) {
          return (row.runID === this.data.runID) ? false : true;
        } else {
          return IsDataDuplicate;
        }
      }
      return false;

    })
  }

  private getRunsData(): void {
    this.runsService.get().subscribe(
      Runs => {
        this.runs = Runs;
      },
      error => this.logger.log(`Errors occurred loading run, ${error}`)
    );
  }

  onReset(fgd: NgForm) {
    let currentMode = this.mode;
    this.showDupWarning = false;
    fgd.onReset();
    this.initialize();
    this.mode= currentMode;
  }

  closeDialog() {
    this.dialogRef.close();
  }

  public onChangeStatus(e: any, type: string) {
    this.runsForm.get(type).setValue(e.value === 'Active' ? true : false);
  }

  private initialize(): void {

    this.getRunsData();

    if (!this.data) {
      this.runsForm = this.createForm(this.formBuilder);
      this.mode = "Add";
    } else {
      this.runsForm = this.updateForm(this.formBuilder, this.data);
      this.mode = (this.data)?this.data.mode:"Add";
    }
  }

  private createForm(formBuilder: FormBuilder): FormGroup {
    this.mode = "Add";
    this.data = {
      shoreBaseName: "Galliano",
      shoreBaseID: 11,
      runName: "",
      runTime: "",
      isActive: "Active",
      heliport: "GAO",
      heliportId: null,
      runID: 0,
      lastUpdatedBy: environment.account,
      lastUpdatedDate: new Date(),
      flightName: "",
      activeInactive: true
    };

    return formBuilder.group({
      runID: [this.data.runID],
      shoreBaseID: [this.data.shoreBaseID],
      shoreBaseName: [{ value: this.data.shoreBaseName, disabled: true }, Validators.required],
      runName: [this.data.runName, Validators.required],
      isActive: [this.data.isActive, Validators.required],
      lastUpdatedBy: [this.data.lastUpdatedBy],
      lastUpdatedDate: [this.data.lastUpdatedDate],
      activeInactive: [this.data.activeInactive]
    });
  }

  private updateForm(formBuilder: FormBuilder, model: Runs): FormGroup {
    this.mode = "Edit";
    this.data = model;
    return formBuilder.group({
      runId: [model.runID],
      shoreBaseID: [model.shoreBaseID],
      shoreBaseName: [{ value: model.shoreBaseName, disabled: true }, Validators.required],
      runName: [model.runName, [Validators.required,Validators.minLength(3),Validators.maxLength(20),alphanumericValidator]],
      isActive: [model.isActive, Validators.required],
      lastUpdatedBy: [environment.account],
      lastUpdatedDate: [new Date()],
      activeInactive: [model.activeInactive]
    });
  }


  private save(): Runs {
    let runData: Runs;

    runData = new Runs(this.runsForm.value);

    // Copy over unchanged data (for updating)
    runData.shoreBaseName = this.data.shoreBaseName;
    return runData;
  }
}
