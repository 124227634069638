import { Injectable } from '@angular/core';
import { CalAngularService } from '@cvx/cal-angular';

@Injectable({
  providedIn: 'root'
})
export class RolesService {

  constructor(private _calService: CalAngularService) { }

  public hasRole(role: string): boolean {
    const roles = (this._calService.getAccount().idTokenClaims as any).roles;
    return roles.find(userRole => userRole === role) ? true : false;
  }

  public async isInGroup(group: string): Promise<boolean> {
    return this._calService.isInGroup(group);
  }
}
