import { Passenger } from "../dtms/models/passenger";

export class Utils {
    static trackPassChanges(oldList: Passenger[], newList: Passenger[]): boolean {
        let result = oldList?.filter(cp => newList.some(ncp => cp.firstName === ncp.firstName &&
            cp.lastName === ncp.lastName && cp.companyID === ncp.companyID &&
            cp.cargo === ncp.cargo && cp.weight === ncp.weight))
        if (result && result.length != newList.length) {
            return true;
        } else {
            return false;
        }
    }
}
