import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { LoggingService } from 'src/app/logging/logging.service';
import { environment } from 'src/environments/environment';
import { Company } from 'src/app/dtms/models/companies';
import { catchError, tap } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class CompaniesService {
  private api = `${environment.api}companies/`;
  private companies:Company[]=[];

  constructor(private http: HttpClient, private logger: LoggingService) { }

  get(): Observable<Company[]> {
    return this.companies.length > 1? of(this.companies) : this.http.get<Company[]>(this.api, { headers: environment.headers })
      .pipe(
        tap(companies => this.companies = companies),
        catchError(error => {
          return this.logger.logError<Company[]>(`Error: ${error.error}`, error);
        })
      );
  }

  add(com: Company): Observable<Company> {
    return this.http.post<Company>(this.api, com, { headers: environment.headers })
      .pipe(
        catchError(error => {
          return this.logger.logError<Company>(`Error: ${error.error}`, error);
        })
      );
  }

  update(id: number, com: Company): Observable<Company> {
    return this.http.put<Company>(`${this.api}${id}`, com, { headers: environment.headers })
      .pipe(
        catchError(error => {
          return this.logger.logError<Company>(`Error: ${error.error}`, error);
        })
      );
  }

  delete(id: number, com: Company): Observable<any> {

    const url = `${this.api}${id}`;

    return this.http.delete<Company>(url, { headers: environment.headers })
      .pipe(
        catchError(error => {
          return this.logger.logError<Company[]>(`Error: ${error.error}`, error);
        })
      );
  }


}
