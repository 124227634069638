import { Injectable } from '@angular/core';
import { FlightRunSelection } from '../../models/flight-runselection';

@Injectable({
  providedIn: 'root'
})
export class FlighRunSelectionService {

  private _flightRunSelection:FlightRunSelection;

  public getFlightRunSelection():FlightRunSelection {
    return this._flightRunSelection;
  } 
  public setFlightRunSelection(value:FlightRunSelection) {
    this._flightRunSelection = value;
  }
}
