import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';


fetch('/assets/config.json')
    .then((response) => response.json())
    .then((config) => {
      if (environment.production) {
        enableProdMode()
      }

      environment.appTitle = config.appTitle;
      environment.trayApi = config.trayApi;
      environment.api= config.api;
      environment.calConfig.clientId = config.clientId;
      environment.calConfig.redirectUri = config.redirectUrl
      environment.appInsights.instrumentationKey = config.instrumentationKey;
      environment.headers.set('Access-Control-Allow-Origin', config.redirectUrl);
      environment.calInterceptorConfig.consentScopes = [config.calInterceptorUrl];
      environment.adminRole = config.adminRole;
      environment.manifestingRole = config.manifestingRole;
      environment.readonlyRole = config.readonlyRole;
      
      platformBrowserDynamic().bootstrapModule(AppModule)
        .catch(err => console.error(err))
    });
