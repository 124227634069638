import { Component, OnInit, Inject, AfterViewInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { HelicopterTypes } from '../models/helicoptertypes';
import { HelicoptertypesService } from '../helicoptertypes/helicoptertypes.service';

@Component({
  selector: 'app-addhelicoptertypes',
  templateUrl: './addhelicoptertypes.component.html',
  styleUrls: ['./addhelicoptertypes.component.css']
})
export class AddhelicoptertypesComponent implements OnInit, AfterViewInit {

  public typeForm: FormGroup;
  public typeData: HelicopterTypes[];
  statusList: string[] = ['Active', 'Inactive'];
  frStatus: any = ['Yes', 'No']
  showDupWarning = false;
  typeName = "";

  constructor(public dialogRef: MatDialogRef<AddhelicoptertypesComponent>,
    @Inject(MAT_DIALOG_DATA) public helicopterType: HelicopterTypes,
    private formBuilder: FormBuilder,
    private HelicoptertypesService: HelicoptertypesService) { }

  public ngOnInit() {
    this.initialize();
  }

  public ngAfterViewInit() {
    this.load();
  }

  public onSubmit(): void {
    this.showDupWarning = this.hasDuplicates();

    if (this.typeForm.valid && !this.showDupWarning) {
      this.dialogRef.close(this.save());
    }
  }
  
  public onReset(fgd: NgForm) {
    this.showDupWarning = false;
    fgd.onReset();
    this.initialize();
  }
  
  public closeDialog() {
    this.dialogRef.close();
  }

  public onChangeStatus(e:any,type:string) {
    this.typeForm.get(type).setValue(e.value==='Active'?true:false);
  }

  private hasDuplicates() {
    const typeName: string = (this.typeForm.get('helicopterTypeName').value);
    return !this.helicopterType.helicopterTypeID && this.typeData.some(row => row.helicopterTypeName === typeName)
  }

  private initialize(): void {
    if (!this.helicopterType) {
      this.typeForm = this.createForm();
    } else {
      this.typeForm = this.setForm();
    }
  }

  private createForm(): FormGroup {
    this.helicopterType = {
      helicopterTypeID: 0,
      activeInactive: true,
      isActive: "Active",
      aftCgY1: 0,
      aftCgY2: 0,
      aftGwX1: 0,
      aftGwX2: 0,
      burnPerHour: 0,
      fcgY1: 0,
      fcgY2: 0,
      fePaxCount: 0,
      fgwX1: 0,
      fgwX2: 0,
      hasFlightEngineer: false,
      helicopterTypeName: '',
      isIFR: false,
      lastUpdatedBy: '',
      lastUpdatedDate: new Date(),
      lbsStop: 0,
      maxPassengerWeight: 0,
      maxToFuel: 0,
      maximumTow: 0,
      minStop: 0,
      model: '',
      passengerCapacity: 0,
      tas: 0,
    };

    return this.setForm(); 
  }

  private setForm(): FormGroup {
    return this.formBuilder.group({
      activeInactive: [this.helicopterType.activeInactive],
      isActive: [this.getStatus(this.helicopterType.activeInactive), Validators.required],
      hasFlightEngineer: [this.helicopterType.hasFlightEngineer],
      helicopterTypeID: [this.helicopterType.helicopterTypeID],
      lastUpdatedBy: [this.helicopterType.lastUpdatedBy],
      lastUpdatedDate: [this.helicopterType.lastUpdatedDate],
      aftCgY1: [this.helicopterType.aftCgY1, Validators.required],
      aftCgY2: [this.helicopterType.aftCgY2, Validators.required],
      aftGwX1: [this.helicopterType.aftGwX1, Validators.required],
      aftGwX2: [this.helicopterType.aftGwX2, Validators.required],
      burnPerHour: [this.helicopterType.burnPerHour, Validators.required],
      fcgY1: [this.helicopterType.fcgY1, Validators.required],
      fcgY2: [this.helicopterType.fcgY2, Validators.required],
      fePaxCount: [this.helicopterType.fePaxCount, Validators.required],
      fgwX1: [this.helicopterType.fgwX1, Validators.required],
      fgwX2: [this.helicopterType.fgwX2, Validators.required],
      helicopterTypeName: [this.helicopterType.helicopterTypeName, Validators.required],
      isIFR: [this.helicopterType.isIFR, Validators.required],
      lbsStop: [this.helicopterType.lbsStop, Validators.required],
      maxPassengerWeight: [this.helicopterType.maxPassengerWeight, Validators.required],
      maxToFuel: [this.helicopterType.maxToFuel, Validators.required],
      maximumTow: [this.helicopterType.maximumTow, Validators.required],
      minStop: [this.helicopterType.minStop, Validators.required],
      model: [this.helicopterType.model, Validators.required],
      passengerCapacity: [this.helicopterType.passengerCapacity, Validators.required],
      tas: [this.helicopterType.tas, Validators.min(1)]
    });
  }

  private save(): HelicopterTypes {
    let data: HelicopterTypes;

    data = new HelicopterTypes(this.typeForm.value);
    return data;
  }

  private load(): void {
    this.getHelicopterTypes();
  }

  private getHelicopterTypes(): void {
    this.HelicoptertypesService.get().subscribe(
      typesData => {
        this.typeData = typesData;
      });
  }

  private getStatus(status:boolean):string {
    return status?this.statusList[0]:this.statusList[1];
  }
}
