import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ManifestCalculationsService } from '../../manifest-calculations/manifest-calculations.service';
import { FlightSelection } from '../../models/flight-selection';
import { Leg } from '../../models/leg';
import { FlightInfoService } from '../flight-info.service';
import { FlightSelectionService } from '../flight-selection/flight-selection.service';

@Component({
  selector: 'app-fuelneeded',
  templateUrl: './fuelneeded.component.html',
  styleUrls: ['./fuelneeded.component.css']
})
export class FuelneededComponent implements OnInit {

  public fuelNeededForm: FormGroup;
  showDupWarning: boolean;
  get legs(): FormArray {
    return <FormArray>this.fuelNeededForm.get('legs')
  }
  public dialogTitle: string = "Departure Fuel"
  public fuelNeeded: number = 0;
  public flightTOW: number = 0;
  public flightMaxtoFuel: number;
  public netWeight: number = 0;
  public addedFuel: number = 0;
  public needFuelEnroute: boolean;
  public departureFuel: number = 0;
  public mindepartureFuel: number = 0;
  public manifestfuelNeededStatus: boolean = false;
  public refuelLocationsUpdated: boolean = false;
  public requiredFuelGap: number = -1;

  public flightselected: FlightSelection;
  constructor(@Inject(MAT_DIALOG_DATA) public manifestlegs: Leg[],
    public dialogRef: MatDialogRef<FuelneededComponent>,
    private flight: FlightSelectionService,
    private formBuilder: FormBuilder,
    private calculationService: ManifestCalculationsService,
    private flightInfoService: FlightInfoService) { }


  ngOnInit() {
    this.initialize();
  }

  private initialize(): void {
    this.mindepartureFuel = this.manifestlegs.find(l => l.legNumber === 1).fuel;

    this.fuelNeededForm = this.createForm();
    //subscribe to flight changes
    this.flight.onFlightSelection().subscribe((fs) => {
      if (fs) {
        this.flightselected = fs;
        this.flightMaxtoFuel = this.flightselected.helicopterType.maxToFuel;
        this.flightTOW = this.flightselected.helicopterType.maximumTow;
        this.calculateManifeshFuelNeeded();
        // Form creation is based on default values from Flight selection
        this.fuelNeededForm = this.createForm();
      }
    });





    // subscribe to departure fuel changes to set initial leg carrying fuel
    this.fuelNeededForm?.controls['departurefuel'].valueChanges
      .subscribe(df => {
        (<FormArray>this.fuelNeededForm.controls['legs'])
          .controls
          .find(c => c.get("legNumber").value === 1)
          .get('carryingFuel').setValue(df);
      });

  }

  private createForm(): FormGroup {
    return this.formBuilder.group(
      {
        departurefuel: [this.departureFuel, [Validators.required, Validators.min(this.mindepartureFuel), Validators.max(this.flightMaxtoFuel)]],
        legs: this.createLegs()
      }
    )

  }

  public calculateManifeshFuelNeeded() {
    this.fuelNeeded = this.calculationService.calculateFuelNeeded(this.manifestlegs, this.flightselected);
    this.netWeight = this.calculationService.calculateNetWeight(this.manifestlegs, this.flightselected);
    this.departureFuel = this.fuelNeeded >= this.flightMaxtoFuel ? this.flightMaxtoFuel : this.fuelNeeded;
  }

  public onSubmit(fgjb: NgForm) {
    // This condition needs a relook
    if (this.fuelNeededForm.controls['departurefuel'].invalid) {
      return false;
    }
    this.departureFuel = this.fuelNeededForm.controls['departurefuel'].value;

    if (this.departureFuel > 0 && (this.departureFuel < this.fuelNeeded) && !this.needFuelEnroute) {
      this.needFuelEnroute = true;
      this.dialogTitle = "Enroute Fuel";
    }
    // Condition to check if enough fuel is entered in first leg 
    else if (this.departureFuel > 0 && (this.departureFuel >= this.fuelNeeded) && !this.needFuelEnroute) {
      this.manifestlegs.find(leg => leg.legNumber === 1).carryingFuel = this.departureFuel;
      this.flightInfoService.isFuelWorkflowCompleted = true;
      this.dialogRef.close(this.manifestlegs);
    }
    else if (this.needFuelEnroute) {
      let totalcarryingFuel: number = this.fuelEntered();
      if (totalcarryingFuel >= this.fuelNeeded) {
        this.fuelNeededForm.value.legs.forEach((l) => {
          this.manifestlegs.find(leg => leg.legNumber === l.legNumber).addedFuel = l.carryingFuel;
        });
        this.flightInfoService.isFuelWorkflowCompleted = true;
        this.dialogRef.close(this.manifestlegs);
      }
    }
  }

  onReset(fgjd: NgForm) {
    this.showDupWarning = false;
    fgjd.onReset();
    this.initialize();
  }
  public closeDialog() {
    this.dialogRef.close();
  }

  //Create array model based on manifest legs
  public createLegs(): FormArray {
    const legsFormGroups = this.manifestlegs.map((x, i) =>
      this.formBuilder.group({
        legNumber: this.formBuilder.control(x.legNumber),
        origination: this.formBuilder.control(x.origination),
        carryingFuel: this.formBuilder.control(i == 0 ? this.departureFuel : x.carryingFuel)
      }));

    return this.formBuilder.array(legsFormGroups);
  }

  public fuelEntered(): number {
    let totalFuelEntered: number = 0;
    this.fuelNeededForm.value.legs.forEach((leg: Leg) => {
      totalFuelEntered = totalFuelEntered + leg.carryingFuel;
    });
    return totalFuelEntered;
  }

  public fuelGap(event: any): void {
    console.log(event);
    if (event.target.value > 0) {
      let fuelDiff = this.fuelNeeded - this.fuelEntered();
      this.requiredFuelGap = fuelDiff;
      this.manifestfuelNeededStatus = fuelDiff <= 0 ? true : false;
    } else {
      this.requiredFuelGap = -1;
    }
  }
}
