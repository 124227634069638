import { Component, OnInit, ViewChild } from '@angular/core';
import { PilotsService } from './pilots.service';
import { LoggingService } from 'src/app/logging/logging.service';
import { Pilot } from '../models/pilot';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { SelectionModel } from '@angular/cdk/collections';
import { AddpilotComponent } from 'src/app/dtms/addpilot/addpilot.component';
import { cvxActionConfiguration } from 'src/app/shared/cvxActionButton/cvxActionConfiguration';
import { TableVirtualScrollDataSource } from 'src/app/shared/tableVirtualScroll/tableVirtualScrollDataSource';
import { CvxProgressService } from 'src/app/shared/cvx-progress/cvx-progress.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { RolesService } from 'src/app/auth/roles.service';
import { rowfadeOut } from 'src/app/shared/animations/animations';
@Component({
  selector: 'app-pilots',
  templateUrl: './pilots.component.html',
  styleUrls: ['./pilots.component.css'],
  animations: [rowfadeOut]
})
export class PilotsComponent implements OnInit {
  public dataSource: MatTableDataSource<Pilot>;
  public displayedColumns: string[] = ['select', 'lastName', 'firstName', 'weight', 'initials', 'status', "copy"];
  public selection = new SelectionModel<Pilot>(true, []);
  public data: Pilot[];
  public selectedData: Pilot[];
  public filterValue = '';
  public hasDeletePermissions: boolean;
  public deleteRole: string;

  //Pagination
  public pageSize = 10;
  public currentPage = 0;
  public totalSize = 0;
  public array: any;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  @ViewChild(MatSort, { static: false }) sort: MatSort;

  public actions: cvxActionConfiguration[];
  constructor(
    private pilotsService: PilotsService,
    private logger: LoggingService,
    public dialog: MatDialog,
    private progress: CvxProgressService,
    private rolesService: RolesService
  ) { }

  public ngOnInit() {
    try {
      this.initialize();

    } catch (error) {
      this.logger.log(`Error Loading Pilots: ${error}`);
    }
  }

  private initialize() {
    this.data = [];
    this.selectedData = [];    
    
    this.dataSource = new TableVirtualScrollDataSource<Pilot>();
  }

  public async ngAfterViewInit() {
    try {
      this.getPilots();
      await this.initializeActions();

    } catch (error) {
      this.logger.log(`Error initializing data/actions: ${error}`);
    }
  }

  private async initializeActions(): Promise<void> {
    this.deleteRole = environment.adminRole;
    this.hasDeletePermissions = await this.rolesService.isInGroup(this.deleteRole);

    this.actions = [
      {
        name: 'add',
        icon: 'add',
        display: 'insert',
        disabled : false,
      },
      {
        name: 'delete',
        icon: 'delete',
        display: 'delete',
        disabled: !this.hasDeletePermissions
      }
    ];
  }

  public onAction(action: cvxActionConfiguration): void {
    if (action.name === 'add') {
      this.add();
    } else {
      this.onDelete();
    }
  }

  public onCopyClick($event: any, copyPilotRow: Pilot) {
    $event.stopPropagation();
    let row:Pilot = {...copyPilotRow};
    row.pilotID = 0;
    row.mode = "Add";

    let dialogConfig: MatDialogConfig = {
      // maxWidth: '100vw',
      //maxHeight: '100vh',
      width: '550px',
      disableClose: false,
      autoFocus: true,
      hasBackdrop: true,
      data: row
    };

    const dialogRef = this.dialog.open(AddpilotComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        let typeData: Pilot = result;

        this.insert(typeData);
        return true;
      }
    });
  }

  public add() {
    let dialogConfig: MatDialogConfig = {
      width: '550px',
      disableClose: false,
      autoFocus: true,
      hasBackdrop: true
    };

    const dialogRef = this.dialog.open(AddpilotComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        let pilot: Pilot = result;
        this.insert(pilot);

        return true;
      }
    });
  }

  public insert(pilot: Pilot): void {
    this.progress.spin$.next(true)
    this.pilotsService.add(pilot).subscribe(
      result => {
        this.pilotsService.getPilots(true).subscribe((pilots) => {
          this.data = [...pilots];
          this.setTable();
          this.progress.spin$.next(false)
          this.logger.notify('Item Added', 'Dismiss');
        })

      },
      error => {
        this.progress.spin$.next(false);
        this.logger.logError<Pilot>(`Error while inserting pilot data: ${pilot.firstName + pilot.lastName}
        |${error}`);
      }
    );
  }

  public edit($event: any, row: Pilot) {
    row.mode = "Edit";
    let dialogConfig: MatDialogConfig = {
      width: '550px',
      disableClose: false,
      autoFocus: true,
      hasBackdrop: true,
      data: row
    };

    const dialogRef = this.dialog.open(AddpilotComponent, dialogConfig);


    dialogRef.afterClosed().subscribe(result => {
      row.pilotID = result.pilotID;
      row.lastName = result.lastName;
      row.firstName = result.firstName;
      row.weight = result.weight;
      row.initials = result.initials;
      row.activeInactive = result.activeInactive;
      row.isActive = result.isActive

      this.update(result);

      return true;
    });
  }

  public onFilter(value: string): void {
    this.dataSource.filter = value.trim().toLocaleLowerCase();
  }

  public onClear(): void {
    this.filterValue = '';
    this.onFilter(this.filterValue);
  }

  public isAllSelected($event) {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  public masterToggle($event) {
    if ($event.checked) {
      this.dataSource.data.forEach(row => this.selection.select(row));
      this.selectedData = this.data;
    } else {
      this.dataSource.data.forEach(row => this.selection.deselect(row));
      this.selectedData = [];
    }
  }

  public onSelectRow($event: any, row: Pilot) {
    if ($event.checked) {
      this.logger.log(row);
      this.selectedData.push(row);
    } else {
      this.logger.log(row);
      this.selectedData.splice(this.selectedData.indexOf(row), 1);
    }
  }

  private getPilots(): any {
    this.progress.spin$.next(true);
    this.pilotsService.getPilots().subscribe(
      data => {
        this.data = data;
        this.setTable();
        this.dataSource.sort = this.sort;
        this.progress.spin$.next(false);
      },
      error => {
        this.progress.spin$.next(false);
        this.logger.log(`Errors occurred loading data, ${error}`);

      }
    );
  }

  public save(pilot: Pilot): void {
    this.progress.spin$.next(true);
    this.pilotsService.add(pilot).subscribe(
      result => {
        this.data.push(pilot);
        this.progress.spin$.next(false);
        this.dataSource = new MatTableDataSource<Pilot>(this.data);
      },
      error => {
        this.progress.spin$.next(false);
        this.logger.logError<Pilot>(`Error while saving pilot data: ${error}`);
      }
    );
  }

  public update(pilot: Pilot): void {
    this.progress.spin$.next(true);
    this.pilotsService.update(pilot.pilotID, pilot).subscribe(
      result => {
        // alternative update - https://stackoverflow.com/a/48500099/12233405
        this.setTable();
        this.progress.spin$.next(false);
        this.logger.notify('Item Updated', 'Dismiss');
      },
      error => {
        this.progress.spin$.next(false);
        this.logger.logError<Pilot>(`Error while updating pilot data: ${pilot.pilotID}
        |${error}`);
      }
    );
  }

  public onDelete(): void {
    // if -> onConfirm(deleteRecords) 
    // confirmed forEach(row => {
    //   delete the row

    this.selectedData.forEach(row => {
      this.delete(row);
    });

    this.selectedData = [];
  }
  public delete(row: Pilot): void {
    this.pilotsService.delete(row.pilotID, row).subscribe(
      _ => {
        this.data.splice(this.data.indexOf(row), 1);
        this.selectedData.splice(this.selectedData.indexOf(row), 1);
        this.setTable();
        this.logger.notify('Item Deleted', 'Dismiss');
      },
      error => this.logger.logError<Pilot>(`Error deleting pilot: ${row.pilotID}|${error}`)
    );
  }

  private setTable() {
    this.dataSource = new TableVirtualScrollDataSource<Pilot>(this.data);
    this.dataSource.sort = this.sort;
  }
}
