import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { formatDate } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IManifest } from '../../models/manifest';
import { PilotsService } from '../../pilots/pilots.service';
import { Pilot } from '../../models/pilot';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Helicopter } from '../../models/helicopter';
import { FlightSelectionService } from './../flight-selection/flight-selection.service';
import { FlightSelection } from '../../models/flight-selection';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'flight',
  templateUrl: './flight.component.html',
  styleUrls: ['./flight.component.css'],
  exportAs: 'flight'
})
export class FlightComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() manifest: IManifest;
  @Output() manifestUpdated = new EventEmitter<{ manifest: IManifest, valid: boolean }>();

  public flightForm: FormGroup;
  public chargeTypes: string[];
  public pilots$: Observable<Pilot[]>;
  public pilotsFiltered: Observable<Pilot[]>;
  public pilots2Filtered: Observable<Pilot[]>;
  public pilots: Pilot[];
  public pilots2: Pilot[];
  public pilots1$: Observable<Pilot[]>;
  public pilots2$: Observable<Pilot[]>;
  public flightSelection: FlightSelection;
  public helicopter: Helicopter;
  public dataSource: MatTableDataSource<Helicopter>;
  public crewTotalWeight: number = 0;
  public changesAreApplied: boolean = false;

  public isPilotDisabled: boolean = true;
  constructor(private formBuilder: FormBuilder,
    private flightSelectionService: FlightSelectionService,
    private pilotsService: PilotsService) { }

  public ngOnInit(): void {
    try {
      this.pilotsService.getPilots().subscribe(results => {
        this.pilots = results;
      });
      this.initialize();
    } catch (err) {
      console.log("FlightComponent-ngonit=", err);
    }
  }

  public ngAfterViewInit(): void {
    try {
      this.subscribeToChanges();
    } catch (err) {
      console.log("FlightComponent-ngafterviewinit=", err);
    }
  }

  public ngOnChanges(_changes: SimpleChanges): void {
    if (this.changesAreApplied) {
      return;
    }
    if (this.flightForm == undefined || this.flightForm.controls == undefined) {
      return;
    }
    this.flightForm = this.setForm();
    this.flightForm.get("pilot2").enable();
    this.subscribeToChanges();
    this.calculateCrewWeight(this.manifest.pilot1Id, this.manifest.pilot2Id);
  }

  private initialize(): void {
    this.flightForm = this.formBuilder.group({
      flightNumber: [''],
      takeoffTime: [''],
      returnTime: [''],
      pilot1: [''],
      pilot2: [''],
      charge: [''],
      aircraftWeight: [''],
      moment: [''],
      crewWeight: [''],
      stateTime: [''],
      flightTime: [''],
      windSpeed: [''],
      windDirection: ['']
    });
    this.chargeTypes = ['Pax Miles', 'Hourly'];
    this.helicopter = new Helicopter();

    if (!this.manifest) {
      this.flightForm = this.createForm();
    } else {
      this.flightForm = this.setForm();
      this.calculateCrewWeight(this.manifest.pilot1, this.manifest.pilot2);
    }
    this.loadPilots();
    this.flightForm.get("pilot1").valueChanges.subscribe(_fValue => {
      this.pilotsFiltered = this.pilots1$.pipe(map(pilots => pilots.filter(pilot => pilot.fullName.indexOf(this.flightForm.get("pilot1").value.toString().toUpperCase()) != -1)));
      this.flightForm.get("pilot2").enable();
    });
  }

  displayFn(pilot: Pilot): string {
    return pilot && pilot.fullName ? pilot.fullName : '';
  }

  onChangeSIC(_fValue: any) {
    this.pilots2Filtered = this.pilots2$.pipe(map(pilots => pilots.filter(pilot => pilot.fullName.indexOf(this.flightForm.get("pilot2").value.toString().toUpperCase()) != -1)));
  }

  onChangePIC(_fValue: any) {
    this.pilotsFiltered = this.pilots1$.pipe(map(pilots => pilots.filter(pilot => pilot.fullName.indexOf(this.flightForm.get("pilot1").value.toString().toUpperCase()) != -1)));
  }

  private loadPilots(): void {
    this.pilotsService.getPilots().subscribe(results => {
      this.pilots = results;
      if ((this.flightForm.controls.pilot1.value == "" || (typeof this.flightForm.controls.pilot1.value) == "string"
        || this.flightForm.controls.pilot1.value == null) && this.manifest.pilot1Id != null) {
        let pilot1 = this.pilots.filter(pilot => pilot.pilotID.toString() === this.manifest.pilot1Id);
        this.flightForm.controls.pilot1.setValue(pilot1[0]);
      }

      this.isPilotDisabled = (this.manifest.pilot1Id == "") ? true : false;

      if (this.pilots) {
        this.pilots2 = this.pilots.filter(pilot => pilot.pilotID.toString() != this.manifest.pilot1Id);

        if (this.flightForm.controls.pilot2.value == "" || this.flightForm.controls.pilot2.value == null
          || (typeof this.flightForm.controls.pilot2.value) == "string" && this.manifest.pilot2Id != null) {
          let pilot2 = this.pilots.filter(pilot => pilot.pilotID.toString() === this.manifest.pilot2Id);
          this.flightForm.get("pilot2").enable();
          this.flightForm.controls.pilot2.setValue(pilot2[0]);
        }

        let pilot1Data = this.pilots.filter(pilot => pilot.pilotID.toString() == this.manifest.pilot1Id)[0];
        let pilot2Data = this.pilots.filter(pilot => pilot.pilotID.toString() == this.manifest.pilot2Id)[0];
        this.crewTotalWeight = (this.manifest.pilot1Id ? pilot1Data.weight : 0) + (this.manifest.pilot2Id ? pilot2Data.weight : 0);
        if (this.flightForm.get('crewWeight').value == "")
          this.flightForm.get('crewWeight').setValue(this.crewTotalWeight);

        this.calculateCrewWeight(this.manifest.pilot1Id, this.manifest.pilot2Id);
      }
    });
  }

  private subscribeToChanges(): void {
    //  this.pilotsService.getPilots().subscribe(results => {
    //  this.pilots = results
    //})
    this.pilots$ = this.pilotsService.getPilots();
    this.pilots1$ = this.pilots$;
    this.pilots2$ = this.pilots$;
    this.pilotsFiltered = this.pilots$;
    this.pilots2Filtered = this.pilots2$;

    // subscribe to flight changes and update view / model appropriately
    this.flightSelectionService.onFlightSelection().subscribe({
      next: (selection) => this.updateFlightSelections(selection)
    });
    this.flightForm.valueChanges
      .subscribe(result => {
        //Added by Ravi
        //TechDebt: this assignment not required but had to due to crewweight fixes
        // Please do clean up this at later point 
        let updatedManifest = {
          ...this.manifest,
          windDirection: this.flightForm.get('windDirection').value,
          windSpeed: this.flightForm.get('windSpeed').value,
          flightNumber: this.flightForm.get('flightNumber').value,
          stateTime: this.flightForm.get('stateTime').value,
          flightTime: this.flightForm.get('flightTime').value,
          takeoffTime: this.flightForm.get('takeoffTime').value,
          returnTime: this.flightForm.get('returnTime').value
        }
        this.manifestUpdated.emit({ manifest: updatedManifest, valid: this.flightForm.valid });
        console.log(result);
      });
    this.flightForm.get('pilot1').valueChanges.subscribe(
      _result => {
        let pilot1Id = this.getPilotId('pilot1');
        this.manifest.pilot1 = this.flightForm.get('pilot1').value;
        this.manifest.pilot1Id = pilot1Id;
        this.calculateCrewWeight(this.manifest.pilot1, this.manifest.pilot2);
        this.flightForm.get("pilot2").enable();
      }
    );
    this.flightForm.get('pilot2').valueChanges.subscribe(
      _result => {

        let pilot2 = this.flightForm.get('pilot2').value;
        if (typeof pilot2 == "object") {
          this.manifest.pilot2 = pilot2.pilotID;
          this.manifest.pilot2Id = pilot2.pilotID;
        } else {
          this.manifest.pilot2Id = pilot2;
        }
        this.manifest.pilot2 = this.flightForm.get('pilot2').value;
        this.calculateCrewWeight(this.manifest.pilot1, this.manifest.pilot2);
      }
    );


  }
  private getPilotId(pilotName: string): any {
    let pilotId = ""
    let pilot1 = this.flightForm.get(pilotName).value;
    if (typeof pilot1 == "object" && pilot1 != null) {
      pilotId = pilot1?.pilotID;
    } else {
      pilotId = pilot1;
    }
    return pilotId;
  }


  private createForm(): FormGroup {
    this.manifest = {
      manifestId: 0,
      manifestGUID: 0,
      flightNumber: 0,
      takeoffTime: null,
      returnTime: null,
      pilot1: '',
      pilot2: '',
      pilot1Id: '',
      pilot2Id: '',
      charge: 'Pax Miles',
      basicWeight: 0,
      articaftMoment: 0,
      crewWeight: 0,
      stateTime: .4,
      flightTime: 0,
      part135: false,
      part91: true,
      ifr: true,
      burnPerHour: 0,
      cgRangeHigh: 0,
      cgRangeLow: 0,
      centerOfGravity: 0,
      lbsStop: 0,
      windDirection: 0,
      windSpeed: 0,
      xPonder: 0,
      leg: [],
      manifestLegs: [],
      location: '',
      PAX: 0,
      fuel: 0,
      fuelMoment: 0,
      cg: 0,
      range: 0,
      tow: 0,
      maxtow: 0,
      crew: 0,
      cargo: 0
    };

    return this.setForm();
  }

  private setForm(): FormGroup {
    let takeofftime = this.manifest.takeoffTime === null ? '' : formatDate(this.manifest?.takeoffTime, 'HH:mm', 'en');
    let returntime = this.manifest.returnTime === null ? '' : formatDate(this.manifest?.returnTime, 'HH:mm', 'en');
    try {
      if (this.pilots != undefined) {
        this.manifest.pilot1 = this.pilots.find(p => p.pilotID.toString() == this.manifest.pilot1Id);
        this.manifest.pilot2 = this.pilots.find(p => p.pilotID.toString() == this.manifest.pilot2Id);
      }
    }
    catch (error) {
      console.log("err", error);
    }
    return this.formBuilder.group({
      flightNumber: [this.manifest.flightNumber, [Validators.required, Validators.min(1)]],
      takeoffTime: [takeofftime],
      returnTime: [returntime],
      pilot1: [this.manifest.pilot1, Validators.required],
      pilot2: [{ value: this.manifest.pilot2, disabled: true}],
      charge: [this.manifest.charge, Validators.required],
      aircraftWeight: [{ value: this.manifest.basicWeight, disabled: true }, Validators.required],
      moment: [{ value: this.manifest.articaftMoment, disabled: true }, Validators.required],
      crewWeight: [{ value: this.crewTotalWeight }, Validators.required],
      stateTime: [this.manifest.stateTime, Validators.required],
      flightTime: [this.manifest.flightTime, Validators.required],
      windSpeed: [this.manifest.windSpeed, Validators.required],
      windDirection: [this.manifest.windDirection, Validators.required],
      part135: [this.manifest.part135],
      part91: [this.manifest.part91],
      ifr: [this.manifest.ifr]
    });
  }
  private saveChanges(): FlightSelection {
    const selection: FlightSelection = this.flightForm.value
    return selection;
  }

  private updateFlightSelections(selection: FlightSelection) {
    // This logic can be cleaned up a bit depending on how we load the rest of the chosen flight info -> flight & helicopter (tail number)
    if (selection && selection.tailNumber) {
      this.helicopter = selection.tailNumber;
      this.manifest.basicWeight = selection.tailNumber.basicWeight;
      this.manifest.articaftMoment = selection.tailNumber.airCraftMoment;

      this.flightForm.patchValue({
        aircraftWeight: this.manifest.basicWeight,
        moment: this.manifest.articaftMoment
      });
    }
  }

  private calculateCrewWeight(pilot1, pilot2): void {

    let pilot1Id = (pilot1 == "" || pilot1 == undefined || typeof (pilot1) == 'object') ? this.getPilotId("pilot1") : pilot1;
    let pilot2Id = (pilot2 == "" || pilot2 == undefined || typeof (pilot2) == 'object') ? this.getPilotId("pilot2") : pilot2;

    if (this.pilots != undefined) {
      try {
        this.crewTotalWeight = (pilot1Id ? this.pilots.find(p => p.pilotID.toString() == pilot1Id?.toString())?.weight : 0) +
          (pilot2Id ? this.pilots.find(p => p.pilotID.toString() == pilot2Id?.toString())?.weight : 0);
      }
      catch (err) {
        console.log("error while getting the data", err);
        this.crewTotalWeight = 0;
      }
    }

    this.manifest.crewWeight = this.crewTotalWeight = NaN ? 0 : this.crewTotalWeight;
    this.flightForm.get('crewWeight').setValue(this.crewTotalWeight);
    this.pilots2$ = pilot1Id ? this.pilots$.pipe(map(pilots => pilots.filter(pilot => pilot.pilotID != pilot1Id))) : this.pilots$;
    this.pilots2Filtered = this.pilots2$;
    this.pilots1$ = pilot2Id ? this.pilots$.pipe(map(pilots => pilots.filter(pilot => pilot.pilotID != pilot2Id))) : this.pilots$;
    this.pilotsFiltered = this.pilots1$;
    this.manifest.pilot2 = this.flightForm.get('pilot2').value;
    this.manifest.crewWeight = this.crewTotalWeight;
    this.manifestUpdated.emit({ manifest: this.manifest, valid: this.flightForm.valid });
  }

  public updateFlightTime(value: number): void {
    this.flightForm.get('flightTime').setValue(value);
  }

}

