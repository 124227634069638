import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { LoggingService } from 'src/app/logging/logging.service';
import { Passenger } from '../models/passenger';
import { PassengersDataService } from './passengers-data.service';

@Component({
  selector: 'app-passengers',
  templateUrl: './passengers.component.html',
  styleUrls: ['./passengers.component.css']
})
export class PassengersComponent implements OnInit {

  public dataSource: MatTableDataSource<Passenger>;
  public displayedColumns: string[] = ['select', 'lastName', 'firstName', 'passengerWeight', 'company', 'chargeCode', 'part135Code'];
  public selection = new SelectionModel<Passenger>(true, []);
  public passengers: Passenger[];
  public selectedPassengers: Passenger[];

  public filterValue = '';

  @ViewChild(MatSort, {static: false}) sort: MatSort;
    

  constructor(
    private passengersService: PassengersDataService,
    private logging: LoggingService
  ) { }

  ngOnInit() {
    try {
      this.passengers = [];
      this.selectedPassengers = [];
      this.getPassengers();
    } catch (error) {
      this.logging.log(`Error Loading passengers: ${error}`);
    }
  }
  public onFilter(value: string): void {
    this.dataSource.filter = value.trim().toLocaleLowerCase();
  }

  public onClear(): void {
    this.filterValue = '';
    this.onFilter(this.filterValue);
  }
  public onCompleteColumn(dataSource) {
    dataSource.data.forEach(element => {
      console.log(element);
    });
  }

  public isAllSelected($event) {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  public onCompleteRow(dataSource) {
    dataSource.data.forEach(element => {
      console.log(element);
    });
  }
  public masterToggle($event) {
    if ($event.checked) {
      this.onCompleteRow(this.dataSource);
    }
    this.isAllSelected($event) ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }

  public onSelectRow($event: any, row: Passenger) {
    if ($event.checked) {
      console.log(row);
      this.selectedPassengers.push(row);
    } else {
      console.log(row);
      this.selectedPassengers.splice(this.selectedPassengers.indexOf(row), 1);
    }
  }

  private getPassengers(): any {
    this.passengersService.get().subscribe(
      passengers => {
        this.passengers = passengers;
        this.dataSource = new MatTableDataSource<Passenger>(this.passengers);
        this.dataSource.sort = this.sort;
      },
      error => this.logging.log(`Errors occurred loading passengers, ${error}`)
    );
  }


  public save(passenger: Passenger): void {
    this.passengersService.add(passenger).subscribe(
      result => {
        this.passengers.push(passenger);
        this.dataSource = new MatTableDataSource<Passenger>(this.passengers);
      },
      error => this.logging.logError<Passenger>(`Error while saving passenger data: ${error}`)
    );
  }

  public update(passenger: Passenger): void {
    this.passengersService.update(passenger.manifestPassengerID, passenger).subscribe(
      result => {
        // alternative update - https://stackoverflow.com/a/48500099/12233405
        let updated = this.passengers.find(item => item.manifestPassengerID === passenger.manifestPassengerID);
        updated = passenger;
        this.dataSource = new MatTableDataSource<Passenger>(this.passengers);
      },
      error => this.logging.logError<Passenger>(`Error while updating passenger data: ${passenger.manifestPassengerID}
        |${error}`)
    );
  }

  public delete(passenger: Passenger): void {
    this.passengersService.delete(passenger).subscribe(
      _ => {
        this.passengers = this.passengers.filter(item => item !== passenger);
        this.dataSource = new MatTableDataSource<Passenger>(this.passengers);
        
      },
      error => this.logging.logError<Passenger>(`Error deleting passenger: ${passenger.manifestPassengerID}|${error}`)
    );
  }


}