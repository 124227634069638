import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddrunsComponent } from './addruns.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';

const components = [
  AddrunsComponent
];
const providers = [    ];


@NgModule({
  declarations: [AddrunsComponent],
  imports: [
    CommonModule, 
    MatDialogModule, 
    MatDividerModule,
    MatFormFieldModule,
    MatCheckboxModule, 
    MatSelectModule,
    MatInputModule,
    MatButtonModule,
    ReactiveFormsModule
  ],
  providers: [providers],
  exports: [components]
})
export class AddrunsModule { }
