import { Component, Input, OnInit, OnChanges, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { PassengersService } from './passengers.service';
import { SelectionModel } from '@angular/cdk/collections';
import { MatTableDataSource } from '@angular/material/table';
import { AddpassengersComponent } from './addpassengers/addpassengers.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Passenger, PassengerType, WeightType } from '../models/passenger';
import { Reservation } from '../models/reservation';
import { cvxActionConfiguration } from 'src/app/shared/cvxActionButton/cvxActionConfiguration';
import { FlightSelection } from '../models/flight-selection';
import { FlightSelectionService } from '../flight-info/flight-selection/flight-selection.service';
import { ReservationsService } from '../services/reservations.service';
import { Company } from '../models/companies';
import { Location } from '../models/locations';
import { rowfadeOut } from 'src/app/shared/animations/animations';
import { environment } from 'src/environments/environment';
import { IManifestInfo } from '../models/manifestinfo';
import { ActivatedRoute } from '@angular/router';
import { Runs } from '../models/runs';

@Component({
  selector: 'flight-passengers-manifest',
  templateUrl: './passengers-manifest.component.html',
  styleUrls: ['./passengers-manifest.component.css'],
  encapsulation: ViewEncapsulation.None,
  animations: [rowfadeOut]
})

export class PassengersManifestComponent implements OnInit, OnChanges {
  @Input() ManifestInfo: IManifestInfo;
  @Input() ReservationMode: boolean;
  public dataSource: MatTableDataSource<Passenger>;
  public displayedColumns: string[] = ['select', 'fullName', 'from', 'to', 'weight', 'cargo', 'totalweight', 'company', 'chargecode','add', 'remove'];
  public confirmedColumns: string[] = ['fullName', 'from', 'to', 'weight', 'cargo', 'totalweight', 'company', 'chargecode','picklater', 'remove'];

  public actions: cvxActionConfiguration[];

  public confirmedPassengers: Passenger[] = [];
  public selection = new SelectionModel<Passenger>(true, []);
  public reservations: Reservation[];
  public flightDate: Date;
  public flightSelection: FlightSelection;
  public locations: Location[];
  public companies: Company[];
  public runs: Runs[];
  public manisfestId: number;
  public reservedPassengers: Passenger[];
  public deleteRole: string;
  public dataSource_confirmpassengers: MatTableDataSource<Passenger>;
  public dataSource_reserved: MatTableDataSource<Passenger>;


  constructor(
    public dialog: MatDialog,
    private passengersService: PassengersService,
    private flightSelectionService: FlightSelectionService,
    private reservationsService: ReservationsService,
    private activatedRoute: ActivatedRoute) { }

  public ngOnChanges(_changes: SimpleChanges): void {

    this.ManifestInfo?.passengerManifestInfo.forEach(p => {
      this.confirmedPassengers.push({
        fullName: p.passengerName,
        date: Date.now.toString(),
        from: p.from,
        to: p.to,
        //weight: number;
        cargo: p.cargoLbs,
        totalWeight: p.totalLbs,
        company: p.company,
        companyID: p.companyId,
        chargeCode: p.chargeCode.toString(),
        weight: p.bodyLbs,
        firstName: p.firstName,
        lastName: p.lastName,
        //companyID?: p.company.toString(),
        distance: p.miles,
        //fromLocationID?: p.from,
        // helicopterRow?: p.,
        // manifestGUID?: p.,
        //manifestPassengerID?: p.,
        part135Code: p.code135?.toString(),
        // paxIndex?: p.,
        pickLater: p.pickLater,
        reservationID: p.reservationID,
        //toLocationID?: p.to,
        //verifyChargeCode?: p.,

      });
    });
    this.flightSelection = this.ManifestInfo?.flightSelection;
    this.dataSource_confirmpassengers = new MatTableDataSource<Passenger>(this.confirmedPassengers);
    this.passengersService.setConfirmedPassengers(this.confirmedPassengers);
  }

  ngOnInit() {
    this.activatedRoute.paramMap.subscribe(params => {
      let id = params.get('id');
      this.manisfestId = (id != undefined) ? Number(id) : 0;
    });
    this.initialize();
  }

  public onAdd() {
    let dialogConfig: MatDialogConfig = {
      width: '550px',
      disableClose: false,
      autoFocus: true,
      hasBackdrop: true
    };

    const dialogRef = this.dialog.open(AddpassengersComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        let passenger: Passenger = result;
        result.date = new Date();
        if (!this.confirmedPassengers) {
          this.confirmedPassengers = [];
        }
        this.createReservation(passenger);
        this.onReserved();
      }
    });
  }

  public onEdit(_$event: any, row: Passenger) {
    let dialogConfig: MatDialogConfig = {
      width: '550px',
      disableClose: false,
      autoFocus: true,
      hasBackdrop: true,
      data: row
    };

    const dialogRef = this.dialog.open(AddpassengersComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        row = result;
        this.confirmedPassengers.forEach((passenger, i) => {
          // Assuming no passenger name changes removing below condition
          if (passenger.firstName === row.firstName && passenger.lastName === row.lastName) {
            this.confirmedPassengers[i] = { ...this.confirmedPassengers[i], ...row };
          }
        });

        this.onConfirm();
      }
    });
  }

  public onRemove(_$event: any, row: Passenger) {
    try {
      // find matching reserved row and deselect
      this.onSelectRow({ checked: false }, row);
    } catch (error) {
      this.passengersService.logger.log(`Error removing PAX: ${error}`);
    }
  }

  /** Whether the number of selected elements matches the total number of rows. */
  public isAllSelected(_$event) {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource_reserved.data.length;
    return numSelected === numRows;
  }

  public getWeightTotal(weightType: string, passengerType: string): number {
    let total = 0;
    let wType: WeightType = WeightType[weightType];
    let pType: PassengerType = PassengerType[passengerType];

    let list: Passenger[] = [];
    if (pType == PassengerType.Reserved) {
      list = this.reservedPassengers;
    } else {
      list = this.confirmedPassengers;
    }

    if (list) {
      switch (wType) {
        case WeightType.Person: {
          total = list.map(t => t.weight).reduce((acc, value) => acc + value, 0);
          break;
        }
        case WeightType.Cargo: {
          total = list.map(t => t.cargo).reduce((acc, value) => acc + value, 0);
          break;
        }
        case WeightType.Total: {
          total = list.map(t => t.totalWeight).reduce((acc, value) => acc + value, 0);
          break;
        }
      }
    }

    return Math.round(total);
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  public masterToggle($event) {
    if (!$event.checked) {
      return;
    }
    if ($event.checked && this.reservedPassengers.length > 0) {
      this.confirmedPassengers = this.confirmedPassengers.concat(this.reservedPassengers);
      this.reservedPassengers = [];
    } else {
      this.reservedPassengers = this.confirmedPassengers;
      this.confirmedPassengers = [];
    }
    this.onReserved();
    this.isAllSelected($event) ?
      this.selection.clear() :
      this.dataSource_reserved.data.forEach(row => this.selection.select(row));

    this.onConfirm();
  }

  public onSelectRow($event: any, row: Passenger) {
    if ($event.checked) {
      this.removeReservationAndConfirm(row);
    } else {
      this.clearConfirmationAndReserve(row);
    }
  }

  public onPickLater($event: any, row: any) {
    this.confirmedPassengers.find( cp => cp.reservationID === row.reservationID).pickLater = $event.checked;
    this.onConfirm();
  }

  public onClearReservations() {
    try {
      this.clearReservations();
    } catch (error) {
      this.passengersService.logger.logError("Error in removeReservations", error);
    }
  }

  private clearConfirmationAndReserve(passenger: Passenger) {
    if (passenger?.reservationID > 0) {
      this.confirmedPassengers = this.confirmedPassengers.filter((item) => item.reservationID !== passenger.reservationID);
      this.reservedPassengers.push(passenger);
      // remove pax from reserved via reservations service
      this.onConfirm();
      this.onReserved();
    }
  }

  private removeReservationAndConfirm(passenger: Passenger) {
    if (passenger?.reservationID > 0) {
      this.reservedPassengers = this.reservedPassengers.filter((item) => item.reservationID !== passenger.reservationID);
      this.confirmedPassengers.push(passenger);
      this.onConfirm();
      this.onReserved();
    }

  }

  private clearReservations() {
    this.reservedPassengers.forEach(passengerReservation => this.clearReservation(passengerReservation));
  }

  private clearReservation(passengerReservation: Passenger) {
    if (passengerReservation.reservationID > 0) {
      this.reservationsService.deleteReservation(passengerReservation.reservationID).subscribe({
        next: () => {
          this.reservedPassengers.splice(this.reservedPassengers.indexOf(passengerReservation), 1);

          // remove pax from reserved via reservations service
          this.onReserved();
        },
        error: (error) => {
          this.passengersService.logger.logError(error);
          this.passengersService.logger.notify(`An error occurred removing the passenger's reservation for ${passengerReservation.lastName}, ${passengerReservation.firstName}`, 'Ok');
        }
      });
    }
  }

  private onConfirm() {
    this.dataSource_confirmpassengers = new MatTableDataSource<Passenger>(this.confirmedPassengers);
    this.passengersService.setConfirmedPassengers(this.confirmedPassengers);
  }

  private onReserved() {
    this.dataSource_reserved = new MatTableDataSource<Passenger>(this.reservedPassengers);
    this.passengersService.setReservedPassengers(this.reservedPassengers);
  }

  private getPassengers(): void {
    this.onPassengersReserved();
    this.onPassengersConfirmed();
  }

  private setActions(): cvxActionConfiguration[] {
    return [
      {
        name: 'manifest',
        icon: 'flight',
        display: 'manifest',
        disabled: false
      }
    ];
  }

  private onPassengersConfirmed(): void {
    this.passengersService.onPassengersConfirmed().subscribe({
      next: (passengers) => {
        if (passengers) {
          this.confirmedPassengers = passengers;
        }

        this.dataSource_confirmpassengers = new MatTableDataSource<Passenger>(this.confirmedPassengers);
        // Loop through records to select on reserved
        this.selectReserved();
      },
      error: (error) => {
        this.passengersService.logger.log(`Errors occurred loading passengers, ${error}`);
      }
    });
  }

  private onPassengersReserved(): void {
    this.passengersService.onPassengersReserved().subscribe({
      next: (passengers) => {
        if (passengers) {
          this.reservedPassengers = passengers.filter((p) => !this.confirmedPassengers.some(cp => cp.reservationID === p.reservationID));
        }
        this.dataSource_reserved = new MatTableDataSource<Passenger>(this.reservedPassengers);
      },
      error: (error) => {
        this.passengersService.logger.log(`Errors occurred loading passengers, ${error}`);
      }
    });
  }

  private selectReserved() {
    this.confirmedPassengers.forEach(confirmed => {
      this.selection.select(this.dataSource_reserved.data.find(passenger => passenger.fullName === confirmed.fullName && passenger.weight === confirmed.weight
        && passenger.to === confirmed.to && passenger.from === confirmed.from))
    });
  }

  private initialize(): void {
    this.actions = this.setActions();
    this.deleteRole = environment.adminRole;

    this.confirmedPassengers = [];
    this.reservedPassengers = [];
    this.flightDate = this.getFlightDate();

    this.loadCompanies();
    this.loadLocations();
    this.loadRuns();

    this.flightSelection = new FlightSelection();
    // subscribe to flight changes and update view / model appropriately
    this.onFlightSelection();
    this.getPassengers();
    this.loadReservations()

  }

  private getFlightDate(): Date {
    // Use UTC so it matches everywhere
    let date: Date = new Date();

    this.passengersService.logger.log(`flight date: ${date}`);
    return date;
  }

  private loadLocations(): void {
    this.locations = [];
    this.passengersService.locationsService.get().subscribe({
      next: (locations) => this.locations = locations
    });
  }

  private loadCompanies(): void {
    this.companies = [];
    this.passengersService.companiesService.get().subscribe({
      next: (companies) => this.companies = companies
    });
  }

  private loadRuns(): void {
    this.runs = [];
    this.passengersService.runsService.get().subscribe({
      next: (runs) => this.runs = runs
    });
  }

  private onFlightSelection() {
    this.flightSelectionService.onFlightSelection().subscribe({
      next: (selection) => {
        if (selection) {
          // For filtering, consider clearing the reserved pax array when the selected flight changes        
          this.flightSelection = selection;
        }

      }
    });
  }

  private loadReservations() {
    this.reservations = [];
    try {
      // Convert to use reservations service load, need to patch 2 unit tests failing as result -- save for later
      this.reservationsService.loadReservations(this.flightDate).subscribe({
        next: (reservations: Reservation[]) => {
          if (reservations?.length) {
            this.reservations = reservations;
            this.passengersService.setReservedPassengers(this.passengersService.mapPassengers(
              this.filterFlownReservations(reservations)
            ))
          }

        },
        error: (error) => {
          this.passengersService.logger.log(`Errors occurred loading reservations for ${this.flightDate.toDateString()}, Error: ${error}`);
        }
      });

    } catch (error) {
      this.passengersService.logger.log(`Error checking reservations: ${error}`);
    }
  }

  private createReservation(passenger: Passenger): void {

    try {
      this.reservationsService.createReservation(
        passenger,
        this.getRunSelection(),
        this.flightDate, this.locations, this.companies).subscribe({
          next: (reservation) => {
            if (reservation) {
              this.reservations.push(reservation);
              this.passengersService.setReservedPassengers(this.passengersService.mapPassengers(
                this.filterFlownReservations(this.reservations)));
              this.passengersService.logger.notify(`1 reservations created`, 'Ok');
            }
          },
          error: (error) => {
            this.passengersService.logger.logError(`Errors occurred creating reservation for new passenger`, error);
          }
        });
    } catch (error) {
      this.passengersService.logger.logError(error);
    }
  }

  private filterFlownReservations(reservations: Reservation[]) {
    return reservations.filter((res) => res.flownIndicator === false)
  }

  public getRunSelection(): Runs {
    let currentRun = this.passengersService.runSelService.getFlightRunSelection()?.flight;
    if (!currentRun) {
      currentRun = this.runs.filter(r => r.runName === 'Special Run')[0];
    }
    return currentRun;
  }
}
