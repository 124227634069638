import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { LoggingService } from 'src/app/logging/logging.service';
import { environment } from 'src/environments/environment';
import { FlightSelectionService } from '../flight-info/flight-selection/flight-selection.service';
import { Shorebasejob } from '../models/shorebasejob';

@Injectable({
  providedIn: 'root'
})
export class ShoreBaseJobsService {
private readonly api = `${environment.api}shorebasejobs`;
private shoreBaseJobs:Shorebasejob[] = [];

  constructor(private http:HttpClient, private logger:LoggingService, private fsService:FlightSelectionService) { }

  get():Observable<Shorebasejob[]> {
    
   return this.shoreBaseJobs.length > 0 ? of(this.shoreBaseJobs) : this.http.get<Shorebasejob[]>(this.api,{headers:environment.headers, params:{"includeInactive": "false"}})
    .pipe(
      tap(sbjs => this.shoreBaseJobs = sbjs),
      catchError(
        (error)=> 
        { return this.logger.logError<Shorebasejob[]>("Error fetching shorebasejobs from api")
      })
    )
  }

  loadShoreBaseJob() {
   this.fsService.onFlightSelection().subscribe(
     (_fs) => {
      (!this.shoreBaseJobs) && this.get().subscribe(
         result => this.shoreBaseJobs = result
       )
     }
   )
  }
}
