import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
// MSAL
import { CalGuardService, RoleGuardService } from '@cvx/cal-angular';

import { TrayComponent } from 'src/app/dtms/tray/tray.component';
import { PassengersManifestComponent } from 'src/app/dtms/passengers/passengers-manifest.component';
import { PilotsComponent } from './dtms/pilots/pilots.component';
import { FlightManifestComponent } from './dtms/flight-manifest/flight-manifest.component';
import { AddpassengersComponent } from './dtms/passengers/addpassengers/addpassengers.component';
import { HelicopterComponent } from './dtms/helicopter/helicopter.component';
import { RunsComponent } from './dtms/runs/runs.component';
import { LocationsComponent } from './dtms/locations/locations.component';
import { AddlocationComponent } from './dtms/addlocation/addlocation.component';
import { AddrunsComponent } from './dtms/addruns/addruns.component';
import { AddhelicopterComponent } from 'src/app/dtms/addhelicopter/addhelicopter.component';
import { CompaniesComponent } from 'src/app/dtms/companies/companies.component';
import { AddcompanyComponent } from 'src/app/dtms/addcompany/addcompany.component'
import { PassengersComponent } from './dtms/passengers/passengers.component';
import { AddpassengerdetailsComponent } from 'src/app/dtms/addpassengerdetails/addpassengerdetails.component'
import { HelicoptertypesComponent } from 'src/app/dtms/helicoptertypes/helicoptertypes.component';
import { AddpilotComponent } from 'src/app/dtms/addpilot/addpilot.component'
import { AddhelicoptertypesComponent } from 'src/app/dtms/addhelicoptertypes/addhelicoptertypes.component'
import { FlightInfoComponent } from 'src/app/dtms/flight-info/flight-info.component'
import { ManifestComponent } from './dtms/flight-manifest/manifest/manifest.component';
import { FlightRouteComponent } from './dtms/flight-info/flight-route/flight-route.component';
import { ViewManifestComponent } from './dtms/flight-manifest/view-manifest/view-manifest.component';
import { RoleGuard } from './guards/role.guard';
import { FlightReservationsComponent } from './dtms/flight-reservations/flight-reservations.component';
import { JobremarksComponent } from './dtms/flight-manifest/jobremarks/jobremarks.component';

const release: string[] = [
  'dtms-admin-users',
  'dtms-release',
  'dtms-users',
  'dtms-manifest-users',
  'dtms-read-only'
];

const admin: string[] = [
  'dtms-admin-users'
];

const manifest: string[] = [
  'dtms-admin-users',
  'dtms-manifest-users',
  'dtms-read-only'
]

const user: string[] = [
  'dtms-admin-users',
  'dtms-release',
  'dtms-users',
  'dtms-read-only'
];

const reservations: string[] = [
  'dtms-reservations-users',
  'dtms-admin-users'
];


const routes: Routes = [
  {
    path: 'tray/:id',
    component: TrayComponent,
    data: {
      roles: user
    },
    canActivate: [CalGuardService, RoleGuardService]
  },
  {
    path: 'passengers',
    component: PassengersComponent,
    data: {
      roles: release
    },
    canActivate: [CalGuardService, RoleGuardService]
  },
  {
    path: 'pilots',
    component: PilotsComponent,
    data: {
      roles: release
    },
    canActivate: [CalGuardService, RoleGuardService]
  },
  {
    path: 'manifest',
    component: ManifestComponent,
    data: {
      roles: release
    },
    canActivate: [CalGuardService, RoleGuardService]
  },
  {
    path: 'jobremarks',
    component: JobremarksComponent,
    data: {
      roles: release
    },
    canActivate: [CalGuardService, RoleGuardService]
  },

  {
    path: 'manifest/:id',
    component: ViewManifestComponent,
    data: {
      roles: release
    },
    canActivate: [CalGuardService, RoleGuard]
  },
  {
    path: 'helicopter',
    component: HelicopterComponent,
    data: {
      roles: release
    },
    canActivate: [CalGuardService, RoleGuardService]
  },
  {
    path: 'runs',
    component: RunsComponent,
    data: {
      roles: release
    },
    canActivate: [CalGuardService, RoleGuardService]
  },
  {
    path: 'locations',
    component: LocationsComponent,
    data: {
      roles: release
    },
    canActivate: [CalGuardService, RoleGuardService]
  },
  {
    path: 'companies',
    component: CompaniesComponent,
    data: {
      roles: release
    },
    canActivate: [CalGuardService, RoleGuardService]
  },
  {
    path: 'helicoptertypes',
    component: HelicoptertypesComponent,
    data: {
      roles: release
    },
    canActivate: [CalGuardService, RoleGuardService]
  },
  {
    path: 'addpassenger',
    component: AddpassengersComponent,
    data: {
      roles: release
    },
    canActivate: [CalGuardService, RoleGuardService]
  },
  {
    path: 'addlocation',
    component: AddlocationComponent,
    data: {
      roles: release
    },
    canActivate: [CalGuardService, RoleGuardService]
  },
  {
    path: 'addruns',
    component: AddrunsComponent,
    data: {
      roles: release
    },
    canActivate: [CalGuardService, RoleGuardService]
  },
  {
    path: 'addhelicopter',
    component: AddhelicopterComponent,
    data: {
      roles: release
    },
    canActivate: [CalGuardService, RoleGuardService]
  },
  {
    path: 'addcompany',
    component: AddcompanyComponent,
    data: {
      roles: release
    },
    canActivate: [CalGuardService, RoleGuardService]
  },
  {
    path: 'addpassengerdetails',
    component: AddpassengerdetailsComponent,
    data: {
      roles: release
    },
    canActivate: [CalGuardService, RoleGuardService]
  },
  {
    path: 'addpilot',
    component: AddpilotComponent,
    data: {
      roles: release
    },
    canActivate: [CalGuardService, RoleGuardService]
  },
  {
    path: 'addhelicoptertypes',
    component: AddhelicoptertypesComponent,
    data: {
      roles: release
    },
    canActivate: [CalGuardService, RoleGuardService]
  },
  {
    path: 'flightroute',
    component: FlightRouteComponent,
    data: {
      roles: manifest
    },
    canActivate: [CalGuardService, RoleGuardService]
  },
  {
    path: 'passengermanifest',
    component: PassengersManifestComponent,
    data: {
      roles: manifest
    },
    canActivate: [CalGuardService, RoleGuardService]
  },
  {
    path: 'flightmanifest',
    component: FlightManifestComponent,
    data: {
      roles: manifest
    },
    canActivate: [CalGuardService, RoleGuardService]
  },
  {
    path: 'flightmanifest/:id',
    component: FlightManifestComponent,
    data: {
      roles: manifest
    },
    canActivate: [CalGuardService, RoleGuardService]
  },
  {
    path: 'flightinfo',
    component: FlightInfoComponent,
    data: {
      roles: manifest
    },
    canActivate: [CalGuardService, RoleGuardService]
  },
  {
    path: 'reservations',
    component: FlightReservationsComponent,
    data: {
      roles: reservations
    },
    canActivate: [CalGuardService, RoleGuardService]
  },
  {
    path: '', redirectTo: 'app', pathMatch: 'full'
  },
  {
    path: '**', redirectTo: 'app',
  }
];

@NgModule({
  declarations: [],
  imports: [
    RouterModule.forRoot(routes, { useHash: true })
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule { }
