import { Component, OnInit } from '@angular/core';
import { TraysService } from 'src/app/dtms/tray//trays.service';
import { ITray } from 'src/app/dtms/models/tray'
import { Router, ActivatedRoute } from '@angular/router';
import { LoggingService } from 'src/app/logging/logging.service';
import { NextPageService, PageLayout } from '@cvx/nextpage';

@Component({
  selector: 'app-tray',
  templateUrl: './tray.component.html',
  styleUrls: ['./tray.component.css']
})
export class TrayComponent implements OnInit {
  public tray: ITray;
  public trayId: number;

  constructor(private route: ActivatedRoute,
    private router: Router,
    private NP: NextPageService,
    private trayservice: TraysService,
    private logger: LoggingService) {
    // this.NP.layout.setLayout(PageLayout.Layout39);
  }

  ngOnInit(): void {


    this.route.paramMap.subscribe(params => {
      try {
        this.trayId = +params.get('id');
        this.getTray(this.trayId);

      } catch (error) {
        this.logger.log(`Error Loading tray details: ${error}`);
      }
    });
  }

  public onSubmit(): void {
    this.router.navigate(['flightmanifest']);
  }

  public getTray(id: number): any {
    this.trayservice.GetTray(id).subscribe(
      data => {
        this.tray = data;
        console.log(this.tray.key);
        this.logger.log(data);
      },
      error => this.logger.log(`Errors occurred loading tray: ${this.trayId}, ${error}`)
    );
  }

  public onTrayClick(item: any) {

    console.log(item);

    if (item.id == 'gao') {
      this.router.navigate(['/flightmanifest']);
    }
    else if (item.id == 'hmreservations') {
      this.router.navigate(['/reservations']);
    }
    else if (item.id == 'hmhelicopters') {
      this.router.navigate(['/helicopter'])
    }
    else if (item.id == 'hmpilots') {
      this.router.navigate(['/pilots'])
    }
    else if (item.id == 'hmruns') {
      this.router.navigate(['/runs'])
    }
    else if (item.id == 'hmlocations') {
      this.router.navigate(['/locations'])
    }
    else if (item.id == 'hmcompanies') {
      this.router.navigate(['/companies'])
    }
    else if (item.id == 'hmpassengers') {
      this.router.navigate(['passengers']);
    }
    else if (item.id == 'hmmanifest') {
      this.router.navigate(['/manifest'])
    }
    else if (item.id == 'hmhelicoptertypes') {
      this.router.navigate(['/helicoptertypes'])
    }
    else if (item.id == 'search manifest') {
      this.router.navigate(['/manifest'])
    }

    else if (item.id == 'job remarks') {
      this.router.navigate(['/jobremarks'])
    }

  }

}
