import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { Location } from '../models/locations';
import { LocationsService } from '../locations/locations.service';
import { ShorebaseService } from '../shorebase/shorebase.service';
import { Shorebase } from '../models/shorebase';
import { alphanumericValidator, decimalMin5Validator } from 'src/app/shared/validators/validators';

@Component({
  selector: 'app-addlocation',
  templateUrl: './addlocation.component.html',
  styleUrls: ['./addlocation.component.css'],
  exportAs: 'app-addlocation',

})

export class AddlocationComponent implements OnInit {

  public locData: Location[];
  public locationForm: FormGroup;
  mode: string = "New";
  showDupWarning = false;
  locName = "";
  selectedShoreBase: Shorebase;

  heliports: string[] = ['ALGAL', 'GAO', 'ICY', 'LVL', 'PAS', 'PIC', 'VEN'];
  locations: any = ['GAO', 'TAH', 'BFT', 'JSM'];
  crewchanged: string[] = ['Yes', 'No'];
  sharedpoint: string[] = ['Yes', 'No'];
  statusList: any[] = ['Active', 'Inactive'];

  constructor(public dialogRef: MatDialogRef<AddlocationComponent>,
    @Inject(MAT_DIALOG_DATA) public location: Location,
    private formBuilder: FormBuilder,
    private locationService: LocationsService,
    private shorebaseService: ShorebaseService) { }

  public ngOnInit() {
    this.initialize();
  }

  public onSubmit(): void {
    this.showDupWarning = this.hasDuplicates();
    if (this.locationForm.valid && !this.showDupWarning) {
      this.location = this.save();
      this.dialogRef.close(this.location);
    }
  }

  public onReset(_fgd: NgForm) {
    let currentMode = this.mode;
    this.showDupWarning = false;
    this.locationForm.reset();
    this.initialize();
    this.mode= currentMode;
  }
  
  public closeDialog() {
    this.dialogRef.close();
  }

  public onChangeLoc(e: any, type: string) {
    const selected: string = this.locations[e.target.selectedIndex - 1];
    this.locationForm.get(type).setValue(selected, {
      onlySelf: true
    });
  }
  
  public onChangeHeliport(e: any, type: string) {
    const selected: string = this.heliports[e.target.selectedIndex - 1];
    this.locationForm.get(type).setValue(selected, {
      onlySelf: true
    });
  }

  public onChangeCrew(e: any, type: string) {
    const selected: string = this.crewchanged[e.target.selectedIndex - 1];
    this.locationForm.get(type).setValue(selected, {
      onlySelf: true
    });
  }
  public onChangeShared(e: any, type: string) {
    const selected: string = this.sharedpoint[e.target.selectedIndex - 1];
    this.locationForm.get(type).setValue(selected, {
      onlySelf: true
    });
  }

  public onChangeStatus(e: any, type: string) {
    this.locationForm.get(type).setValue(e.value === 'Active' ? true : false);
  }

  private initialize(): void {
    this.getLocationData();
    const heliport = "GAO";
    console.log("initialize calling..");
    if (!this.location) {
     
      this.shorebaseService.getShorebases().subscribe(
        shorebases => {
          this.selectedShoreBase = shorebases.find((item) => item.shoreBaseShortName == heliport);
          this.locationForm = this.createForm();
        }
      );
      this.mode = "Add";
    } else {
      this.selectedShoreBase = this.location.shoreBase;
      this.locationForm = this.updateForm();
      this.mode = (this.location)?this.location.mode:"Add";
    }
  }

  private hasDuplicates() {
    const locName: string = (this.locationForm.get('locationName').value);
    const latitudeData: string = (this.locationForm.get('latitude').value);
    const longitudeData: string = (this.locationForm.get('longitude').value);
    return !this.location.locationID && this.locData.some(row => row.locationName === locName && row.latitude.toString() == latitudeData && row.longitude.toString() == longitudeData) 
  } 

  private createForm(): FormGroup {
    this.mode = "Add";
    this.location = {
      heliport: 'GAO',
      heliportId: 0,
      locationID: 0,
      lastUpdatedBy: '',
      lastUpdatedDate: new Date(),
      shoreBase: this.selectedShoreBase,
      shoreBaseId: this.selectedShoreBase.shoreBaseID,
      shoreBaseName: this.selectedShoreBase.shoreBaseLongName,
      locationName: "",
      latitude: null,
      longitude: null,
      variation: null,
      isCrewChanged: null,
      isShared: null,
      activeInactive: true,
      isActive: "Active",
    };

    return this.setForm();
  }

  private setForm(): FormGroup {
    return this.formBuilder.group({
      heliport: [{ value: this.location.heliport, disabled: true }, Validators.required],
      locationName: [ this.location.locationName, [Validators.required,Validators.minLength(3),Validators.maxLength(20),alphanumericValidator]],
      latitude: [this.location.latitude, [Validators.required, decimalMin5Validator]],
      longitude: [this.location.longitude, [Validators.required,decimalMin5Validator]],
      variation: [this.location.variation, Validators.required],
      isCrewChanged: [this.location.isCrewChanged],
      isShared: [this.location.isShared],
      activeInactive: [this.location.activeInactive],
      isActive: [this.location.isActive, Validators.required],
      lastUpdatedBy: this.location.lastUpdatedBy,
      lastUpdatedDate: this.location.lastUpdatedDate,
      shoreBase: this.selectedShoreBase,
      shoreBaseId: this.selectedShoreBase.shoreBaseID,
      shoreBaseName: this.selectedShoreBase.shoreBaseLongName,
      heliportId: this.location.heliportId,
      locationID: this.location.locationID
    });
  }

  private getStatus(status: boolean): string {
    return status ? this.statusList[0] : this.statusList[1];
  }

  private updateForm(): FormGroup {
    this.mode = "Edit";
    this.location.heliport = 'GAO';
    
    this.locationForm = this.setForm();
    this.locationForm.patchValue({ locationName: this.location.locationName });

    return this.locationForm;
  }

  private save(): Location {
    let saveLocation: Location;

    saveLocation = new Location(this.locationForm.value);

    return saveLocation;
  }

  private getLocationData(): void {
    this.locationService.get().subscribe(
      location => {
        this.locData = location;
      });
  }
}
