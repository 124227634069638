import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LoggingService } from 'src/app/logging/logging.service';
import { environment } from 'src/environments/environment';
import { Passenger } from '../models/passenger';

@Injectable({
  providedIn: 'root'
})
export class PassengersDataService {
  private api = `${environment.api}passengers/`;

  httpClient: any;
  
constructor(private http: HttpClient, private logger: LoggingService) { }

get(): Observable<Passenger[]> {
  return this.http.get<Passenger[]>(this.api, { headers: environment.headers })
      .pipe(
        catchError(error => {
          return this.logger.logError<Passenger[]>('Error retrieving passenger')
        })
      );
}

  add(passenger: Passenger) : Observable<any> {
    return this.http.post<Passenger>(this.api, passenger, { headers: environment.headers })
    .pipe(
      catchError(error => {
        return this.logger.logError<Passenger>(`Error adding Passenger: ${passenger}`)
      })
    );
  }

  update(id: number, passenger: Passenger): Observable<any> {
    return this.http.put<Passenger>(`${this.api}${id}`, passenger, { headers: environment.headers })
    .pipe(
      catchError(error => {
        return this.logger.logError<Passenger>(`Error updating Passenger: ${passenger}`)
      })
    );
  }

  delete(passenger: Passenger | number): Observable<any> {
    const id = typeof passenger === 'number' ? passenger : passenger.manifestPassengerID;

    const url = `${this.api}${id}`;

    return this.http.delete<Passenger>(url, { headers: environment.headers })
    .pipe(
      catchError(error => {
        return this.logger.logError<Passenger>(`Error deleting Passenger: ${passenger}`) 
      })
    );
  }
}
