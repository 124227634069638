import { Component, OnInit, ViewChild} from '@angular/core';
import { FlightInfoComponent } from '../flight-info/flight-info.component';
import { FlightSelection } from '../models/flight-selection';
import { ManifestService } from './manifest.service';
import { LoggingService } from 'src/app/logging/logging.service';
import { CvxProgressService } from 'src/app/shared/cvx-progress/cvx-progress.service';
import { ActivatedRoute } from '@angular/router';
import { IManifestInfo, IPassengerManifest } from '../models/manifestinfo';

@Component({
  selector: 'app-flight-manifest',
  templateUrl: './flight-manifest.component.html',
  styleUrls: ['./flight-manifest.component.css']
})
export class FlightManifestComponent implements OnInit {


  @ViewChild(FlightInfoComponent, { static: true }) child: FlightInfoComponent;
  public  flightSelection :FlightSelection;
  public  passengerManifestInfo :IPassengerManifest[] = [];
  public  manifestInfo :IManifestInfo
  public manisfestId: number; 
  constructor(private manifestService: ManifestService,
    private logger: LoggingService,
    private progress: CvxProgressService,
    private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    this.activatedRoute.paramMap.subscribe(params =>{
      let id = params.get('id');
      this.manisfestId = (id != undefined)? Number(id):0;
      if( this.manisfestId>0){
       this.getManifestData(this.manisfestId);
      }
    });
   
  }
  getManifestData(manifestID: number){
    this.progress.spin$.next(true);
    this.manifestService.getManifestsByID(manifestID).subscribe(
      (manifestInfo: IManifestInfo) => {
        this.manifestInfo = manifestInfo;
        this.flightSelection  = manifestInfo.flightSelection;   
        this.passengerManifestInfo = manifestInfo.passengerManifestInfo;
        this.progress.spin$.next(false);
      })
  }

}
