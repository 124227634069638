import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlightManifestComponent } from './flight-manifest.component';
import { PassengersModule} from 'src/app/dtms/passengers/passengers.module';
import { FlightInfoModule} from 'src/app/dtms/flight-info/flight-info.module';
import { ManifestService } from './manifest.service';
import { ManifestComponent } from './manifest/manifest.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { TableVirtualScrollModule } from 'src/app/shared/tableVirtualScroll/tableVirtualScroll.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatTableModule } from '@angular/material/table';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSelectModule } from '@angular/material/select';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSortModule } from '@angular/material/sort';
import { ViewManifestComponent } from './view-manifest/view-manifest.component';
import { RouterModule } from '@angular/router';
import { FlightReservationsModule } from '../flight-reservations/flight-reservations.module';
import { ViewManifestModelComponent } from './view-manifest-model/view-manifest-model.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { JobremarksComponent } from './jobremarks/jobremarks.component';

const components = [
  FlightManifestComponent, 
  ViewManifestModelComponent,
  ManifestComponent,
  JobremarksComponent
];

@NgModule({
  declarations: [
    components,
    ViewManifestComponent,
    ViewManifestModelComponent,
  ],
  imports: [
    CommonModule,
    FlightInfoModule,
    PassengersModule,
    MatTableModule,
    MatCheckboxModule,
    MatButtonToggleModule,
    MatDividerModule,
    MatExpansionModule,
    BrowserAnimationsModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatIconModule,
    MatCardModule,
    MatTabsModule,
    MatSelectModule,
    MatDialogModule,
    ScrollingModule,
    TableVirtualScrollModule,
    MatSortModule,
    FlexLayoutModule,
    RouterModule,
    FlightReservationsModule,
    MatSnackBarModule

  ],
  providers: [
    ManifestService
  ],
  exports: [
    components  
  ]
})
export class FlightManifestModule { }
