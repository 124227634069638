import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlightInfoComponent } from 'src/app/dtms/flight-info/flight-info.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FlightInfoService } from './flight-info.service';
import { StopsComponent } from './stops/stops.component';
import { BalanceComponent } from './balance/balance.component';
import { FlightComponent } from './flight/flight.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { FlightSelectionComponent } from './flight-selection/flight-selection.component';
import { FlightRouteComponent } from './flight-route/flight-route.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { RemarksComponent } from './remarks/remarks.component';
import { CvxActionModule } from 'src/app/shared/cvxActionButton/cvxActionButton.module';
import { FuelneededComponent } from './fuelneeded/fuelneeded.component';
import { MatTableModule } from '@angular/material/table';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSelectModule } from '@angular/material/select';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatListModule } from '@angular/material/list';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import {MatProgressBarModule} from '@angular/material/progress-bar';

const components = [
  FlightInfoComponent,
  StopsComponent,
  BalanceComponent,
  FlightComponent,
  FlightSelectionComponent,
  FlightRouteComponent,
  RemarksComponent
];

const providers = [
  FlightInfoService
];


@NgModule({

  declarations: [
    FlightInfoComponent, 
    StopsComponent, 
    BalanceComponent, 
    FlightComponent, 
    FlightSelectionComponent, 
    FlightRouteComponent, 
    RemarksComponent, 
    FuelneededComponent],
  imports: [
    CommonModule,
    MatTableModule,
    MatCheckboxModule,
    MatButtonToggleModule,
    MatDividerModule,
    MatExpansionModule,
    BrowserAnimationsModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatIconModule,
    MatCardModule,
    MatTabsModule,
    MatSelectModule,
    MatPaginatorModule,
    MatButtonModule,
    MatInputModule,
    ReactiveFormsModule,
    MatGridListModule,
    ScrollingModule,
    FormsModule,
    FlexLayoutModule,
    DragDropModule,
    MatListModule,
    CvxActionModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatAutocompleteModule
  ],
  providers: [
    providers
  ],
  exports: [
    components
  ],
  entryComponents: [FuelneededComponent]
})


export class FlightInfoModule { }
