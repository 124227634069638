import { Component, OnInit, Inject, AfterViewInit } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { Company } from '../models/companies';
import { CompaniesService } from '../companies/companies.service';
import { LoggingService } from 'src/app/logging/logging.service';
import { environment } from 'src/environments/environment';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { alphanumericValidator } from 'src/app/shared/validators/validators';


@Component({
  selector: 'app-addcompany',
  templateUrl: './addcompany.component.html',
  styleUrls: ['./addcompany.component.css'],
  exportAs: 'app-addcompany'
})

export class AddcompanyComponent implements OnInit, AfterViewInit {
  public companies: Company[];
  public companyForm: FormGroup;
  public companyName: string;
  public showDupWarning: boolean;
  public submitted: boolean;
  public statusList: string[] = ['Active', 'Inactive'];
  mode: string = "New";

  constructor(@Inject(MAT_DIALOG_DATA) public company: Company,
              public dialogRef: MatDialogRef<AddcompanyComponent>, 
              private formBuilder: FormBuilder,
              private companyService: CompaniesService,
              private logger: LoggingService) { }

  public ngOnInit(): void {
    this.initialize();
  }

  public ngAfterViewInit(): void {
    this.getCompanyData();
  }

  public onSubmit(): void {
    this.showDupWarning = this.hasDuplicates();

    if (this.companyForm.valid && !this.showDupWarning)
    {
      this.dialogRef.close(this.save());
    }
  }

  private hasDuplicates()
  {
    const companyName: string = (this.companyForm.get('description').value);
    return !this.company.companyID && this.companies.some(row=> row.description === companyName) 
  }

  onReset(fgd: NgForm) {
    let currentMode = this.mode;
    this.showDupWarning=false;
    fgd.onReset();
    this.initialize();
    this.mode= currentMode;
  }
  
  closeDialog(){
    this.dialogRef.close();
  }
 
  public onChangeStatus(e: any, type: string){
    this.companyForm.get(type).setValue(e.value==='Active'?true:false);
  }

  private initialize(): void{
    if (!this.company) {
      this.companyForm = this.createForm(this.formBuilder);
      this.mode = "Add";
    } else {
      this.companyForm = this.updateForm(this.formBuilder, this.company);
      this.mode = (this.company)?this.company.mode:"Add";
    }
  }  

  private createForm(formBuilder: FormBuilder): FormGroup {
    this.mode = "Add";
    this.company = {
      isActive: "Active",
      lastUpdatedBy: environment.account,
      lastUpdatedDate: new Date(),
      activeInactive: true,
      companyID: 0,
      description: ''
    };

    return formBuilder.group({      
        companyID: [this.company.companyID],
        description: [this.company.description, [Validators.required,Validators.minLength(3),Validators.maxLength(20),alphanumericValidator]],
        isActive: [this.company.isActive, Validators.required],
        lastUpdatedBy: [this.company.lastUpdatedBy],
        lastUpdatedDate: [this.company.lastUpdatedDate],
        activeInactive: [this.company.activeInactive]
      });
  }

  private updateForm(formBuilder: FormBuilder, model: Company): FormGroup {
    this.mode = "Edit";
    return formBuilder.group({      
        companyID: [model.companyID],
        description: [model.description, [Validators.required,Validators.minLength(3),Validators.maxLength(100)]],
        isActive: [model.isActive, Validators.required],
        lastUpdatedBy: [environment.account],
        lastUpdatedDate: [new Date()],
        activeInactive: [model.activeInactive]
      });
   }

  private save(): Company {
    let comData: Company;  
    comData = new Company(this.companyForm.value);
   
    return comData;
  }

  private getCompanyData(): void {
    this.companyService.get().subscribe(
      result => {
        this.companies = result;
      });
  }
    
}
