import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Observable } from 'rxjs/internal/Observable';
import { LoggingService } from 'src/app/logging/logging.service';
import { FlightSelection } from '../../models/flight-selection';

@Injectable({
  providedIn: 'root'
})
export class FlightSelectionService {
  constructor(private logger: LoggingService) {
    this.loadCargoLimits()// This is left intentionally blank
  }

  private flightSelection$: BehaviorSubject<FlightSelection> = new BehaviorSubject(null);
  private flightCargoLimit: any;

  //private flightSelection$: BehaviorSubject<FlightSelection> = new BehaviorSubject(JSON.parse(sessionStorage.getItem('flightSelections')));

  onFlightSelection(): Observable<FlightSelection> {
    return this.flightSelection$.asObservable();
  }

  setFlightSelection(flightSelection: FlightSelection) {
    try {
      flightSelection = flightSelection.helicopterType && this.updateFlightMaxCargo(flightSelection);
      this.flightSelection$.next(flightSelection);
    }
    catch (error) {
      this.logger.logError(`Error mapping , ${error}`);
    }
  }

  private updateFlightMaxCargo(flightSelection: FlightSelection): FlightSelection {

    let flightName = flightSelection.helicopterType.helicopterTypeName.toUpperCase();
    flightSelection.helicopterType.maxToCargoWeight = !isNaN(this.flightCargoLimit[flightName]) ? this.flightCargoLimit[flightName] : this.flightCargoLimit["DEFAULT"];

    return flightSelection;
  }

  // Load cargo max limit from json file. [TechDebt]
  private loadCargoLimits() {
    fetch("/assets/flightcargolimit.json")
      .then((res) => res.json())
      .then((cargoLimit) => this.flightCargoLimit = cargoLimit
      )
  }


}
