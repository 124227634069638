import { Component, EventEmitter, Input, Output } from '@angular/core';
import { cvxActionConfiguration } from './cvxActionConfiguration';

@Component({
  selector: 'cvx-action-button',
  templateUrl: './cvxActionButton.component.html',
  styleUrls: ['./cvxActionButton.component.css']
})
export class CvxActionButtonComponent {
  @Input() actions: cvxActionConfiguration[];
  @Output() action = new EventEmitter<cvxActionConfiguration>();

  constructor() { // this is intentionally left blank no DI needed
  }

  public onClick(action: cvxActionConfiguration) {
    this.action.emit(action);
  }
}
