export class Company {
    mode?: string;
    
    public constructor(init?: Partial<Company>) {
        Object.assign(this, init);
    }

    companyID: number;
    description: string;
    lastUpdatedBy:string;
    lastUpdatedDate:Date;
    isActive: string;
    activeInactive: boolean;
}