import { formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { FlightRunSelection } from '../../models/flight-runselection';
import { Runs } from '../../models/runs';
import { Shorebase } from '../../models/shorebase';
import { RunsService } from '../../runs/runs.service';
import { ShorebaseService } from '../../shorebase/shorebase.service';
import { FlighRunSelectionService } from './fligh-runselection.service';

@Component({
  selector: 'flight-runselection',
  templateUrl: './flight-runselection.component.html',
  styleUrls: ['./flight-runselection.component.css']
})
export class FlightRunSelectionComponent implements OnInit {

  private date: Date = new Date();
  private runs: Runs[] = [];
  private shorebase: Shorebase[] = [];
  private runs$: Observable<Runs[]>;
  private shorebase$: Observable<Shorebase[]>
  private flightRunSelection: FlightRunSelection;
  private runSelectionForm: FormGroup;

  constructor(private runService: RunsService,
    private formBuilder: FormBuilder,
    private shoreBaseService: ShorebaseService,
    private runSelectionService: FlighRunSelectionService) { }

  ngOnInit(): void {
    //subscribe to runs service
    this.runs$ = this.runService.get();
    this.shorebase$ = this.shoreBaseService.getShorebases();
    this.initialize();
  }

  private initialize() {
    if (this.flightRunSelection == null) {
      this.runSelectionForm = this.createForm()
    } else {
      this.runSelectionForm = this.setForm();
    }

    //set initial values
    this.runs$.subscribe((runs: Runs[]) => {
      this.runs = runs;
      this.runSelectionForm.controls.flight.setValue(runs.filter((run) => run.runName === 'Special Run')[0]);
    });

    this.shorebase$.subscribe((shorebases: Shorebase[]) => {
      this.shorebase=shorebases;
      this.runSelectionForm.controls.heliport.setValue(shorebases.filter(s => s.shoreBaseShortName === 'GAO')[0])
    });

    this.runSelectionForm.valueChanges.subscribe((rs: FlightRunSelection) => this.runSelectionService.setFlightRunSelection(rs))
  }
  private createForm(): FormGroup {
    this.flightRunSelection = new FlightRunSelection({ rundate: this.date });
    return this.setForm()

  }
  private setForm(): FormGroup {
    return this.formBuilder.group({
      heliport: [this.flightRunSelection.heliport, [Validators.required]],
      flight: [this.flightRunSelection.flight, [Validators.required]],
      rundate: [formatDate(this.flightRunSelection.rundate, 'yyyy-MM-dd', 'en'), [Validators.required]]
    })
  }

}
