import { FormControl, ValidationErrors } from "@angular/forms";

const ALPHA_NUMERIC_REGEX = /^[a-zA-Z0-9 ]*$/;
const ALPHA_NUMERIC_VALIDATION_ERROR = { alphaNumericError: 'Only alpha numeric values are allowed' }
const DECIMAL_UPTO2_REGEX = /^(\d{1,20}|\d{0,20}\.\d{1,2})$/;
const DECIMAL_VALIDATION_ERROR = { decimalError: 'Only upto 2 decimals allowed' }
const DECIMAL_MIN5_REGEX = /^(\d{5,100}|\d{5,100}\.\d*)$/;
const DECIMAL_MIN5_VALIDATION_ERROR = { decimalMin5Error: 'Min 5 digits required' }

export function alphanumericValidator(control:FormControl):ValidationErrors| null {
    return ALPHA_NUMERIC_REGEX.test(control.value) ? null: ALPHA_NUMERIC_VALIDATION_ERROR;

}

export function decimalValidator(control:FormControl):ValidationErrors | null {
    return DECIMAL_UPTO2_REGEX.test(control.value) ? null : DECIMAL_VALIDATION_ERROR;
}
export function decimalMin5Validator(control:FormControl):ValidationErrors | null {
    return DECIMAL_MIN5_REGEX.test(control.value) ? null : DECIMAL_MIN5_VALIDATION_ERROR;
}