import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CompaniesComponent } from './companies.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoggingService } from 'src/app/logging/logging.service';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CvxActionModule } from 'src/app/shared/cvxActionButton/cvxActionButton.module';
import { FormsModule } from '@angular/forms';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { TableVirtualScrollModule } from 'src/app/shared/tableVirtualScroll/tableVirtualScroll.module';
import { CvxProgressModule } from 'src/app/shared/cvx-progress/cvx-progress.module';
import { OverlayModule } from '@angular/cdk/overlay';
import { MatFormFieldModule, MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MatTableModule } from '@angular/material/table';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSelectModule } from '@angular/material/select';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSortModule } from '@angular/material/sort';
import { MatButtonModule } from '@angular/material/button';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { RouterModule } from '@angular/router';
import { DirectivesModule } from 'src/app/directives/directives.module';

const components = [
  CompaniesComponent
];
const providers = [
  {provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: {floatLabel: 'always'}}
];

@NgModule({
  declarations: [
    components
  ],
  imports: [
    CommonModule,    
    MatTableModule,
    MatCheckboxModule,
    MatButtonToggleModule,
    MatDividerModule,
    MatExpansionModule,
    BrowserAnimationsModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatIconModule,
    MatCardModule,
    MatTabsModule,
    MatSelectModule,
    MatDialogModule,
    MatSortModule,
    MatButtonModule,
    MatSnackBarModule,
    FlexLayoutModule,
    FormsModule,
    CvxActionModule,
    ScrollingModule, 
    TableVirtualScrollModule,
    CvxProgressModule,     
    MatProgressSpinnerModule, 
    OverlayModule,
    RouterModule,
    DirectivesModule
  ],
  providers: [
    providers
  ],
  exports: [
    components
  ]
})
export class CompaniesModule { }
