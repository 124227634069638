import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoggingService } from 'src/app/logging/logging.service';
import { LoggingComponent } from 'src/app/logging/logging.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';

const components = [
  LoggingComponent
];

const services = [
  LoggingService
];

@NgModule({
  declarations: [
    components
  ],
  providers: [
    services
  ],
  imports: [
    CommonModule,
    MatSnackBarModule
  ],
  exports: [
    components
  ]
})
export class LoggingModule { }
