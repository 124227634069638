import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddhelicoptertypesComponent } from './addhelicoptertypes.component';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDividerModule } from '@angular/material/divider';
import { MatDialogModule } from '@angular/material/dialog';

import { ReactiveFormsModule } from '@angular/forms';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

const components = [
  AddhelicoptertypesComponent
];
const providers = [    ];


@NgModule({
  declarations: [AddhelicoptertypesComponent],
  imports: [
    CommonModule, 
    MatDialogModule, 
    MatDividerModule,
    MatFormFieldModule,
    MatCheckboxModule, 
    MatSelectModule,
    MatInputModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatButtonModule    
  ],
  providers: [providers],
  exports: [components]
})
export class AddhelicoptertypesModule { }
