import { Helicopter } from "./helicopter";
import { HelicopterTypes } from "./helicoptertypes";
import { Shorebase } from "./shorebase";
import { Shorebasejob } from "./shorebasejob";

export class JobRemarks {
    public constructor(init?: Partial<JobRemarks>) {
        Object.assign(this, init);
    }

     jobRemarkID:number;
     dayOfMonth:Date;
     shorebase:Shorebase;
     shoreBaseJob?:Shorebasejob;
     helicopter:Helicopter;
     helicopterTypes:HelicopterTypes;
     job:string;
     tailNumber:string;
     ron:number;
     scheduledAOG:number;
     unScheduledAOG:number;
     remarks:string;
     lastUpdatedBy:string
     lastUpdatedDate:Date

}

export class Remarks {
    public constructor(init?: Partial<Remarks>) {
        Object.assign(this, init);
    }

     xponder:number;
     remarks:string;
     enrouteFuel:string;

}
