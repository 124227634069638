import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MasterManifestPassenger } from '../models/mastermanifestpassenger';

@Component({
  selector: 'app-addpassengerdetails',
  templateUrl: './addpassengerdetails.component.html',
  styleUrls: ['./addpassengerdetails.component.css']
})
export class AddpassengerdetailsComponent implements OnInit {

  public passengerdetailsForm: FormGroup;
  
  locations: any = ['GAO', 'TAH', 'BFT', 'JSM'];
  companyNames: any = ['(61B) CVX', '(61B) CTX', '(61B) CORRPRO', 'C & D'];
  PickLater: any = ['Yes', 'No'];
  Status: any = ['Active', 'Inactive']

  constructor(public dialogRef: MatDialogRef<AddpassengerdetailsComponent>, 
              @Inject(MAT_DIALOG_DATA) public data: any,
              private formBuilder: FormBuilder) { }

  public ngOnInit() {
    this.initialize();
  }

  public onSubmit(): void {
    this.dialogRef.close(this.save());
  }

  public onSave(): void {
    console.log(JSON.stringify(this.passengerdetailsForm.value));
    this.dialogRef.close(this.save());
  }

  public onChangeLoc(e: any, type: string){
    const selected: string = this.locations[e.target.selectedIndex - 1];
    this.passengerdetailsForm.get(type).setValue(selected, {
      onlySelf: true
    });
  }
  public onChangeStatus(e: any, type: string){
    const selected: string = this.Status[e.target.selectedIndex - 1];
    this.passengerdetailsForm.get(type).setValue(selected, {
      onlySelf: true
    });
  }

  public onChangeCom(e: any, type: string){
    const selected: string = this.companyNames[e.target.selectedIndex - 1];
    this.passengerdetailsForm.get(type).setValue(selected, {
      onlySelf: true
    });
  }

  public onChangePic(e: any, type: string){
    const selected: string = this.PickLater[e.target.selectedIndex - 1];
    this.passengerdetailsForm.get(type).setValue(selected, {
      onlySelf: true
    });
  }

  private initialize(): void{
    if (!this.data) {
       this.passengerdetailsForm = this.createForm(this.formBuilder);
     } 
   else {
       this.passengerdetailsForm = this.updateForm(this.formBuilder, this.data);
     }
  }

  private createForm(formBuilder: FormBuilder): FormGroup {
    return formBuilder.group({
     
      Cargo: [0, Validators.required],
      CompanyName: ['', Validators.required],
      FirstName: ['', Validators.required],
      LastName: ['', Validators.required],
      FromLocation: ['', Validators.required],
      ToLocation: ['', Validators.required],
      Part135Code: ['', Validators.required],
      PassengerWeight: [0, Validators.required],
      PaxIndex: [0, Validators.required],
      PickLater: [''],
      Status:['']
    });
  }

   private updateForm(formBuilder: FormBuilder, model: MasterManifestPassenger): FormGroup {
   return formBuilder.group({
    Cargo: [model.Cargo, Validators.required],
    CompanyName: [model.CompanyName, Validators.required],
    FirstName: [model.FirstName, Validators.required],
    LastName: [model.LastName, Validators.required],
    FromLocation: [model.FromLocation, Validators.required],
    ToLocation: [model.ToLocation, Validators.required],
    Part135Code: [model.Part135Code, Validators.required],
    PassengerWeight: [model.PassengerWeight, Validators.required],
    PaxIndex: [model.PaxIndex, Validators.required],
    PickLater: [model.PickLater],
    Status: [model.Status]
   });
  }

  private save(): MasterManifestPassenger { 
    let mpassenger: MasterManifestPassenger;
    
    mpassenger = new MasterManifestPassenger(this.passengerdetailsForm.value);
    
    
    //TODO: Find proper way to map formgroup to model
    mpassenger.Cargo = Number(mpassenger.Cargo);
    mpassenger.CompanyName = `${mpassenger.CompanyName}`;
    mpassenger.FirstName = `${mpassenger.FirstName}`;
    mpassenger.LastName= `${mpassenger.LastName}`;
    mpassenger.FromLocation= `${mpassenger.FromLocation}`;
    mpassenger.ToLocation= `${mpassenger.ToLocation}`;
    mpassenger.Part135Code = `${mpassenger.Part135Code}`;
    mpassenger.PassengerWeight = Number(mpassenger.PassengerWeight);
    mpassenger.PaxIndex = Number(mpassenger.PaxIndex);
    
    return mpassenger;
  } 
}
