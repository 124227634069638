import { AfterViewInit, Component, Input, OnInit,OnChanges } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { LoggingService } from 'src/app/logging/logging.service';
import { Leg } from '../../models/leg';
import { Location } from "../../models/locations";

@Component({
  selector: 'flight-stops',
  templateUrl: './stops.component.html',
  styleUrls: ['./stops.component.css']
})
export class StopsComponent implements OnInit, AfterViewInit,OnChanges {
  @Input() legs: Leg[];

  public dataSource: MatTableDataSource<Leg>;
  public displayedColumns: string[] = ['origination', 'destination', 'paxPick', 'paxDrop', 'cargoPick', 'cargoDrop', 'burn', 'ETE', 'fuel', 'distance'];

  constructor(private logger: LoggingService) { }

  public ngOnInit(): void {
    try {     
     this.initialize();

    } catch (error) {
      this.logger.log(`Error Loading Stops: ${error}`);
    }
  }

public ngOnChanges(): void{

  if (this.legs.length>0)
  {
    this.setLegs();
    console.log("model is been updated",this.legs);
     /* this.legs=[{
      addedFuel: 2,
    burn: 3,
    cargoBal: 3,
    cargoDrop: 3,
    cargoPick: 2,
    cargoWeight: 44,
    carryingFuel: 44,
    centerofGravity: 2,
    crewWeight: 34,
    flightEngineer: 23,
    destination: {""},
    distance: 32,
    ete: 2,
    fuel: 323,
    fuelMoment: 3,
    legNumber: 1,
    legPath: "GAO > TAHITI",
    legPax: null,
    origination: Location,
    paxBal: 4,
    paxCount: 3,
    paxPick: 2,
    paxDrop: 1,
    rangeStart: 3,
    rangeEnd: 2,
    row1Weight: 32,
    row2Weight: 32,
    row3Weight: 43,
    row4Weight: 32,
    row5Weight: 45,
    row6Weight: 23,
    row7Weight: 32,
    rowsFactors:44,
    tOW: 32,
    maximumTow: 32
    }];*/
  }
}

  public ngAfterViewInit(): void {
    this.setLegs();
  }

  private setLegs() {
    try {
      this.dataSource = new MatTableDataSource<Leg>(this.legs);
    } catch (error) {
      this.logger.log(`Error Loading Stops: ${error}`);
    }
  }

  private initialize(): void {
    this.dataSource = new MatTableDataSource<Leg>();
  }
}
