import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-flight-reservations',
  templateUrl: './flight-reservations.component.html',
  styleUrls: ['./flight-reservations.component.css']
})
export class FlightReservationsComponent {

  constructor() {// Intentionally left blank 
  }

}
