import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PilotsComponent } from './pilots.component';
import { LoggingModule } from 'src/app/logging/logging.module';
import { LoggingService } from 'src/app/logging/logging.service';
import { PilotsService } from './pilots.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDividerModule } from '@angular/material/divider';
import { MatDialogModule } from '@angular/material/dialog';
import { CvxActionModule } from 'src/app/shared/cvxActionButton/cvxActionButton.module';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { TableVirtualScrollModule } from 'src/app/shared/tableVirtualScroll/tableVirtualScroll.module';
import { MatTableModule } from '@angular/material/table';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { RouterModule } from '@angular/router';
import { DirectivesModule } from 'src/app/directives/directives.module';
const components = [
  PilotsComponent
];

const providers = [
  PilotsService
];

const modules = [
  CommonModule,
  FlexLayoutModule,
  FormsModule
];

const materialModules = [
  MatTableModule,
  MatCheckboxModule,
  MatButtonToggleModule,
  MatDividerModule,
  MatExpansionModule,
  BrowserAnimationsModule,
  MatFormFieldModule,
  MatInputModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatIconModule,
  MatCardModule,
  MatTabsModule,
  MatSelectModule,
  MatDialogModule,
  MatSortModule,
  MatFormFieldModule,
  MatInputModule,
  MatPaginatorModule,
  CvxActionModule,
  ScrollingModule,
  TableVirtualScrollModule,
  RouterModule
];

@NgModule({
  declarations: [
    components
  ],
  providers: [
    providers
  ],
  imports: [
    modules,
    materialModules,
    DirectivesModule
  ],
  exports: [
    
    components
  ]
})
export class PilotsModule { }
