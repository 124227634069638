import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { FlightSelection } from '../../models/flight-selection';
import { JobRemarks } from '../../models/jobremarks';
import { LoggingService } from 'src/app/logging/logging.service';
import { CalAngularService } from '@cvx/cal-angular';

@Injectable({
  providedIn: 'root'
})
export class RemarksService {
  private api = `${environment.api}reservations/jobremarks`;
  private postapi =`${environment.api}reservations/addjobremarks`
  constructor(private http:HttpClient,private logging: LoggingService, private authservice:CalAngularService) { }
 
  get(selection:FlightSelection):Observable<JobRemarks[]> 
  {
    return this.http.get<JobRemarks[]>(this.api,{headers:environment.headers,
      params:{
        "tailnumber":selection.tailNumber.tailNumber.toString()
      }
    
    }).pipe(catchError( (error) =>
      {
        console.log(error);
        return this.logging.logError<JobRemarks[]>(`Error fetching JobRemarks: ${error.error.title}`);
    }));
  }

  save(jobremarks:JobRemarks) : Observable<number>
  {
    return this.http.post<number>(this.postapi, jobremarks,{headers: environment.headers})
    .pipe(catchError((error) =>
      {
        return this.logging.logError<number>(`Error adding JobRemarks: ${error.error.title}`);
    }));
  }


}
