import { Component, OnInit, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { IManifest, IJobRemarks, JobRemarksSelection } from '../../models/manifest';
import { LoggingService } from 'src/app/logging/logging.service';
import { CvxProgressService } from 'src/app/shared/cvx-progress/cvx-progress.service';
import { ShorebaseService } from '../../shorebase/shorebase.service';
import { Shorebase } from '../../models/shorebase';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { LocationsService } from '../../locations/locations.service';
import { RunsService } from '../../runs/runs.service';
import { Runs } from '../../models/runs';
import { Helicopter } from '../../models/helicopter';
import { HelicoptersService } from '../../helicopter/helicopter.service';
import { Router } from '@angular/router';
import { HelicoptertypesService } from '../../helicoptertypes/helicoptertypes.service';
import { HelicopterTypes } from '../../models/helicoptertypes';
import { Shorebasejob } from '../../models/shorebasejob';
import { ShoreBaseJobsService } from '../../shorebasejobs/shorebasejobs.service';
import { JobremarksService } from './jobremarks.service';

@Component({
  selector: 'app-jobremarks',
  templateUrl: './jobremarks.component.html',
  styleUrls: ['./jobremarks.component.css']
})
export class JobremarksComponent implements OnInit {

  public dataSource: MatTableDataSource<IJobRemarks>;
  public displayedColumns: string[] = ['date', 'jobNumber', 'helicopterType', 'tailNumber', 'ron', 'scheduledAOG', 'unscheduledAOG', 'remarks'];
  public jobremarks: IJobRemarks[];
  public selectedJobremarks: IManifest[];
  public jobRemarksFilter: JobRemarksSelection = new JobRemarksSelection();
  public isAllSelected: boolean;
  public selectedShoreBaseId: number;
  public selectedShoreBaseJob: string;
  public selectedHelicopterTypeId: string;
  public selectedTailNumber: string;
  public manifestFromDate: Date;
  public shoreBases: Observable<Shorebase[]>;
  public helicopters: Observable<Helicopter[]>;
  public helicopterTypes: Observable<HelicopterTypes[]>;
  public shorebaseJobs$: Observable<Shorebasejob[]>;
  public jobs$: Observable<string[]>;

  public jobs: string[];
  public shorebaseJobs: Shorebasejob[];
  public shorebases: Shorebase[];
  public helicopterType: HelicopterTypes[];
  public helicoptersAll: Helicopter[];
  public helicopter: Helicopter[];
  public filterValue = '';
  public tailNumberDisabled: boolean = true;
  public datewiseChecked:boolean = true;

  @ViewChild(MatSort, { static: false }) sort: MatSort;

  constructor(
    private jobremarksService: JobremarksService,
    private logger: LoggingService,
    private progress: CvxProgressService,
    private shoreBaseService: ShorebaseService,
    private helicoptersService: HelicoptersService,
    private helicopterTypesService: HelicoptertypesService,
    private shoreBaseJobService: ShoreBaseJobsService,
    private router: Router) { }

  ngOnInit() {
    try {

      console.log("this.sort=", this.sort);
      this.init();
    } catch (error) {
      this.logger.log(`Error loading job remarks: ${error}`);
    }
  }
  public ngAfterViewInit(): void {
    this.load();
  }

  private load(): void {

    this.shoreBases = this.shoreBaseService.getShorebases();
    this.shorebaseJobs$ = this.shoreBaseJobService.get();
    this.helicopterTypes = this.helicopterTypesService.get();
    this.helicopters = this.helicoptersService.get();
    this.helicopterTypes.subscribe(helicopterTypes => {
      this.helicopterType = helicopterTypes;
    });

    this.helicopters.subscribe(helicopters => {
      this.helicoptersAll = helicopters;
      this.helicopter = helicopters;
    });

    this.shoreBases.subscribe(shorebases => {
      this.shorebases = shorebases;

      this.shorebaseJobs$.subscribe(shorebaseJobs => {
        this.shorebaseJobs = shorebaseJobs.filter(sjob => sjob.shoreBaseID == 11);
        this.shorebaseJobs.forEach(shorebasejob => {
          console.log("shorebasejob=", shorebasejob);
          shorebasejob["shorebase"] = this.getShoreBaseById(shorebasejob.shoreBaseID);
          shorebasejob["helicopterType"] = this.getHelicopterTypeById(shorebasejob.helicopterTypeID);
          console.log("shorebasejob=", shorebasejob);
        });
        this.shorebaseJobs = this.shorebaseJobs.filter(a => a["helicopterType"] != undefined);
      });
    });

  }

  private onHelicopterTypeSelection(selectedValue: any) {
    this.helicopter = this.helicoptersAll.filter(a => a.helicopterTypeName === this.getHelicopterTypeById(selectedValue));
    this.tailNumberDisabled = false;
  }

  private getShoreBaseById(shorebaseId: number) {
    console.log("this.shorebases=", this.shorebases);
    return this.shorebases.find(a => a.shoreBaseID == shorebaseId)?.shoreBaseShortName;
  }
  private getHelicopterTypeById(helicopterTypeId: number) {
    return this.helicopterType.find(a => a.helicopterTypeID == helicopterTypeId)?.helicopterTypeName;
  }
  private getHelicopterTypeByIdByName(helicopterTypeName: string) {
    return this.helicopterType.find(a => a.helicopterTypeName == helicopterTypeName)?.helicopterTypeID;
  }
  public onFromDateChanged(event: MatDatepickerInputEvent<Date>): void {
    this.manifestFromDate = event.value;
  }

  public validateInput() {
    if (this.manifestFromDate != undefined) {
      return false;
    } else {
      return true;
    }
  }

  private init(): void {
    this.jobremarks = [];
    this.dataSource = new MatTableDataSource<IJobRemarks>();
    this.selectedJobremarks = [];
    this.getAllShoreBases();
    this.selectedShoreBaseId = 11;

  }
  public onFilter(value: string): void {
    this.dataSource.filter = value.trim().toLocaleLowerCase();
  }

  public onClear(): void {
    this.filterValue = '';
    this.onFilter(this.filterValue);
  }

  public onSearch(): void {
    try {
      this.getAllJobRemarks();
    }
    catch (error) {
      this.logger.logError(`Errors occurred retrieving job remarks: ${error}`);
    }
  }

  private getAllJobRemarks(): void {
    if (this.manifestFromDate != undefined && this.shorebases != null && this.shorebaseJobs != null) {
      this.progress.spin$.next(true);
      this.jobRemarksFilter.dateWise = this.datewiseChecked
      this.jobRemarksFilter.fromManifestDate = this.manifestFromDate;
      this.jobRemarksFilter.shoreBaseId = this.selectedShoreBaseId;
      this.jobRemarksFilter.jobNumber = this.selectedShoreBaseJob["shorebase"] + '-' + this.selectedShoreBaseJob["helicopterType"] + '#' + this.selectedShoreBaseJob["jobNumber"] || '';
      this.jobRemarksFilter.tailNumber = this.selectedTailNumber || '';
      this.jobRemarksFilter.helicopterTypeId = Number(this.selectedHelicopterTypeId) || 0;
      try {
        this.jobremarksService.getJobremarks(this.jobRemarksFilter).subscribe(
          jobremarks => {
            this.jobremarks = jobremarks;
            this.dataSource = new MatTableDataSource<IJobRemarks>(jobremarks);
            this.dataSource.sort = this.sort;
            this.progress.spin$.next(false);
          },
          error => {
            console.log("error=", error);
            this.logger.log(`Errors occurred loading job remarks, ${error}`);
            this.progress.spin$.next(false);
          });
      }
      catch (err) {
        console.log("err=", err);
      }
    }
  }

  private onDateWiseChange($event:any): void{
    console.log($event);
    this.datewiseChecked = $event.checked;
  }
  private getAllShoreBases(): void {
    this.shoreBases = this.shoreBaseService.getShorebases();
  }

  private onCellUpdate($event: any, row: any, field: string) {
    console.log("event=", $event.target.value);
    console.log("row=", row);
    let oldData = this.dataSource.data;
    let modifiedRow = oldData.find(data => data.jobRemarkID == row.jobRemarkID);
    let modifiedRows = oldData.filter(data => data.jobRemarkID != row.jobRemarkID);

    switch (field) {
      case "ron":
        modifiedRow.ron = Number($event.target.value);
        break;
      case "scheduledAOG":
        modifiedRow.scheduledAOG =  Number($event.target.value);
        break;
      case "unScheduledAOG":
        modifiedRow.unScheduledAOG =  Number($event.target.value);
        break; 
      case "remarks":
        modifiedRow.remarks = $event.target.value;
        break;
      default:
        break;
    }
    modifiedRows.push(modifiedRow);
    this.dataSource = new MatTableDataSource<IJobRemarks>(modifiedRows);
  }

  private onSave() {
    console.log("dataSource=", this.dataSource);
    console.log("dataSource data=", this.dataSource.data);
    this.jobremarksService.update(this.dataSource.data).subscribe(res=>{
      console.log("res=",res);
    })
  }

}

