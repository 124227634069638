import { Component, ElementRef,Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { LoggingService } from 'src/app/logging/logging.service';
import { CvxProgressService } from 'src/app/shared/cvx-progress/cvx-progress.service';
import { IManifestInfo, IPassengerManifest, passengerManifestInfo, WeightType } from '../../models/manifestinfo';
import { ManifestService } from '../manifest.service';
import { saveAs } from 'file-saver'

@Component({
  selector: 'app-view-manifest-model',
  templateUrl: './view-manifest-model.component.html',
  styleUrls: ['./view-manifest-model.component.css']
})
export class ViewManifestModelComponent implements OnInit {
  public inboundDataSource: MatTableDataSource<IPassengerManifest>;
  public outboundDataSource: MatTableDataSource<IPassengerManifest>;
  public inFieldDataSource: MatTableDataSource<IPassengerManifest>;
  public displayedColumns: string[] = ['from', 'to', 'miles', 'fullName', 'code', 'body', 'cargo', 'total', 'company', 'chargeCode'];
  public manifestInfo: IManifestInfo;
  public manifests: IPassengerManifest[];
  public inboundManifests: IPassengerManifest[];
  public outboundManifests: IPassengerManifest[];
  public inFieldManifests: IPassengerManifest[];
  public totalMiles: number = 0;
  public landingFuel: number = 0;
  public fuelNeeded: number = 0;
  public paxMile: boolean = false;
  public hourly: boolean = false;

  constructor(@Inject(MAT_DIALOG_DATA) public manifestData: IManifestInfo,
  public dialogRef: MatDialogRef<ViewManifestModelComponent>, 
    private manifestService: ManifestService,
    private logger: LoggingService,
    private progress: CvxProgressService,
    private route: ActivatedRoute) { }

  @ViewChild('content') content: ElementRef;

  ngOnInit(): void {
    this.inboundDataSource = new MatTableDataSource<IPassengerManifest>();
    this.outboundDataSource = new MatTableDataSource<IPassengerManifest>();
    this.inFieldDataSource = new MatTableDataSource<IPassengerManifest>();
    this.viewManifest();
  }
  private viewManifest(){
    this.manifestInfo = this.manifestData;
    this.outboundManifests = this.manifestInfo.passengerManifestInfo?.filter(a => a.from == "GAO");
    this.inboundManifests = this.manifestInfo.passengerManifestInfo?.filter(a => a.to == "GAO");
    this.inFieldManifests = this.manifestInfo.passengerManifestInfo?.filter(a => a.to != "GAO" && a.from != "GAO");
    this.inboundDataSource = new MatTableDataSource<IPassengerManifest>(this.inboundManifests);
    this.outboundDataSource = new MatTableDataSource<IPassengerManifest>(this.outboundManifests);
    this.inFieldDataSource = new MatTableDataSource<IPassengerManifest>(this.inFieldManifests);
    const legsData =  this.manifestInfo.legs;
    this.manifestInfo.manifestLegs.forEach(function (leg) {
      const leginfo =  legsData.filter(l=>l.legNumber== leg.legNumber)[0];
      leg.fuel = leginfo.carryingFuel;
     });

    this.totalMiles = this.manifestInfo.manifestLegs.map(t => t.distance).reduce((acc, value) => acc + value, 0)
        if(this.manifestInfo.flightDataSheetInfo.flightType.slice(0,3)=="S92")
        {
        this.fuelNeeded = this.manifestInfo.manifestLegs.reduce((acc, leg) => acc + Number(leg.burn + 50) , 0)
        this.fuelNeeded= this.fuelNeeded + 750;
        }
        else if(this.manifestInfo.flightDataSheetInfo.flightType.slice(0,5)=="AW139")
        {
          this.fuelNeeded = this.manifestInfo.manifestLegs.reduce((acc, leg) => acc + Number(leg.burn + 50), 0)
          this.fuelNeeded= this.fuelNeeded + 475;
        }
        this.landingFuel = this.manifestInfo.manifestLegs.reduce((_acc, leg) => (leg.fuel - leg.burn - 50), 0)
    if (this.manifestInfo.calculationInfo.charges === "Pax Miles") {
      this.paxMile = true;
    }
    else {
      this.hourly = true;
    }
    this.progress.spin$.next(false);
  }
  

  public checkboxChanged(value: boolean) {
    this.manifestInfo.flightDataSheetInfo.IFR = value;
    this.manifestInfo.calculationInfo.part135 = value;
    this.manifestInfo.calculationInfo.part91 = value;
  }

  private calculateWeightTotals(manifests: IPassengerManifest[], weightType: WeightType, _type: string): number {
    let total = 0;

    switch (weightType) {
      case WeightType.Miles: {
        total = manifests?.map(t => t.miles).reduce((acc, value) => acc + value, 0);
        break;
      }
      case WeightType.Person: {
        total = manifests?.map(t => t.bodyLbs).reduce((acc, value) => acc + value, 0);
        break;
      }
      case WeightType.Cargo: {
        total = manifests?.map(t => t.cargoLbs).reduce((acc, value) => acc + value, 0);
        break;
      }
      case WeightType.Total: {
        total = manifests?.map(t => t.totalLbs).reduce((acc, value) => acc + value, 0);
        break;
      }
      case WeightType.Pax: {
        total = manifests?.filter(a => a.bodyLbs > 0).reduce((acc, _value) => acc + 1, 0);
        break;
      }
      case WeightType.CargoCount: {
        total = manifests?.filter(a => a.bodyLbs <= 0).reduce((acc, _value) => acc + 1, 0);
        break;
      }
    }

    return total;
  }

  public calculateTotal(weightType: string, type: string): number {
    let total = 0;
    let wType: WeightType = WeightType[weightType];
    let list: passengerManifestInfo[] = [];

      if (type == "outbound") {
        total = this.calculateWeightTotals(this.outboundManifests, wType, type);
      } 
      else {
        if (type == "inbound")
        {
        total = this.calculateWeightTotals(this.inboundManifests, wType, type);
        }
        else
        {
          total = this.calculateWeightTotals(this.inFieldManifests, wType, type);
        }
      }
    return total;
  }

  public calculateEntries(){
    let entriesCount = 0;
    entriesCount = this.manifestInfo.passengerManifestInfo?.filter(a => a.bodyLbs > 0).reduce((acc, _value) => acc + 1, 0);
    entriesCount = entriesCount +this.manifestInfo.passengerManifestInfo?.filter(a => a.bodyLbs <= 0).reduce((acc, _value) => acc + 1, 0);
    return entriesCount;
  }
  private print() {
    this.progress.spin$.next(true);
    let manifestId = this.manifestInfo.manifestId;
    this.manifestService.print(manifestId).subscribe(
      (blobData) => {
        const file = new Blob([blobData], { type: 'application/pdf' });
        const fileUrl = URL.createObjectURL(file);
        
        // use saveAs fileUrl, fileName here to save ${manifestId}_Manifest_Resport.pdf
        // alternative is to display the pdf in the view and offer download button, this allows us to set a 
        // filename, but adds more work and gets away from using native browser behavior

        this.progress.spin$.next(false);
        window.open(fileUrl, '_blank');
      },
      (error) => {
        console.log("error=", error);
      }
    );
  }
  private download(){
    let manifestId = this.manifestInfo.manifestId;
    this.manifestService.print(manifestId).subscribe(
      (blobData) => {
        const file = new Blob([blobData],{type:'application/pdf'});
        const  fileUrl = URL.createObjectURL(file);
        let fileName = `${manifestId}_Manifest_Resport.pdf`;
         saveAs(fileUrl, fileName);
      },
      (error) =>{
        console.log("error=",error);
      }
      );
   }
}
