import { FlightSelection } from "./flight-selection";
import { Leg } from '../models/leg';

export interface IManifestInfo {
    manifestGUID: any;
    manifestId: number,
    flightDataSheetInfo: IFlightDataSheetInfo
    weightBalanceInfo: IWeightAndBalanceInfo
    calculationInfo: ICalculationsInfo
    passengerManifestInfo: IPassengerManifest[],
    manifestLegs: ImanifestLegInfo[],
    legs:Leg[],
    flightSelection : FlightSelection,
    waypoints?:string[],
    remarks: string;
    xponder:number;
  }

  export interface IFlightDataSheetInfo{
    pilot1Id:number;
    pilot2Id:number;
    crew:string;
    baseJob: number;
    date:string;
    flightType: string;
    flightNumber:number;
    landings:number;
    IFR: boolean;

    flightTime:number;
    stateTime: number;
    entries:number;

}

export class IWeightAndBalanceInfo {

    origin:number;
    destination: string;
    distance: number;
    ETE: number;
    TOW: number;
    fuel: number;
    highRange: number;
    lowRange:number;
    CG: number;
    pax: number;
    burn: number;
  }

  export interface ICalculationsInfo
  {
    cautionOrRemarks: string;
    enrouteFuelRemarks:string;
    paxMile: number;
    hourly: number;
    charges: string;
    part135: boolean;
    part91: boolean;
    takeOff: string;
    return: string;
    windSpeed: number;
    windDirection: number;

    code: number;
    pMile: number;
    PAX:string;
    PEC: string;

    basicWeight: number;
    moment: number;
    pilotCoPilotWeight: number;
    takeOffFuel: number;
    takeOffWeight: number;
    maxtakeOffWeight: number;
  }

  export interface IPassengerManifest
  {
    reservationID: number;
    lastName: string;
    firstName: string;
    fullName: string;
    companyId: number;
    from: string;
    to: string;
    miles: number;
    passengerName?: string;
    code135: number;
    bodyLbs: number;
    cargoLbs: number;
    totalLbs: number;
    company: string;
    chargeCode: string;
    pickLater:boolean;
    manifestPassengerId?:number;
  }

  export class ImanifestLegInfo {

    origin:any;
    destination: any;
    distance: number;
    ETE: number;
    TOW: number;
    fuel: number;
    range: string;
    CG: number;
    pax: number;
    burn: number;
    manifestLegId?:number
    legNumber?:number
  }
export class passengerManifestInfo {
  public constructor(init?: Partial<passengerManifestInfo>) {
    Object.assign(this, init);
}
}

export enum WeightType {
  
  Miles,
  Person,
  Cargo,
  Total,
  Pax,
  CargoCount  
}