import { Component, OnInit, Inject, AfterViewInit } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { Helicopter } from '../models/helicopter';
import { environment } from 'src/environments/environment';
import { HelicoptersService } from '../helicopter/helicopter.service';
import { HelicoptertypesService } from '../helicoptertypes/helicoptertypes.service';
import { Observable } from 'rxjs';
import { HelicopterTypes } from '../models/helicoptertypes';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { alphanumericValidator, decimalValidator } from 'src/app/shared/validators/validators';

@Component({
  selector: 'app-addhelicopter',
  templateUrl: './addhelicopter.component.html',
  styleUrls: ['./addhelicopter.component.css'],
  exportAs: 'app-addhelicopter'
})
export class AddhelicopterComponent implements OnInit, AfterViewInit {
  public helicopterForm: FormGroup;
  public helicopterData: Helicopter[];
  public statusList: string[] = ['Active', 'Inactive'];
  public strtailNumber: string;
  public showDupWarning: boolean;
  public helicopterTypes$: Observable<HelicopterTypes[]>;     // Obervable list of helictoper types for helicopter dropdown
  mode: string = "New";

  constructor(public dialogRef: MatDialogRef<AddhelicopterComponent>,
    @Inject(MAT_DIALOG_DATA) public helicopter: Helicopter,
    private formBuilder: FormBuilder,
    private helicoptersService: HelicoptersService,
    private helicopterTypesService: HelicoptertypesService) { }

  public ngOnInit() {
    this.initialize();
  }

  public ngAfterViewInit(): void {
    this.load();
  }

  public onSubmit(): void {
    this.showDupWarning = this.hasDuplicates();

    if (this.helicopterForm.valid && !this.showDupWarning) {
      this.dialogRef.close(this.save());
    }
  }

  public onReset(fgd: NgForm) {
    let currentMode = this.mode;
    this.showDupWarning = false;
    fgd.onReset();
    this.initialize();
    this.mode= currentMode;
  }

  public closeDialog() {
    this.dialogRef.close();
  }

  public onSave(): void {
    console.log(JSON.stringify(this.helicopterForm.value));
    this.dialogRef.close(this.save());
  }

  public compareCategoryObjects(object1: HelicopterTypes, object2: HelicopterTypes) {
    return object1 && object2 && object1.helicopterTypeID == object2.helicopterTypeID;
  }
  
  public onChangeStatus(e: any, type: string) {
    this.helicopterForm.get(type).setValue(e.value==='Active'?true:false);
  }

  private initialize(): void {
    if (!this.helicopter) {
      this.helicopterForm = this.createForm();
      this.mode = "Add";
    } else {
      this.helicopterForm = this.setForm();
      this.mode = (this.helicopter)?this.helicopter.mode:"Add";
    }
  }

  private load(): void {
    this.getHelicopters();
    this.helicopterTypes$ = this.helicopterTypesService.get();
  }

  private createForm(): FormGroup {
    this.mode = "Add";
    this.helicopter = {
      tailNumber: null,
      helicopterOwner: "",
      helicopterID: 0,
      helicopterTypes: new HelicopterTypes(),
      helicopterTypeName: "",
      basicWeight: null,
      arm: null,
      airCraftMoment: null,
      isActive: "Active",
      activeInactive: true
    };

    return this.setForm();
  }

  private setForm(): FormGroup {
    return this.formBuilder.group({
      tailNumber: [this.helicopter.tailNumber, [Validators.required,Validators.minLength(3),Validators.maxLength(20),alphanumericValidator]],
      helicopterOwner: [this.helicopter.helicopterOwner, [Validators.required,Validators.minLength(3),Validators.maxLength(20),alphanumericValidator]],
      helicopterTypes: [this.helicopter.helicopterTypes, Validators.required],
      basicWeight: [this.helicopter.basicWeight, [Validators.required,decimalValidator]],
      arm: [this.helicopter.arm, [Validators.required,decimalValidator]],
      airCraftMoment: [this.helicopter.airCraftMoment,[ Validators.required,decimalValidator]],
      isActive: [this.helicopter.isActive, Validators.required],
      activeInactive: [this.helicopter.activeInactive],
      helicopterTypeName: [this.helicopter.helicopterTypeName],
      lastUpdatedBy: [environment.account],
      lastUpdatedDate: [new Date()],
      helicopterID: [this.helicopter.helicopterID]
    });
  }

  private save(): Helicopter {
    let helicopter: Helicopter = new Helicopter(this.helicopterForm.value);
    helicopter.helicopterTypeName = helicopter.helicopterTypes.helicopterTypeName;

    return helicopter;
  }

  private hasDuplicates() {
    const strtailNumber: string = (this.helicopterForm.get('tailNumber').value);
    return !this.helicopter.helicopterID && this.helicopterData.some(row => row.tailNumber === strtailNumber)
  }

  private getHelicopters(): void {
    this.helicoptersService.get().subscribe(
      helicopters => {
        this.helicopterData = helicopters;
      });
  }
}
