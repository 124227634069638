import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HelicopterComponent } from './helicopter.component';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CvxActionModule } from 'src/app/shared/cvxActionButton/cvxActionButton.module';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { TableVirtualScrollModule } from 'src/app/shared/tableVirtualScroll/tableVirtualScroll.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatFormFieldModule, MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MatTableModule } from '@angular/material/table';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { RouterModule } from '@angular/router';
import { DirectivesModule } from 'src/app/directives/directives.module';

const components = [
  HelicopterComponent
];

const providers = [
  { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { floatLabel: 'always' } }
];

const materialModules = [
  MatTableModule,
  MatCheckboxModule,
  MatButtonToggleModule,
  MatDividerModule,
  MatExpansionModule,
  MatFormFieldModule,
  MatInputModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatIconModule,
  MatCardModule,
  MatTabsModule,
  MatSelectModule,
  MatSortModule,
  MatDialogModule,
  MatSnackBarModule,
  RouterModule
];

@NgModule({
  declarations: [
    components
  ],
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    FormsModule,
    materialModules,
    ScrollingModule,
    TableVirtualScrollModule,
    CvxActionModule,
    DirectivesModule
  ],
  providers: [
    providers
  ],
  exports: [
    components
  ]
})
export class helicopterModule { }
