import { Shorebase } from './shorebase';

export class Location {
    
    public constructor(init?: Partial<Location>) {
        Object.assign(this, init);
    }
    heliportId: number;
    heliport: string;
    locationID: number;
    isShared: string;
    lastUpdatedBy:string;
    lastUpdatedDate:Date;
    shoreBase:Shorebase;
    shoreBaseId: number;
    shoreBaseName:string;
    locationName: string;
    latitude: number;
    longitude: number;
    variation: number;
    isCrewChanged: string;
    //isSharedPoint: string;
    activeInactive: boolean;
    isActive?: string;
    mode?: string;
}
