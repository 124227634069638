import { AfterViewInit, Component, Input, OnInit, OnChanges } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { LoggingService } from 'src/app/logging/logging.service';
import { ManifestCalculationsService } from '../../manifest-calculations/manifest-calculations.service';
import { FlightSelection } from '../../models/flight-selection';
import { Leg } from '../../models/leg';
import { WbComputeComponent } from '../../wb-compute/wb-compute.component';
import { FlightSelectionService } from '../flight-selection/flight-selection.service';

const dialogWidth: string = '1050px';

@Component({
  selector: 'flight-balance',
  templateUrl: './balance.component.html',
  styleUrls: ['./balance.component.css']
})
export class BalanceComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() balance: Leg[];

  public dataSource: MatTableDataSource<Leg>;
  public click: boolean;
  public errorLegNumbers: number[] = [];
  public errorInLegs: any[] = [];
  public flightRowCount: number = 0;

  public displayedColumns: string[] = ['leg', 'location', 'PAX', 'fuel', 'fuelmoment', 'cg', 'range', 'tow', 'maxtow',
    'crew', 'row1', 'row2', 'row3', 'row4', 'row5', 'row6', 'row7', 'cargo', "getdetails"];

  constructor(private logger: LoggingService,
    public dialog: MatDialog,
    private manifestCalcService: ManifestCalculationsService,
    private flighSelectionService: FlightSelectionService) { }

  public ngOnInit(): void {
    try {
      this.initialize();
    } catch (error) {
      this.logger.log(`Error Loading Weight Balance: ${error}`);
    }
  }

  public ngOnChanges(): void {
    if (this.balance.length > 0) {
      this.setBalance();
      console.log("model is been updated");
    }
  }

  private setBalance() {
    try {
      this.dataSource = new MatTableDataSource<Leg>(this.balance);
    } catch (error) {
      this.logger.log(`Error Loading balance: ${error}`);
    }
  }

  public ngAfterViewInit(): void {
    try {
      this.dataSource = new MatTableDataSource<Leg>(this.balance);
    } catch (error) {
      this.logger.log(`Error Loading Weight Balance: ${error}`);
    }
  }

  public openDialog(legNumber: number) {
    const dialogConfig: MatDialogConfig = {
      width: dialogWidth,
      data: this.balance.find(l => l.legNumber === legNumber)
    }
    const dialogRef = this.dialog.open(WbComputeComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(_result => {
      this.balance = this.manifestCalcService.manifestCalculation?.leg;
      this.setBalance();
      this.manifestFuelCalcCheck();
    });
  }

  private initialize(): void {
    this.dataSource = new MatTableDataSource<Leg>();
    this.flighSelectionService.onFlightSelection().subscribe(
      (fs) => {
        if (fs.helicopterType.helicopterTypeName.toUpperCase() === 'AW139') { this.flightRowCount = 3 }
        else {
          this.flightRowCount = 7;
        }
      });

  }
  public getBalanceErrorCssClass(legNumber: number, field: string) {
    let cssClass;

    if (field === "carryingFuel") {
      if (this.errorInLegs.filter(leg => leg.legNumber === legNumber && leg.carryingFuel).length > 0) {
        cssClass = {
          "balanceerror": true
        };
      }

    }
    if (field === "centerofGravity") {
      if (this.errorInLegs.filter(leg => leg.legNumber === legNumber && leg.centerofGravity === true ).length > 0) {
        cssClass = {
          "balanceerror": true
        };
      }
    }
    if (field === "tow") {
      if (this.errorInLegs.filter(leg => leg.legNumber === legNumber && leg.tow).length > 0) {
        cssClass = {
          "balanceerror": true
        };
      }
    }
    return cssClass;
  }

  /**
   * @summary Check manifest legs for issues, log warnings, return boolean for errors
   * @returns true - There are no errors | false - errors exist in the manifest
   */
  public manifestFuelCalcCheck(): boolean {
    let errors: string = "";
    this.errorLegNumbers = [];
    let legs: Leg[] = [];
    legs = this.manifestCalcService.manifestCalculation?.leg;

    legs?.forEach((leg: Leg) => {
      let errorLeg: any = {};
      errorLeg.legNumber = leg.legNumber;
      errorLeg.carryingFuel = false;
      errorLeg.centerofGravity = false;
      errorLeg.tow = false;

      if (leg.carryingFuel < leg.fuel) {
        errors = errors + (errors.length > 0 ? ", " : "") + `Leg ${leg.legNumber} carrying Fuel is below required fuel`;
        this.errorLegNumbers?.push(leg.legNumber);
        errorLeg.carryingFuel = true;

      } else if (leg.centerofGravity < leg.rangeStart || leg.centerofGravity > leg.rangeEnd) {
        errors = errors + (errors.length > 0 ? ", " : "") + `Leg ${leg.legNumber} is out of CG`;
        this.errorLegNumbers?.push(leg.legNumber);
        errorLeg.centerofGravity = true;
      }
      else if (leg.tow > leg.maximumTow) {
        errors = errors + (errors.length > 0 ? ", " : "") + `Leg ${leg.legNumber} Tow > Max Tow  `;
        this.errorLegNumbers?.push(leg.legNumber);
        errorLeg.tow = true;
      }
      this.errorInLegs.push(errorLeg);
    });
    if (this.errorLegNumbers.length > 0) {
      this.logger.notify(errors, 'ok');
      return false;
    }
    return true;
  }

  public manifestWBMaxLimitCheck(fs:FlightSelection): boolean {
    let errors = "";
    this.balance.forEach((l: Leg) => {
        if (l.cargoWeight > fs.helicopterType.maxToCargoWeight) {
          errors += `Leg ${l.legNumber} exceeded max cargo weight ${fs.helicopterType.maxToCargoWeight} lbs, `;
        }
        if (l.paxCount > fs.helicopterType.passengerCapacity) {
          errors += `Leg ${l.legNumber} passengers exceeded flight passenger capacity, `;
        }
      });

    if (errors.length > 0) {
      this.logger.notify(errors, 'ok');
      return false;
    }

    return true;
  }
  checkRowError(rownumber: number): string {
    return this.errorLegNumbers.includes(rownumber) ? 'balanceerror' : "";
  }

  getColumnDefs(): string[] {
    if (this.flightRowCount >= 7) {
      return ['leg', 'location', 'PAX', 'fuel', 'fuelmoment', 'cg', 'range', 'tow', 'maxtow',
        'crew', 'row1', 'row2', 'row3', 'row4', 'row5', 'row6', 'row7', 'cargo', "getdetails"];
    } else {
      return ['leg', 'location', 'PAX', 'fuel', 'fuelmoment', 'cg', 'range', 'tow', 'maxtow',
        'crew', 'row1', 'row2', 'row3', 'cargo', "getdetails"];
    }
  }

}