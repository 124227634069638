import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LoggingService } from 'src/app/logging/logging.service';
import { environment } from 'src/environments/environment';
import { Shorebase } from '../models/shorebase';

@Injectable({
  providedIn: 'root'
})
export class ShorebaseService {
  private api = `${environment.api}shorebase/`;
  
  constructor(private http: HttpClient, private logger: LoggingService) { }

  public getShorebases(): Observable<Shorebase[]> {
    return this.http.get<Shorebase[]>(`${this.api}all`, { headers: environment.headers, params: { "includeInactive": "false"} })
      .pipe(
        catchError(error => {
          return this.logger.logError<Shorebase[]>('Error retrieving shorebases from api');
        })
      );
  }

  public getLocations(): Observable<Location[]> {
    return this.http.get<Location[]>(`${this.api}all`, { headers: environment.headers, params: { "includeInactive": "false"} })
      .pipe(
        catchError(error => {
          return this.logger.logError<Location[]>('Error retrieving shorebases from api');
        })
      );
  }

  public getShorebase(id: number): Observable<Shorebase> {
    return this.http.get<Shorebase>(this.api, { headers: environment.headers, params: { "id": id.toString() } })
      .pipe(
        catchError(error => {
          return this.logger.logError<Shorebase>(`Error retrieving shorebase info: ${id} from api`);
        })
      );
  }
}
