import { Pipe, PipeTransform } from "@angular/core";

export class Centroid {
    
    public constructor(init?: Partial<Centroid>) {
        Object.assign(this, init);
    }

    centroidID: number;
    centroidValue: string;
    helicopterRow:string;
    helicopterTypeID:number;
    lastUpdatedDate:Date;
    lastUpdatedBy:string;
    weight:number;
}

@Pipe ({
    name:'centroidbyrow'
})
export class GetCentroidValuebyRow implements PipeTransform
{
    transform(value:Centroid[],fieldName: string,fieldValue:string):string {
        return value.find(c => c.helicopterRow.toLowerCase() === fieldValue.toLowerCase()).centroidValue;
    }
}