import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {IManifest} from "src/app/dtms/models/manifest";
import { Observable, Subject } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { LoggingService } from 'src/app/logging/logging.service';
import { ShoreBaseJobsService } from '../shorebasejobs/shorebasejobs.service';
import { LocationsService } from '../locations/locations.service';
import { PassengersService } from '../passengers/passengers.service';
import { ManifestService } from '../flight-manifest/manifest.service';
import { HelicoptersService } from '../helicopter/helicopter.service';
import { HelicoptertypesService } from '../helicoptertypes/helicoptertypes.service';



@Injectable({
  providedIn: 'root'
})
export class FlightInfoService {
  private api = `${environment.api}manifest`;
  public isFuelWorkflowCompleted:boolean = false;
  //private api1 = `${environment.api}Calculations`;
  

  constructor(private http: HttpClient, 
    private logging: LoggingService, 
    public shoreBaseJobService: ShoreBaseJobsService,
    public locationService:LocationsService,
    public passengersService: PassengersService,
    public manifestService: ManifestService,
    public helicopterTypesService:HelicoptertypesService ) {
 
   }

  private _refreshNeed$ = new Subject<void>();


  get refreshNeeds$(){
    return this._refreshNeed$;
  }

  get(id: number): Observable<IManifest>
  {
    return this.http.get<IManifest>(this.api, { headers: environment.headers, params: { "id": id.toString() } })
      .pipe(
        catchError(error => {
          return this.logging.logError<IManifest>(`Error retrieving manifest: ${id} | ${error.error}`);
        })
      );
       
  }
  
  save(manifest: IManifest) : Observable<IManifest>
  {
  
    return this.http.post<IManifest>(this.api, manifest, { headers: environment.headers })
    .pipe(
      catchError(error => {
       return this.logging.logError<IManifest>(`Error saving manifest: ${error.error.title}`)
      })
    );
  }
}
