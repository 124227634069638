 export class Pilot {
    mode?: string;
    public constructor(init?: Partial<Pilot>) {
        Object.assign(this, init);
    }
    
    firstName: string;
    fullName: string;
    initials: string;
    isFlightEngineer: string;
    lastName: string;
    lastUpdatedBy: string;
    lastUpdatedDate: Date;
    pilotID: number;
    weight: number;
    activeInactive: boolean;
    isActive?: string;
}