import { Injectable } from '@angular/core';

//cdk
import { Overlay } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';

//rxjs
import { Subject } from 'rxjs'
import { scan, map } from 'rxjs/operators'
import { SpinnerComponent } from '../spinner/spinner.component';

@Injectable({
  providedIn: 'root'
})
export class CvxProgressService {

  private spinnerTopRef = this.cdkSpinnerCreate();

  public spin$ :Subject<boolean> = new Subject();

    constructor(
        private overlay: Overlay,
    ) {

      this.spin$
        .asObservable()
        .pipe(
          map(val => val ? 1 : -1 ),
          scan((acc, one) => (acc + one) >= 0 ? acc + one : 0, 0)
        )
        .subscribe(
          (res) => {
            if(res === 1){ 
              this.showProgress(); 
            }
            else if( res == 0 ){ 
              if (this.spinnerTopRef.hasAttached()) {
                this.stopProgress();
              }
            }
          }
        )
    }

    private cdkSpinnerCreate() {
        return this.overlay.create({
            hasBackdrop: true,
            backdropClass: 'dark-backdrop',
            positionStrategy: this.overlay.position()
                .global()
                .centerHorizontally()
                .centerVertically()
        });
    }

    private showProgress(){
      this.spinnerTopRef.attach(new ComponentPortal(SpinnerComponent));
    }

    private stopProgress(){
      this.spinnerTopRef.detach();
    }
}
