import { Component, OnInit, Inject, AfterViewInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { Passenger } from '../../models/passenger';
import { LoggingService } from 'src/app/logging/logging.service';
import { Observable } from 'rxjs';
import { Location } from '../../models/locations';
import { LocationsService } from '../../locations/locations.service';
import { map } from 'rxjs/operators';
import { alphanumericValidator } from 'src/app/shared/validators/validators';
import { Company } from '../../models/companies';
import { CompaniesService } from '../../companies/companies.service';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';

@Component({
  selector: 'app-addpassengers',
  templateUrl: './addpassengers.component.html',
  styleUrls: ['./addpassengers.component.css'],
  exportAs: 'app-addpassengers'
})
export class AddpassengersComponent implements OnInit, AfterViewInit {
  public passengerForm: FormGroup;
  public locations$: Observable<Location[]>;
  public companies$: Observable<Company[]>;
  public locations: Location[];
  public companies: Company[];

  constructor(public dialogRef: MatDialogRef<AddpassengersComponent>,
    @Inject(MAT_DIALOG_DATA) public passenger: Passenger,
    private formBuilder: FormBuilder,
    private locationsService: LocationsService,
    private companiesService: CompaniesService,
    private logger: LoggingService) { }

  /**
   * @function initialize
   * @summary initialize the form
   */
  public ngOnInit(): void {
    this.initialize();
  }

  /**
   * @function ngAfterViewInit
   * @summary Load form dependencies
   */
  public ngAfterViewInit(): void {
    this.load();
  }

  /**
   * @function onSubmit
   * @summary Save and close the form
   */
  public onSubmit(): void {
    if(this.passengerForm.valid){
    this.dialogRef.close(this.save());
    }
  }

  /**
   * @function onReset
   * @summary Reset and clear the form
   * @param fgd Form to reset
   */
  public onReset(fgd: NgForm): void {
    fgd.onReset();
  }

  /**
   * @function onCloseDialog
   * @summary Close the dialog 
   */
  public onCloseDialog(): void {
    this.dialogRef.close();
  }

  /**
   * @function load
   * @summary load locations and handle any necessary data tranformations
   */
  private load(): void {
    this.locations$ = this.locationsService.get()
      .pipe(
        map(locations => this.setLocations(locations))
      );
      this.companies$ = this.companiesService.get()
      .pipe(
        map(companies => this.setCompanies(companies))
      );
  }

  private IsCargoChange($event: MatSlideToggleChange){
      if($event.checked){
        this.passengerForm.controls['lastName'].clearValidators();
        this.passengerForm.controls['lastName'].updateValueAndValidity();
        this.passengerForm.controls['weight'].clearValidators();
        this.passengerForm.controls['weight'].updateValueAndValidity();
      }else{
        this.passengerForm.controls['lastName'].setValidators([Validators.required, Validators.minLength(3), Validators.maxLength(20), alphanumericValidator]);
        this.passengerForm.controls['lastName'].updateValueAndValidity();
        this.passengerForm.controls['weight'].setValidators([Validators.required, Validators.min(0),Validators.max(999)]);
        this.passengerForm.controls['weight'].updateValueAndValidity()
      }
  }
  /**
   * @function setLocations
   * @summary Filter locations for distinct location names, and patch form values to
   *          set selected location
   * @param locations locations list to transform
   * @returns transformed location list
   */
  private setLocations(locations: Location[]): Location[] {
    let distinct = locations.filter(
      (location, i, array) => array.findIndex(l => l.locationName === location.locationName) === i
    );

    // patch form values
    this.passengerForm.patchValue({
      from: this.passenger.from,
      to: this.passenger.to
    });

    return distinct;
  }

  private setCompanies(companies: Company[]): Company[] {
    let distinct = companies.filter(
      (company, i, array) => array.findIndex(c => c.description === company.description) === i
    );

    // patch form values
    this.passengerForm.patchValue({
      from: this.passenger.from,
      to: this.passenger.to
    });

    return distinct;
  }

  /**
   * @function initialize
   * @summary initialize the form
   */
  private initialize(): void {
    if (!this.passenger) {
      this.passengerForm = this.createForm();
    }
    else {
      this.passengerForm = this.setForm();
    }
  }

  /**
   * @function createForm
   * @summary set form for 'Add' / 'Create' mode
   */
  private createForm(): FormGroup {
    this.passenger = new Passenger();
    return this.setForm();
  }

  /**
   * @function setForm
   * @summary bind the form to passengers data model
   * @returns FormGroup
   */
  private setForm(): FormGroup {
    this.setNames();
    return this.formBuilder.group({
      firstName: [this.passenger.firstName, [Validators.required, Validators.minLength(1), Validators.maxLength(20), alphanumericValidator]],
      lastName: [this.passenger.lastName, [Validators.required, Validators.minLength(3), Validators.maxLength(20), alphanumericValidator]],
      from: [this.passenger.from, Validators.required],
      to: [this.passenger.to, Validators.required],
      weight: [this.passenger.weight, [Validators.required, Validators.min(0),Validators.max(999)]],
      cargo: [this.passenger.cargo, [Validators.required, Validators.min(0),Validators.max(999)]],
      company: [this.passenger.company, [Validators.required, Validators.minLength(3), Validators.maxLength(20), alphanumericValidator]],
      chargeCode: [this.passenger.chargeCode, [Validators.required, Validators.minLength(3), Validators.maxLength(20), alphanumericValidator]],
      part135Code: [this.passenger.part135Code]
    });
  }

  /**
   * @function setNames
   * @summary set passenger first and last name from passenger full name
   */
  private setNames(): void {
    try {
      if (!this.passenger.firstName && !this.passenger.lastName && this.passenger.fullName) {
        let name = this.passenger.fullName.split(' ');
        this.passenger.firstName = name[0];
        this.passenger.lastName = name[name.length - 1];
      }
    } catch (error) {
      this.logger.notify('Error retrieving passenger name', 'Ok');
    }
  }

  /**
   * @function save 
   * @summary save the passenger form as a Passenger, set fullName and totalWeight
   * @returns Passenger
   */
  private save(): Passenger {
    const passenger: Passenger = this.passengerForm.value;
    passenger.fullName = `${passenger.firstName} ${passenger.lastName}`;
    passenger.totalWeight = passenger.cargo + passenger.weight;

    return passenger;
  }
}
