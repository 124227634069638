import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';
import { RolesService } from 'src/app/auth/roles.service';

@Directive({
  selector: '[hasRole]'
})
export class HasRoleDirective implements AfterViewInit{
  @Input() userRole: string;
  
  constructor(private elementRef: ElementRef, private rolesService: RolesService) { }
  
  async ngAfterViewInit() {
    await this.checkAccess();
  }

  private async checkAccess() {
    const matchingRoles: boolean = await this.rolesService.isInGroup(this.userRole);

    this.elementRef.nativeElement.style.display = !matchingRoles ? "none" : "block";
  }

}
