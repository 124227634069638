import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { Helicopter } from '../models/helicopter';
import { LoggingService } from 'src/app/logging/logging.service';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { helicopterModule } from './helicopter.module';


@Injectable({
  providedIn: 'root'
})
export class HelicoptersService {
    public helicopters:Helicopter[] = [];
    private api = `${environment.api}aircraft/`;
    httpClient: any;

  constructor(private http: HttpClient, private logger: LoggingService) { }

  
  get(resetCache?:boolean): Observable<Helicopter[]>
  {
    return this.http.get<Helicopter[]>(!resetCache?this.api:`${this.api}?resetCache=${resetCache}`, { headers: environment.headers })
      .pipe(
        catchError(error => {
          return this.logger.logError<Helicopter[]>('Error retrieving helicopter from api');
        })
      );
  }



  add(helicopter: Helicopter) : Observable<any> {
    return this.http.post<Helicopter>(this.api, helicopter, { headers: environment.headers })
    .pipe(
      catchError(error => {
        return this.logger.logError<Helicopter[]>('Error while adding helicopter to api');
      })
    );
  }

  update(id: number, helicopter: Helicopter): Observable<any> {
    return this.http.put<Helicopter>(`${this.api}${id}`, helicopter, { headers: environment.headers })
    .pipe(
      catchError(error => {
        return this.logger.logError<Helicopter[]>('Error while updating helicopter to api');
      })
    );
  }

  delete(id: number, heli: Helicopter): Observable<any> {
    const url = `${this.api}${id}`;
    
    return this.http.delete<Helicopter>(url, { headers: environment.headers })
    .pipe(
      catchError(error => {
        return this.logger.logError<Helicopter[]>('Error deleting helicopter from api');
      })
      );
  }
  

}
