import { Company } from "./companies";
import { Passenger } from "./passenger";
import { Shorebase } from "./shorebase";
import { Location } from "./locations";

export class Reservation {
    public constructor(init?: Partial<Reservation>) {
        Object.assign(this, init);
    }

    cargo: number;
    cargoDescription: string;
    chargeCode: string;
    company?: Company;
    companyID?: number;
    companyName: string;
    destination: Location;
    destinationName: string;
    enteredBy: string;
    firstName: string;
    flownIndicator?: boolean;
    fullName: string;
    lastName: string;
    lastUpdatedBy?: string;
    lastUpdatedDate?: Date;
    origination: Location;
    originationName: string;
    part135Code?: string; // May need to create Part135 model
    part135?: Part135;
    passengerWeight: number;
    paxIndex: number;
    reservationDate?: Date;
    reservationID: number;
    runDate?: Date;
    scheduledFlightRun?: ScheduledFlightRun;
    specialRunName?: string;
    totalWeight: number;
    unAccompaniedBaggage?: boolean;
    verifyFlag?: boolean;
    runID?: number
}

export class Part135 {
    part135Code: string;
    part135Name: string;
    lastUpdatedBy?: string;
    lastUpdatedDate?: Date;
}

export class ScheduledFlightRun {
    runID: number;
    runName: string;
    runTime: string;
    activeInactive: boolean;
    shoreBase?: Shorebase;
    shoreBaseName: string;
    shoreBaseID: number
    lastupdatedby?: string
    lastupdateddate?: Date;
    isActive?: string;
}