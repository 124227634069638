import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { LoggingService } from 'src/app/logging/logging.service';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { Pilot } from '../models/pilot';

@Injectable({
  providedIn: 'root'
})
export class PilotsService {
  private api = `${environment.api}pilots/`;
  private pilots:Pilot[] = [];

  constructor(private http: HttpClient, private logger: LoggingService) { }

  public getPilots(resetCache?:boolean): Observable<Pilot[]>
  {
    return (!resetCache && this.pilots.length > 0)?of(this.pilots): this.http.get<Pilot[]>(!resetCache?this.api:`${this.api}?resetCache=${resetCache}`, { headers: environment.headers })
      .pipe(
        tap(pilots => this.pilots = pilots),
        catchError(error => {
          return this.logger.logError<Pilot[]>('Error retrieving pilots from api');
        })
      );
  }

  public getPilot(id: number): Observable<Pilot>
  {
    return this.http.get<Pilot>(this.api, { headers: environment.headers, params: { "id": id.toString() } })
      .pipe(
        catchError(error => {
          return this.logger.logError<Pilot>(`Error retrieving pilot: ${id} from api`);
        })
      );
  }
  
  add(pilot: Pilot) : Observable<any>
  {
  
    return this.http.post<Pilot>(this.api, pilot, { headers: environment.headers })
    .pipe(
      catchError(error => {
        return this.logger.logError<Pilot>(`Error adding pilot via api: ${pilot}`);
      })
    );
  }

  update(id: number, pilot: Pilot): Observable<any> {
    return this.http.put<Pilot>(`${this.api}${id}`, pilot, { headers: environment.headers })
    .pipe(
      catchError(error => {
        return this.logger.logError<Pilot>(`Error updating pilot via api: ${pilot}`);
      })
    );
  }

  delete(id: number, pilot: Pilot): Observable<any> {
    const url = `${this.api}${id}`;
    
    return this.http.delete<Pilot>(url, { headers: environment.headers })
    .pipe(
      catchError( error => {
        return this.logger.logError<Pilot>(`Error deleting pilot`);
      })
    );
  }
}
