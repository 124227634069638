import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'trimhtml'
})
export class TrimhtmlPipe implements PipeTransform {

  transform(value: string) {

    const htmlTagReg = /<\/?[error>]+>/gi;
    return value.replace(htmlTagReg, '');

  }

}
