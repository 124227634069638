import { Component, OnInit, ViewChild} from '@angular/core';
import { Observable } from 'rxjs';
import { IManifest, ManifestSelection } from '../../models/manifest';
import { ManifestService } from '../manifest.service';
import { LoggingService } from 'src/app/logging/logging.service';
import { CvxProgressService } from 'src/app/shared/cvx-progress/cvx-progress.service';
import { ShorebaseService } from '../../shorebase/shorebase.service';
import { Shorebase } from '../../models/shorebase';
import { Location } from '../../models/locations';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { LocationsService } from '../../locations/locations.service';
import { RunsService } from '../../runs/runs.service';
import { Runs } from '../../models/runs';
import { Helicopter } from '../../models/helicopter';
import { HelicoptersService } from '../../helicopter/helicopter.service';
import { environment } from 'src/environments/environment';
import { RolesService } from 'src/app/auth/roles.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-manifest',
  templateUrl: './manifest.component.html',
  styleUrls: ['./manifest.component.css']
})
export class ManifestComponent implements OnInit {

  public dataSource: MatTableDataSource<IManifest>;
  public displayedColumns: string[] = ['shoreBaseLongName', 'manifestID', 'createdDate', 'jobNumber', 'flightNumber', 'runName', 'helicopterTypeName', 'tailNumber', 'editButton', 'viewButton', 'deletebutton'];
  public manifests: IManifest[];
  public selectedManifests: IManifest[];
  public manifestFilter: ManifestSelection = new ManifestSelection();
  public isAllSelected: boolean;
  public selectedShoreBaseId: number;
  public selectedLocationPath: string;
  public selectedRunId: number;
  public selectedTailNumber: string;
  public manifestFromDate: Date;
  public manifestToDate: Date;
  public shoreBases: Observable<Shorebase[]>;
  public locations: Observable<Location[]>;
  public runs: Observable<Runs[]>;
  public helicopters: Observable<Helicopter[]>;
  public filterValue = '';
  public readonlyrole: string;
  public hasreadonlyPermissions: boolean = false;

  @ViewChild(MatSort, { static: false }) sort: MatSort;
 public  btnSearch:HTMLElement = document.getElementById('btnSearch') as HTMLElement;
  constructor(
    private manifestService: ManifestService,
    private logger: LoggingService,
    private progress: CvxProgressService,
    private shoreBaseService: ShorebaseService,
    private locationsService: LocationsService,
    private runsService: RunsService,
    private helicoptersService: HelicoptersService,
    private router: Router,
    private rolesService: RolesService) { }

  ngOnInit() {
    try {
    
      this.init();
    } catch (error) {
      this.logger.log(`Error loading manifests: ${error}`);
    }
  }

  private initialize() {
    
    this.readonlyrole = environment.readonlyRole;
    this.hasreadonlyPermissions = this.rolesService.hasRole(this.readonlyrole);
  }
  public onFromDateChanged(event: MatDatepickerInputEvent<Date>): void {
    // Date pickers should be bound to manifestFilter.to manifestFilter.from
    this.manifestFromDate = event.value;

  }

  public onToDateChanged(event: MatDatepickerInputEvent<Date>): void {
    // Date pickers should be bound to manifestFilter.to manifestFilter.from
    this.manifestToDate = event.value;

  }
  public validateInput() {
    if (this.manifestToDate != undefined && this.manifestFromDate != undefined) {
      return false;
    } else {
      return true;
    }
  }

  public onExport($event: any, rows: IManifest[]): void {
    // This method is left intentionlly blank
  }

  public onSelectRow($event: any, row: IManifest): void {
    if ($event.checked) {
      console.log(row);
      this.selectedManifests.push(row);
    } else {
      console.log(row);
      this.selectedManifests.splice(this.selectedManifests.indexOf(row), 1);
    }
  }

  private init(): void {
    this.manifests = [];
    this.dataSource = new MatTableDataSource<IManifest>();
    this.selectedManifests = [];
    this.initialize();
    this.getAllShoreBases();
    this.selectedShoreBaseId = 11;
    this.getAllManifests();
    this.getAllLocation();
    this.getAllRuns();
    this.getHelicopters();
  }
  public onFilter(value: string): void {
    this.dataSource.filter = value.trim().toLocaleLowerCase();
  }

  public onClear(): void {
    this.filterValue = '';
    this.onFilter(this.filterValue);
  }

  public async onSearch() {
    try {
      this.getAllManifests();
    } catch (error) {
      this.logger.logError(`Errors occurred retrieving manifests: ${error}`);
    }
  }

  private getAllShoreBases(): void {
    this.shoreBases = this.shoreBaseService.getShorebases();
  }
  private getAllLocation(): void {
    this.locations = this.locationsService.get();
  }
  private getAllRuns(): void {
    this.runs = this.runsService.get();
  }
  private getHelicopters(): void {
    this.helicopters = this.helicoptersService.get();
  }
  private getManifests(filterModel?: ManifestSelection): void {
    this.progress.spin$.next(true);
    this.manifestService.getManifests(filterModel).subscribe(
      manifests => {
        this.manifests = manifests;
        this.dataSource = new MatTableDataSource<IManifest>(this.manifests);
        this.progress.spin$.next(false);
      },
      error => {
        this.logger.log(`Errors occurred loading pilots, ${error}`);
        this.progress.spin$.next(false);
      }
    );
  }

  public view(event, row): void {
    window.open(`/#/manifest/${row.manifestID}`, '_blank');
  }

  public edit(event,row): void{
    window.open(`/#/flightmanifest/${row.manifestID}`, '_blank');
  }

  public delete(event,row):void{
    if (confirm("Are you sure do you want delete ")) {
      this.manifestService.delete(row.manifestID).subscribe(
        manifests => {
          this.onSearch();
        },
        error => {
          this.logger.log(`Errors occurred loading pilots, ${error}`);
        },
        () => {
        }
      );
    }
  }


  private getAllManifests(): void {
    if (this.manifestFromDate != undefined && this.manifestToDate != undefined) {
      this.progress.spin$.next(true);
      this.manifestFilter.shoreBaseId = this.selectedShoreBaseId;
      this.manifestFilter.locationPath = this.selectedLocationPath || '';
      this.manifestFilter.tailNumber = this.selectedTailNumber || '';
      this.manifestFilter.flightNumber = this.selectedRunId || 0;
      this.manifestFilter.runId = this.selectedRunId || 0;
      this.manifestFilter.fromManifestDate = this.manifestFromDate;
      this.manifestFilter.toManifestDate = this.manifestToDate;
      try {
        this.manifestService.getManifests(this.manifestFilter).subscribe(
          manifests => {
            this.manifests = manifests;
            this.dataSource = new MatTableDataSource<IManifest>(manifests);
            this.dataSource.sort = this.sort;
            this.progress.spin$.next(false);
          },
          error => {
            console.log("error=", error);
            this.logger.log(`Errors occurred loading pilots, ${error}`);
            this.progress.spin$.next(false);
          });
      }
      catch (err) {
          console.log("err=",err);
      }
    }
  }
}

