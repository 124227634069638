import { NgModule } from '@angular/core';
import { TrayComponent } from 'src/app/dtms/tray/tray.component';
import { TraysService } from 'src/app/dtms/tray/trays.service';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FlightInfoModule } from '../flight-info/flight-info.module';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { FlightReservationsModule } from '../flight-reservations/flight-reservations.module';
import { NextPage } from '@cvx/nextpage';
const components = [
  TrayComponent
];

const providers = [
  TraysService
];

@NgModule({
  declarations: [
    components
  ],
  providers: [
    providers
  ],
  imports: [
    CommonModule,
    MatCardModule,
    NgbModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    PipesModule,
    NextPage
  ],
  exports: [
    components
  ]
})
export class TraysModule { }
