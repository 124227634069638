
import { Injectable } from '@angular/core';
import { AsyncValidator, FormControl, ValidationErrors } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { CompaniesService } from '../companies/companies.service';
import { Company } from '..//models/companies';

@Injectable({
    providedIn: 'root'
  })
export class CompaniesValidator implements AsyncValidator {
    constructor(private companiesService: CompaniesService) {  }

    validate = (control: FormControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> => {
        return this.companiesService.get().pipe(
            map(companies => this.validateCompany(companies, control)),
            catchError(() => of(null))
        );
    };

    private validateCompany(companies: Company[], control: FormControl): ValidationErrors | null {
        return (companies.findIndex((company) => company.description.toUpperCase() === control.value.toUpperCase()) === -1) ? { notFound: true } : null;
    }

}
