import { BrowserModule} from '@angular/platform-browser';
import { APP_INITIALIZER, ErrorHandler, NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { environment } from '../environments/environment';
import { AppComponent } from 'src/app/app.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { TraysService } from 'src/app/dtms/tray/trays.service';
import { TraysModule } from 'src/app/dtms/tray/trays.module';
import { PassengersModule } from 'src/app/dtms/passengers/passengers.module';
import { AddpassengersModule} from 'src/app/dtms/passengers/addpassengers/addpassengers.module'
import { WbComputeModule} from 'src/app/dtms/wb-compute/wb-compute.module';
import { LoggingModule } from 'src/app/logging/logging.module';
import { LoggingService } from 'src/app/logging/logging.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PilotsModule } from './dtms/pilots/pilots.module';
import { FlightManifestModule } from 'src/app/dtms/flight-manifest/flight-manifest.module';
import { FlightInfoModule } from 'src/app/dtms/flight-info/flight-info.module';
import { MatIconModule } from '@angular/material/icon';
import { AddhelicopterModule} from 'src/app/dtms/addhelicopter/addhelicopter.module'
import { MatPaginatorModule} from '@angular/material/paginator';
import { AddcompanyModule } from './dtms/addcompany/addcompany.module';
import { MonitoringService } from './logging/monitoring.service';
import { AddpassengerdetailsModule } from './dtms/addpassengerdetails/addpassengerdetails.module';
import { HelicoptertypesModule } from './dtms/helicoptertypes/helicoptertypes.module';
import { AddhelicoptertypesModule } from './dtms/addhelicoptertypes/addhelicoptertypes.module';
import { CompaniesModule } from './dtms/companies/companies.module';
import { ManifestpassengerModule } from './dtms/manifestpassenger/manifestpassenger.module';
import { helicopterModule } from './dtms/helicopter/helicopter.module';
import { RunsModule } from './dtms/runs/runs.module';
import { LocationsModule } from './dtms/locations/locations.module';
import { AddlocationModule } from './dtms/addlocation/addlocation.module';
import { AddrunsModule } from './dtms/addruns/addruns.module';
import { ErrorHandlerService } from './errorHandler/error-handler.service';
import { AddpilotModule } from './dtms/addpilot/addpilot.module';
import { ManifestCalculationsModule} from 'src/app/dtms/manifest-calculations/manifest-calculations.module'
import { ManifestCalculationsService } from './dtms/manifest-calculations/manifest-calculations.service';
import { AddJobremarksModule } from './dtms/addjobremarks/addjobremarks.module';
import { CachingInterceptor } from './cache/caching-interceptor';
import { RequestCache, RequestCacheWithMap } from './cache/request-cache.service';
import { MatTableModule } from '@angular/material/table';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { CalAngularModule } from '@cvx/cal-angular';
import { CalInterceptor } from './auth/cal.interceptor';
import { CalInterceptorModule } from './auth/cal.interceptor.module';
import { AppConfigService } from './app.config.service';
import { SpinnerComponent } from './shared/spinner/spinner.component';
import { WbComputeComponent } from './dtms/wb-compute/wb-compute.component';
import { RouterModule } from '@angular/router';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { DirectivesModule } from 'src/app/directives/directives.module';
import { FlightReservationsModule } from './dtms/flight-reservations/flight-reservations.module';
import { ViewManifestModelComponent } from './dtms/flight-manifest/view-manifest-model/view-manifest-model.component';
import { HeaderComponent, NextPage, NextPageLayoutService, NextPageOptionsModel, NextPagePropertiesModel, NextPageService } from '@cvx/nextpage';

const appInitializerFn = (appConfig: AppConfigService) => {
  return () => {
    return appConfig.loadAppConfig();
  };
};

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    CalAngularModule.forRoot(environment.calConfig),
    CalInterceptorModule.forRoot(environment.calInterceptorConfig),
    AppRoutingModule,
    LoggingModule,
    MatTableModule,
    TraysModule,
    PassengersModule,
    AddpassengersModule,
    ReactiveFormsModule,
    FormsModule,
    PilotsModule,
    FlightManifestModule,
    FlightInfoModule,
    helicopterModule,
    WbComputeModule,
    RunsModule,
    LocationsModule,
    AddlocationModule,
    AddrunsModule,
    MatPaginatorModule,
    AddhelicopterModule,
    CompaniesModule,
    AddhelicopterModule,
    AddJobremarksModule,
    AddcompanyModule,
    ManifestpassengerModule,
    AddpassengerdetailsModule,
    HelicoptertypesModule,
    AddhelicoptertypesModule,
    MatIconModule,
    AddpilotModule,
    MatSnackBarModule,
    ManifestCalculationsModule,
    RouterModule,
    MatAutocompleteModule,
    DirectivesModule,
    FlightReservationsModule,
    NextPage
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
  entryComponents: [
    AppComponent,
    WbComputeComponent,
    SpinnerComponent,
    ViewManifestModelComponent
  ],
  exports: [
    AppComponent,
    ReactiveFormsModule,
    FormsModule
  ],
  providers: [
    LoggingService, 
    TraysService,
    MonitoringService,
    ManifestCalculationsService,
    NextPageService,
    NextPageLayoutService,
    { provide: ErrorHandler, useClass: ErrorHandlerService },
    { provide: RequestCache, useClass: RequestCacheWithMap },
    { provide: HTTP_INTERCEPTORS, useClass: CalInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: CachingInterceptor, multi: true },
    { provide: APP_INITIALIZER, useFactory: appInitializerFn, multi: true, deps: [ AppConfigService ] }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

// export class AppTest {
//   constructor(private injector: Injector) { }
//   ngBootStrap() {// This is left intentionally blank
//   }
// }
