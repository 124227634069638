import { Component, OnInit, ViewChild } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { LoggingService } from 'src/app/logging/logging.service';
import { MasterManifestPassenger } from '../models/mastermanifestpassenger';
import { ManifestpassengerService } from './manifestpassenger.service';
import { AddpassengerdetailsComponent } from '../addpassengerdetails/addpassengerdetails.component';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
      
/**
 * @description Intended for passenger master db integration, currently unused
 */
@Component({
  selector: 'app-manifestpassenger',
  templateUrl: './manifestpassenger.component.html',
  styleUrls: ['./manifestpassenger.component.css']
})
export class ManifestpassengerComponent implements OnInit {

  filterValues = {};
  public dataSource: MatTableDataSource<MasterManifestPassenger>;
 
  public displayedColumns: string[] = ['select', 'Cargo', 'ChargeCode', 'Distance', 'FirstName','LastName','FromLocation','ToLocation','Part135Code','PassengerWeight','PaxIndex','PickLater'];
  public selection = new SelectionModel<MasterManifestPassenger>(true, []);
  public dataSource_confirmedPassenger: MatTableDataSource<MasterManifestPassenger>;
  
  public reservedPassenger: MasterManifestPassenger[];
  public confirmedPassenger: MasterManifestPassenger[];
  public step = 1;
  filterSelectObj = [];
 
  public filterValue = '';

  @ViewChild(MatSort, {static: false}) sort: MatSort;
    
  
  constructor(
    private ManifestpassengerService: ManifestpassengerService,
    public dialog: MatDialog,
    private logger: LoggingService
  ) { }

  public ngOnInit() {
    try {
      this.confirmedPassenger = [];
      this.reservedPassenger = [];

      this.getPessengerDetails();
    } catch (error) {
      this.logger.log(`Error Loading passengers: ${error}`);
    }
  }
  public onFilter(value: string): void {
    this.dataSource.filter = value.trim().toLocaleLowerCase();
  }

  public onClear(): void {
    this.filterValue = '';
    this.onFilter(this.filterValue);
  }

  private initialize() {
    this.confirmedPassenger = [];
    this.reservedPassenger = [];
  }

  public add() {
   
    let dialogConfig: MatDialogConfig = {
      width: '550px',
      disableClose: false,
      autoFocus: true,
      hasBackdrop: true
    };

    const dialogRef = this.dialog.open(AddpassengerdetailsComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        let passengerData: MasterManifestPassenger = result;

        
        this.confirmedPassenger.push(passengerData);
        this.onConfirm();
      }
    });
  }

  public edit($event : any, row:MasterManifestPassenger) {
    let dialogConfig: MatDialogConfig = {
      width: '550px',
      disableClose: false,
      autoFocus: true,
      hasBackdrop: true,
      data: row
    };

    const dialogRef = this.dialog.open(AddpassengerdetailsComponent, dialogConfig);
  

    dialogRef.afterClosed().subscribe(result => {
      this.confirmedPassenger = this.confirmedPassenger.filter((value,key)=>{

        row.Cargo= result.Cargo;
        row.ChargeCode = result.ChargeCode;
        row.Distance = result.Distance;
        row.FirstName = result.FirstName;
        row.LastName = result.LastName;
        row.FromLocation = result.FromLocation;
        row.ToLocation =result.ToLocation;
        row.Part135Code = result.Part135Code;
        row.PassengerWeight= result.PassengerWeight;
        row.PaxIndex= result.PaxIndex;
        row.PickLater= result.PickLater;
    
        return true;
      });
    });
  }

  /** Whether the number of selected elements matches the total number of rows. */
  public isAllSelected($event) {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }
  
  /** Selects all rows if they are not all selected; otherwise clear selection. */
  public masterToggle($event) {
    this.isAllSelected($event) ?
        this.selection.clear() :
        this.dataSource.data.forEach(row => this.selection.select(row));
  }

  public onSelectRow($event : any, row:MasterManifestPassenger) {
    if ($event.checked) {
      this.logger.log(row);
      this.confirmedPassenger.push(row);
    } else {
      this.logger.log(row);
      this.confirmedPassenger.splice(this.confirmedPassenger.indexOf(row), 1);
    }
  }

  public onConfirm() {
      this.dataSource= new MatTableDataSource<any>(this.confirmedPassenger);
  }
  
  public setStep(index: number) {
    this.step = index;
  }

   public nextStep() {
    this.step--;
   }

  private getPessengerDetails(): void {
    this.ManifestpassengerService.get().subscribe(
      data => {
        this.reservedPassenger = data;
        this.dataSource = new MatTableDataSource<MasterManifestPassenger>(this.reservedPassenger);
      },
      error => this.logger.log(`Errors occurred loading ManifestPassenger, ${error}`)
    );
  }


  public update(data: MasterManifestPassenger): void {
    this.ManifestpassengerService.update(data.ManifestPassengerID, data).subscribe(
      result => {
        // alternative update - https://stackoverflow.com/a/48500099/12233405
        
        this.dataSource = new MatTableDataSource<MasterManifestPassenger>(this.confirmedPassenger);
      },
      error => this.logger.logError<MasterManifestPassenger>(`Error while updating passenger data: ${data.ManifestPassengerID}
        |${error}`)
    );
  }

  public onDelete($event: any): void {
    this.confirmedPassenger.forEach(row => {
      this.delete(row);
    });

    this.dataSource = new MatTableDataSource<MasterManifestPassenger>(this.reservedPassenger);
  }
  public delete(row: MasterManifestPassenger): void {
    this.ManifestpassengerService.delete(row.ManifestPassengerID, row).subscribe(
      _ => {
        this.confirmedPassenger.splice(this.confirmedPassenger.indexOf(row), 1);  
        this.reservedPassenger.splice(this.reservedPassenger.indexOf(row), 1);     
      },
      error => this.logger.logError<MasterManifestPassenger>(`Error deleting passengers: ${row.ManifestPassengerID}|${error}`)
    );
  }
}






