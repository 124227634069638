import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { LoggingService } from 'src/app/logging/logging.service';
import { environment } from 'src/environments/environment';
import { Location } from 'src/app/dtms/models/locations';
import { catchError, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LocationsService {
  private api = `${environment.api}locations/`;
  public locations:Location[] =[];

  httpClient: any;

  constructor(private http: HttpClient, private logger: LoggingService) { }

  get(resetCache?:boolean): Observable<Location[]> {
    return (!resetCache && this.locations.length > 0) ? of(this.locations): this.http.get<Location[]>(!resetCache?this.api:`${this.api}?resetCache=${resetCache}`, { headers: environment.headers })
      .pipe(
        tap(l => this.locations = l),
        catchError(error => {
          return this.logger.logError<Location[]>(`Error retrieving location from api, Error: ${error.error}`, error,);
        })
      );
  }

  add(run: Location): Observable<Location> {
    return this.http.post<Location>(this.api, run, { headers: environment.headers })
      .pipe(
        catchError(error => {
          return this.logger.logError<Location>(`Error adding location from api, Error: ${error.error}`, error,);
        })
      );
  }

  update(id: number, location: Location): Observable<Location> {
    return this.http.put<Location>(`${this.api}${id}`, location, { headers: environment.headers })
      .pipe(
        catchError(error => {
          return this.logger.logError<Location>(`Error while updating location to api, Error: ${error}`, error);
        })
      );
  }

  delete(id: number, loc: Location): Observable<any> {
    const url = `${this.api}${id}`;


    return this.http.delete<Location>(url, { headers: environment.headers })
      .pipe(
        catchError(error => {
          return this.logger.logError<Location[]>('Error deleting location from api');
        })
      );
  }

}
