import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PassengersManifestComponent } from './passengers-manifest.component';
import { PassengersService } from './passengers.service';
import { LoggingService } from 'src/app/logging/logging.service';
import { ModalModule } from "ngx-bootstrap/modal";
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDividerModule } from '@angular/material/divider';
import { MatDialogModule } from '@angular/material/dialog';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PassengersDataService } from './passengers-data.service';
import { PassengersComponent } from './passengers.component';
import { FlightInfoModule } from '../flight-info/flight-info.module';
import { CvxActionModule } from 'src/app/shared/cvxActionButton/cvxActionButton.module';
import { MatTableModule } from '@angular/material/table';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSortModule } from '@angular/material/sort';
import { DirectivesModule } from 'src/app/directives/directives.module';

const components = [
  PassengersManifestComponent,
  PassengersComponent
];

const providers = [
  PassengersService,
  PassengersDataService
];

@NgModule({

  declarations: [components, PassengersComponent, PassengersManifestComponent],
  imports: [
    CommonModule,
    MatTableModule,
    MatCheckboxModule,
    MatButtonToggleModule,
    MatButtonModule,
    MatDividerModule,
    MatExpansionModule,
    BrowserAnimationsModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatIconModule,
    MatCardModule,
    MatTabsModule,
    MatSelectModule,
    MatDialogModule,
    MatSortModule,
    FlexLayoutModule,
    FormsModule,
    CvxActionModule,
    FlightInfoModule, // Remove this with restructure
    DirectivesModule
  ],
  providers: [
    providers
  ],
  exports: [
    components
  ]
})
export class PassengersModule { }
