import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FormBuilder, FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import {Pilot} from 'src/app/dtms/models/pilot';
import { environment } from 'src/environments/environment';
import { PilotsService } from 'src/app/dtms/pilots/pilots.service'
import { alphanumericValidator } from 'src/app/shared/validators/validators';

@Component({
  selector: 'app-addpilot',
  templateUrl: './addpilot.component.html',
  styleUrls: ['./addpilot.component.css']
})
export class AddpilotComponent implements OnInit {
  public pilotForm: FormGroup;
  public statusList: string[] = ['Active', 'Inactive'];
  public isFlightEngineer: string[] = ['No'];
  public weightnumber: string ="^[0-9]*$"
  public Pilots: Pilot[];
  public submitted: boolean;
  public showDupWarning: boolean ;
  public currentValue: string;
  mode: string = "New";

  constructor(@Inject(MAT_DIALOG_DATA) public data: Pilot,
              public dialogRef: MatDialogRef<AddpilotComponent>, 
              private formBuilder: FormBuilder,  
              private pilotService: PilotsService) { }

  ngOnInit() {
    this.Pilots = [];
    this.initialize();
  }

  onSubmit() {
    this.showDupWarning = this.hasDuplicates();

    if (this.pilotForm.valid && !this.showDupWarning)
    {
      this.dialogRef.close(this.save());
    }
  }

  private hasDuplicates()
  {
    const pilotLastName: string = (this.pilotForm.get('lastName').value);
    const pilotFirstName: string =(this.pilotForm.get('firstName').value);
    return !this.pilotForm.get('pilotID').value && this.Pilots.some(row=> row.firstName === pilotFirstName  &&  row.lastName === pilotLastName) 
  }

  public onReset(fgd: NgForm) {
    let currentMode = this.mode;
    this.showDupWarning=false;
    fgd.onReset();
    this.initialize();
    this.mode= currentMode;
  }
  
  public closeDialog(){
    this.dialogRef.close();
  }

  public onChangestatus(e: any, type: string){
    this.pilotForm.get(type).setValue(e.value==='Active'?true:false);
  }

  private initialize(): void{
    this.getPilots();

    if (!this.data) {
      this.pilotForm = this.createForm(this.formBuilder);
      this.mode = "Add";
    } else {
      this.pilotForm = this.updateForm(this.formBuilder, this.data);
      this.mode = (this.data)?this.data.mode:"Add";
    }
  }

  private getPilots(): any {
    this.pilotService.getPilots().subscribe(
      pilotData => {
        this.Pilots = pilotData;
      },
      //error => this.loggin.log(`Errors occurred loading data, ${error}`)
    );
  }

  private createForm(formBuilder: FormBuilder): FormGroup {
    this.mode = "Add";
    return formBuilder.group({
      lastName: ['', Validators.required],
      firstName: ['', Validators.required],
      fullName: '',
      weight: [0, Validators.required],
      initials: ['', Validators.required],
      isActive:['Active', Validators.required],
      activeInactive: true,
      lastUpdatedBy: environment.account,
      lastUpdatedDate: new Date(),
      pilotID: 0,
    });
  }

  private updateForm(formBuilder: FormBuilder, model: Pilot): FormGroup {
    this.mode = "Edit";
    return formBuilder.group({
      lastName: [model.lastName, [Validators.required,Validators.minLength(3),Validators.maxLength(20),alphanumericValidator]],
      firstName: [model.firstName, [Validators.required,Validators.minLength(3),Validators.maxLength(20),alphanumericValidator]],
      weight: [model.weight, [Validators.required, Validators.max(300)]],
      initials: [model.initials, [Validators.required,Validators.maxLength(2)]],
      fullName: [model.fullName],
      isActive:[model.isActive, Validators.required],
      activeInactive: [model.activeInactive],
      lastUpdatedBy: [environment.account],
      lastUpdatedDate: [new Date()],
      pilotID: model.pilotID
    });
  }

   private save(): Pilot {
    let pilot: Pilot;
    
    pilot = new Pilot(this.pilotForm.value);
    if (!pilot.fullName || pilot.fullName == ''){
      pilot.fullName = `${pilot.firstName} ${pilot.lastName}`;
    }
    return pilot;
  }
}
