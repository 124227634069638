export class CalcDistanceModel {
    
    public constructor(init?: Partial<CalcDistanceModel>) {
        Object.assign(this, init);
    }

    oLocationName?:string;
    dLocationName?:string;
    oLocationId?:number;
    dLocationId?:number;
    oLatitude: number;
    oLongitude: number;
    dLatitude: number;
    dLongitude: number;
    distance: number;
}