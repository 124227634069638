import { FormGroup, ValidatorFn } from '@angular/forms';

export const passengerWeightValidator: ValidatorFn = (group: FormGroup) => {
    const cargo = group.get('cargo').value;
    const body = group.get('body').value;
    const total = group.get('total').value;
    
    const totalValid = Number(total) === Number(cargo) + Number(body);
    
    totalValid ? group.get('total').setErrors(null) : group.get('total').setErrors({ totalInvalid: true });

    return  totalValid ? null : { totalInvalid: true };
};