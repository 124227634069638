import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';  
import { ReactiveFormsModule,FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AddpilotComponent } from './addpilot.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';

const components = [
  AddpilotComponent
];
const providers = [    ];


@NgModule({
  declarations: [AddpilotComponent],
  imports: [
    CommonModule, 
    MatDialogModule, 
    MatDividerModule,
    MatFormFieldModule,
    MatCheckboxModule, 
    MatSelectModule,
    MatInputModule,
    MatButtonModule,
    ReactiveFormsModule,
    FormsModule,
    NgbModule
  ],
  providers: [providers],
  exports: [components]
})
export class AddpilotModule { }
