import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LoggingService } from 'src/app/logging/logging.service';
import { environment } from 'src/environments/environment';
import { Reservation } from '../models/reservation';

@Injectable({
  providedIn: 'root'
})
export class ReservationsDataService {
  private api = `${environment.api}reservations`;

  constructor(private http: HttpClient, private logger: LoggingService) { }

  public getById(id: number): Observable<Reservation> {
    return this.http.get<Reservation>(this.api, { headers: environment.headers, params: { "id": id.toString() } })
      .pipe(
        catchError(error => {
          return this.logger.logError<Reservation>(`Error retrieving reservation: ${id}`, error);
        })
      );
  }

  public get(date: Date): Observable<Reservation[]> {
    return this.http.get<Reservation[]>(this.api, 
      { headers: environment.headers, 
        params: { "date": date.toISOString() } 
      })
      .pipe(
        catchError(error => {
          return of(error);
        })
      );
  }

  public add(reservation: Reservation): Observable<Reservation>{
    return this.http.post<Reservation>(this.api, reservation, 
      { headers: environment.headers })
    .pipe(
      catchError(error => {
        return this.logger.logError<Reservation>(`Error adding reservation`, error);
      })
    );
  }

  public addList(reservations: Reservation[]): Observable<Reservation[]> {
    return this.http.post<Reservation[]>(`${this.api}/list`, reservations,
      { headers: environment.headers })
      .pipe(
        catchError(error => {
          return this.logger.logError<Reservation[]>(`Error adding reservations`, error);
        })
      );
  }

  public update(id: number, reservation: Reservation): Observable<Reservation> {
    const url = `${this.api}/${id}`;

    return this.http.put<Reservation>(url, reservation,
      { headers: environment.headers })
      .pipe(
        catchError(error => {
          return this.logger.logError<Reservation>(`Error updating reservation`, error);
        })
      );
  }

  public delete(id: number): Observable<number> {
    const url = `${this.api}/${id}`;
    
    return this.http.delete<number>(url, { headers: environment.headers })
    .pipe(
      catchError( error => {
        return this.logger.logError<number>(`Error deleting reservation`, error);
      })
    );
  }

  
}
