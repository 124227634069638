import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LoggingService } from 'src/app/logging/logging.service';
import { environment } from 'src/environments/environment';
import { Leg } from '../models/leg';
import { IManifest } from '../models/manifest';
import { ManifestCalculation } from '../models/manifest-calculation';
import { FlightSelection } from '../models/flight-selection';
import { Centroid } from '../models/centroid';
import { CalcDistanceModel } from '../models/calclocationdistance';

@Injectable({
  providedIn: 'root'
})
export class ManifestCalculationsService {
  private api = `${environment.api}calculations`;
  // Set from from flight-info component during compute
  public manifestCalculation: ManifestCalculation;

  constructor(private http: HttpClient, private logger: LoggingService) { }

  public postBalance(manifest: ManifestCalculation): Observable<Leg[]> {
    return this.http.post<Leg[]>(`${this.api}/balance`, manifest, { headers: environment.headers })
      .pipe(
        catchError(error => {
          return this.logger.logError<Leg[]>('Error retrieving aircraft balance calculations', error);
        })
      );
  }
  public postStops(manifest: ManifestCalculation): Observable<Leg[]> {
    return this.http.post<Leg[]>(`${this.api}/stops`, manifest, { headers: environment.headers })
      .pipe(
        catchError(error => {
          return this.logger.logError<Leg[]>('Error retrieving aircraft stop to stop calculations', error);
        })
      );
  }

  public postDistance(distance:CalcDistanceModel):Observable<CalcDistanceModel> {

    return this.http.post<CalcDistanceModel>(`${this.api}/distance`, distance, { headers: environment.headers })
    .pipe(
      catchError(error => {
        return this.logger.logError<CalcDistanceModel>('Error retrieving distance between locations', error);
      })
    );
  }

  public SaveManifest(manifest: any): Observable<IManifest> {

    return this.http.post<IManifest>(this.api, manifest, { headers: environment.headers })
      .pipe(
        catchError(error => {
          return this.logger.logError<IManifest>(`Error retrieving manifest: ${manifest}`)
        })
      );
  }

  public map(manifest: ManifestCalculation): ManifestCalculation {
    const mappedManifest: ManifestCalculation = {
      //TODO: Add Mapping here
      windDirection: manifest.windDirection,
      windSpeed: manifest.windSpeed,
      leg: manifest.leg,
      crewWeight: manifest.crewWeight

    };

    return mappedManifest;
  }

  public calculateFuelNeeded(manifestLegs: Leg[], flightselected: FlightSelection): number {
    let fuelNeeded: number = 0;
    manifestLegs.forEach((leg: Leg) => fuelNeeded = fuelNeeded + leg.fuel);
    fuelNeeded = fuelNeeded + (flightselected.helicopterType.burnPerHour * 0.5);

    return fuelNeeded;

  }

  public calculateNetWeight(manifestLegs: Leg[], flightselected: FlightSelection): number {
    let InitialLeg: Leg = manifestLegs.find((l) => l.legNumber === 1);
    let requiredFuel = this.calculateFuelNeeded(manifestLegs,flightselected);
    return flightselected.tailNumber.basicWeight + InitialLeg.crewWeight +
      InitialLeg.paxPick +
      InitialLeg.cargoPick +
      requiredFuel;
  }

  public postRecomputeLegs(manifest: ManifestCalculation, selectedLegNumber: number): Observable<Leg[]> {

    return this.http.post<Leg[]>(`${this.api}/recomputelegs/${selectedLegNumber}`, manifest, { headers: environment.headers })
      .pipe(
        catchError(error => {
          return this.logger.logError<Leg[]>('Error recomputing legs', error);
        })
      );
  }

  public getCentroids(helicopterTypeId: number): Observable<Centroid[]> {
    return this.http.get<Centroid[]>(`${this.api}/getcentroids/${helicopterTypeId}`, { headers: environment.headers })
      .pipe(
        catchError(error => {
          return this.logger.logError<Centroid[]>('Error getting centroids', error);
        })
      );
  }

  public calculateFlightTime(legs: Leg[]): number {
    let flightDuration:number = 0
    legs.forEach((l) => flightDuration = flightDuration + l.ete);
    return parseFloat(flightDuration.toPrecision(2));
  }

}