import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LoggingService } from 'src/app/logging/logging.service';
import * as XLSX from 'xlsx';
import { Passenger } from '../../models/passenger';
import { PassengersService } from '../../passengers/passengers.service';
import { formatDate } from '@angular/common';
import { Location } from '../../models/locations';
import { Company } from '../../models/companies';
import { CvxProgressService } from 'src/app/shared/cvx-progress/cvx-progress.service';
import { ReservationsService } from '../../services/reservations.service';
import { Runs } from '../../models/runs';
import { Reservation } from '../../models/reservation';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { passengerWeightValidator } from 'src/app/dtms/validators/passenger-weight-validator';
import { LocationsValidator } from 'src/app/dtms/validators/locations-validator';
import { CompaniesValidator } from 'src/app/dtms/validators/companies-validator';

type xlsxData = any[][];

/**
 * @name FlightImportComponent
 * @description import flight reservations from xlsx
 */
@Component({
  selector: 'flight-import',
  templateUrl: './flight-import.component.html',
  styleUrls: ['./flight-import.component.css']
})
export class FlightImportComponent implements OnInit {
  /**
   * @name dialogMode
   * @description hide certain elements when in dialog mode for better renderig in dialog
   */
  @Input() dialogMode: boolean;

  /**
   * @name allowEditMode
   * @description allow editable rows
   */
  @Input() allowEditMode: boolean = true;

  /**
   * @name submitClick
   * @description submit callback handler
   */
  @Output() submitClick = new EventEmitter();

  /**
   * @name editClick
   * @description edit mode callback handler
   */
  @Output() editClick = new EventEmitter<boolean>();

  public model = Date.now();
  public jstoday = '';

  public headerData: string[] = ['from', 'to', 'passenger name', 'body', 'cargo', 'total', 'company', 'charge code'];
  public data: xlsxData = null;
  public wopts: XLSX.WritingOptions = { bookType: 'xlsx', type: 'array' };
  public fileName: string = 'manifest.xlsx';
  public isEditable: boolean;
  public editRow: number;
  public runs: Runs[];
  public importForm: FormGroup;

  constructor(
    private logger: LoggingService,
    private passengersService: PassengersService,
    private progress: CvxProgressService,
    private resService: ReservationsService,
    private fb: FormBuilder,
    private locationsValidator: LocationsValidator,
    private companiesValidator: CompaniesValidator) {
  }

  public get getFormControls() {
    return this.importForm.get('importRows') as FormArray;
  }

  public ngOnInit(): void {
    this.jstoday = formatDate(this.model, 'dd-MM-yyyy', 'en-US', '+0530');
    this.passengersService.runsService.get().subscribe(runs =>
      this.runs = runs);
    this.editRow = -1;

    this.initializeForm();
  }

  public onSubmit(): void {
    if (this.data != null && this.data.length >= 1) {
      if (this.validateTotalCalc(this.data)) {
        this.progress.spin$.next(true);
        this.validateMasterData();
      }
      else {
        this.logger.notify('Incorrect total weight. Please correct and try again', 'ok');
      }
    } else {
      this.logger.notify('Please add Passengers to proceed', 'ok')
    }

  }

  public onReset(uploadedFile?: any) {
    try {
      // clear data [][], clear passengers store, clear file input
      this.data = null;
      this.initializeForm();

      if (uploadedFile) {
        uploadedFile.value = null;
      }
    } catch (e) {
      this.logger.logError(e);
    }
  }

  /**
   * @description Read in spreadsheet and load data
   * @param evt 
   */
  public onFileChange(evt: any): void {
    /* wire up file reader */
    const target: DataTransfer = <DataTransfer>(evt.target);
    if (target.files.length !== 1) throw new Error('Cannot use multiple files');
    const reader: FileReader = new FileReader();

    reader.onload = (e: any) => {
      this.onReset(null);
      /* read workbook */
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

      /* grab first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];
      let importedHeaders: string[] = this.getHeaderRow(ws).sort();
      let tempHeaderdata: string[] = [...this.headerData].sort();
      if (tempHeaderdata.join(',') !== importedHeaders.join(',')) {
        this.logger.notify('Incorrect Template imported. Please correct and try again', 'ok')
        return false;
      }
      /* save data */
      this.data = <xlsxData>(XLSX.utils.sheet_to_json(ws, { header: 0 }));
      this.setImportForm();

      console.log(this.data);
    };
    reader.readAsBinaryString(target.files[0]);
  }

  public onCancel(_$event, row: number) {
    // reset row and handle editable
    this.initializeForm();

    this.onEdit(_$event, row);
    this.setImportForm();  // may be throwing error
  }

  public onEdit(_$event: any, row: number) {
    this.isEditable = !this.isEditable;
    this.editRow = row;

    this.editClick.emit(this.isEditable);
    console.log(_$event);
  }

  public onSave(_$event: any, rowIndex: number) {
    // Copy the form row to data []
    this.data[rowIndex] = this.getFormControls.controls[rowIndex].value;
    this.onEdit(_$event, rowIndex);
  }

  public onExport(): void {
    /* generate worksheet */
    const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet([this.headerData]);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.fileName);
  }

  private mapPassengers(data: any[][]): Passenger[] {
    // remove the first row in array

    let passengers: Passenger[] = [];
    data.forEach(passenger => {

      passengers.push({
        "fullName": (<string>passenger[this.headerData[2]]).trim(),
        "date": "",
        "from": passenger[this.headerData[0]],
        "to": passenger[this.headerData[1]],
        "weight": passenger[this.headerData[3]],
        "cargo": passenger[this.headerData[4]],
        "totalWeight": passenger[this.headerData[5]],
        "company": passenger[this.headerData[6]],
        "chargeCode": passenger[this.headerData[7]],
        "pickLater": false
      })
    });

    return passengers;
  }

  // ** Can be removed
  private validatePassengerLocations(locations: Location[]): boolean {
    let validationStatus: boolean = true;
    let updatedData = this.data.map((row: any) => {
      if (locations.findIndex((loc) => loc.locationName.toUpperCase() === row.to.toUpperCase()) === -1) {
        let index = this.getFormControls.controls.findIndex((ctrl: FormGroup) => ctrl.controls['to'].value === row.to);
        this.getFormControls.controls[index].get('to').setErrors({ 'required': true });

        validationStatus = false;
      }
      if (locations.findIndex((loc) => loc.locationName.toUpperCase() === row.from.toUpperCase()) === -1) {
        let index = this.getFormControls.controls.findIndex((ctrl: FormGroup) => ctrl.controls['from'].value === row.to);
        this.getFormControls.controls[index].get('from').setErrors({ 'required': true });

        validationStatus = false;
      }

      return row;
    });
    this.data = [...updatedData];
    return validationStatus;
  }

  // ** Can be removed 
  private validatePassengerCompany(companies: Company[]): boolean {
    let validationStatus: boolean = true;
    let updatedData = this.data.map((row: any) => {
      if (companies.findIndex((company) => company.description.toUpperCase() === row.company.toUpperCase()) === -1) {
        let index = this.getFormControls.controls.findIndex((ctrl: FormGroup) => ctrl.controls['company'].value === row.to);
        this.getFormControls.controls[index].get('company').setErrors({ 'required': true });
        validationStatus = false;
      }

      return row;
    });
    this.data = [...updatedData];
    return validationStatus;
  }

  private getHeaderRow(sheet: XLSX.WorkSheet) {
    let headers: string[] = [];
    let range: XLSX.Range = XLSX.utils.decode_range(sheet['!ref']);
    let C, R = range.s.r;
    /* start in the first row */
    /* walk every column in the range */
    for (C = range.s.c; C <= range.e.c; ++C) {
      let cell = sheet[XLSX.utils.encode_cell({ c: C, r: R })]

      /* find the cell in the first row */
      let hdr = "UNKNOWN " + C; // <-- replace with your desired default 
      if (cell && cell.t)
        hdr = XLSX.utils.format_cell(cell);

      headers.push(hdr);
    }
    return headers;
  }

  // ** Remove from flow
  private validateTotalCalc(data: xlsxData): boolean {
    return data.every((item: any) => Number(item.total) === (Number(item.cargo) + Number(item.body)));
  }

  // ** Reduce function and remove validation for locations and companies
  private validateMasterData(): void {
    this.passengersService.locationsService.get().subscribe(
      (locations: Location[]) => {
        if (this.validatePassengerLocations(locations)) {
          this.passengersService.companiesService.get().subscribe((companies: Company[]) => {
            if (this.validatePassengerCompany(companies)) {
              // save passengers
              //this.setPassengers();
              this.resService.createReservations(
                this.filterDuplicateImports(this.mapPassengers(this.data)),
                this.getRunSelection(),
                new Date(),
                locations,
                companies)
                .subscribe(
                  reservations => {
                    this.passengersService.setReservedPassengers(
                      this.passengersService.mapPassengers(this.filterFlownReservations(reservations))
                    )

                    this.progress.spin$.next(false);
                    this.submitClick.emit();
                  },
                  _error => {
                    this.progress.spin$.next(false);
                    this.logger.notify('An error occurred creating the reservation', 'ok');
                  }
                );
            } else {
              this.progress.spin$.next(false);
              this.logger.notify('Invalid company found, please correct and retry', 'ok')
            }
          })

        } else {
          this.progress.spin$.next(false);
          this.logger.notify('Invalid locations found, please correct and retry', 'ok')
        }
      }
    );
  }

  private getRunSelection(): Runs {
    let currentRun = this.passengersService.runSelService.getFlightRunSelection()?.flight;
    if (!currentRun) {
      currentRun = this.runs.filter(r => r.runName === 'Special Run')[0];
    }

    return currentRun;
  }

  private filterFlownReservations(reservations: Reservation[]) {
    return reservations.filter((res) => res.flownIndicator === false)
  }

  private filterDuplicateImports(passengers: Passenger[]): Passenger[] {
    let importedPassenger: Passenger[] = [];

    passengers?.forEach((p) => {
      if (!this.passengersService?.reservedPassengers?.find(rp =>
        p?.fullName.toUpperCase() === (rp.firstName.toUpperCase() + " " + rp.lastName.toUpperCase()) &&
        p?.weight === rp.weight &&
        p?.cargo === rp.cargo &&
        p?.company.toUpperCase() === rp.company.toUpperCase() &&
        p?.to.toUpperCase() === rp.to.toUpperCase() &&
        p?.from.toUpperCase() === rp.from.toUpperCase())) {
        importedPassenger.push(p);
      }
    })

    return importedPassenger;
  }

  private initializeForm(): void {
    this.importForm = this.fb.group({
      importRows: this.fb.array([])
    });

    this.importForm.valueChanges.subscribe((result) => this.data = result.importRows);
  }

  private setImportForm(): void {
    let formControls = <FormArray>this.importForm.controls.importRows;

    this.data.forEach(passenger => {
      formControls.push(this.getPassengerFormRow(passenger));
    })

    this.importForm.controls.importRows.setValue(formControls);
    this.importForm.updateValueAndValidity();
  }

  private getPassengerFormRow(passenger: any): FormGroup {
    return this.fb.group({
      "passenger name": [passenger[this.headerData[2]], Validators.required],
      "from": [passenger[this.headerData[0]], {
        validators: [Validators.required],
        asyncValidators: [this.locationsValidator.validate.bind(this.locationsValidator)]
      }],
      "to": [passenger[this.headerData[1]], {
        validators: [Validators.required],
        asyncValidators: [this.locationsValidator.validate.bind(this.locationsValidator)]
      }],
      "body": [isNaN(Number(passenger[this.headerData[3]])) ? 0 : Number(passenger[this.headerData[3]]), Validators.required],
      "cargo": [isNaN(Number(passenger[this.headerData[4]])) ? 0 : Number(passenger[this.headerData[4]]), Validators.required],
      "total": [Number(passenger[this.headerData[5]]), Validators.required],
      "company": [passenger[this.headerData[6]], {
        validators: [Validators.required],
        asyncValidators: [this.companiesValidator.validate.bind(this.companiesValidator)]
      }],
      "charge code": [passenger[this.headerData[7]], Validators.required]
    }, { validators: passengerWeightValidator });
  }
}
