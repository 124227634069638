import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ManifestpassengerComponent } from './manifestpassenger.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule, MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MatDividerModule } from '@angular/material/divider';
import { MatDialogModule } from '@angular/material/dialog';
import { ModalModule } from 'ngx-bootstrap/modal';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoggingService } from 'src/app/logging/logging.service';
import { MatTableModule } from '@angular/material/table';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSortModule } from '@angular/material/sort';

const components = [
  ManifestpassengerComponent,
];
const providers = [
  {provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: {floatLabel: 'always'}}
];
@NgModule({
  declarations: [ManifestpassengerComponent],
  imports: [
    CommonModule,    
    MatTableModule,
    MatCheckboxModule,
    MatButtonToggleModule,
    MatDividerModule,
    MatExpansionModule,
    BrowserAnimationsModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatIconModule,
    MatCardModule,
    MatTabsModule,
    MatSortModule,
    MatSelectModule,
    MatDialogModule,
    FlexLayoutModule,
    FormsModule
  ],
  providers: [
    providers
  ],
  exports: [
    components
  ]
})
export class ManifestpassengerModule { }
