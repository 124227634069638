import { Helicopter } from "./helicopter";
import { HelicopterTypes } from "./helicoptertypes";
import { Leg } from "./leg";
import { Pax } from "./pax";

export class ManifestCalculation {    
    public constructor(init?: Partial<ManifestCalculation>) {
        this.passengers = [];
        this.leg = [];
        this.helicopterType = new HelicopterTypes();
        this.helicopter = new Helicopter();
        this.wayPoints = [];

        Object.assign(this, init);
    }

    passengers?: Pax[];
    leg?: Leg[];
    helicopterType?: HelicopterTypes;
    helicopter?: Helicopter;
    wayPoints?: string[];
    crewWeight: number;
    flightEngineer?: number; // Flight Engineer Weight
    tas?: number;
    burnPerHour?: number;
    lbsStop?: number;
    minsStop?: number;
    windSpeed: number;
    windDirection: number;
    remarks?:string;
    xponder?:number;
}