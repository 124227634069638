import { Component, EventEmitter, Output } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { FlightImportDialogComponent } from '../flight-import-dialog/flight-import-dialog.component';
import { FlightImportComponent } from '../flight-import/flight-import.component';

/**
 * @name FlightImportButtonComponent
 * @description provide a minimal button to open the FlightImportDialogComponent dialog
 */
@Component({
  selector: 'flight-import-button',
  templateUrl: './flight-import-button.component.html',
  styleUrls: ['./flight-import-button.component.css']
})
export class FlightImportButtonComponent{
  /**
   * @description callback for any needed operations after FlightImportComponent Submit clicked such as routing, etc
   */
  @Output() submitClick = new EventEmitter();

  constructor(
      public dialog: MatDialog
    ) {  }

  /**
   * @description display FlightImportDialogComponent dialog
   * @returns void
   * @emits submitClick for event handling
   */
  public showDialog(): void {
    let dialogConfig: MatDialogConfig = {
      height: '875px',
      width: '1500px',
      disableClose: false,
      autoFocus: true,
      hasBackdrop: true
    };

    const dialogRef = this.dialog.open(FlightImportDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.submitClick.emit();
      }
    });
  }

}
