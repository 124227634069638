import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LoggingService } from 'src/app/logging/logging.service';
import { environment } from 'src/environments/environment';
import { IJobRemarks, JobRemarksSelection } from '../../models/manifest';


@Injectable({
  providedIn: 'root'
})
export class JobremarksService {
  private api = `${environment.api}Reservations/`;
  
  
  constructor(private http: HttpClient, private logger: LoggingService) { }

  getJobremarks(filterModel?: JobRemarksSelection): Observable<IJobRemarks[]> {
    if (filterModel) {
      let manifestParams ={
        "datewise":filterModel.dateWise.toString(),
        "shoreBaseId": filterModel.shoreBaseId.toString(),
        "jobNumber": filterModel.jobNumber.toString(),
        "tailNumber": filterModel.tailNumber.toString(),
        "helicopterTypeId": filterModel.helicopterTypeId.toString(),
        "date": filterModel.fromManifestDate.toISOString(), 
      }
      return this.http.get<IJobRemarks[]>(`${this.api}search`, { headers: environment.headers, params:  manifestParams})
      .pipe(
        catchError(_error => {
          return this.logger.logError<IJobRemarks[]>('Error retrieving jobremarks')
        })
      );
    } else {
      return this.http.get<IJobRemarks[]>(this.api, { headers: environment.headers })
        .pipe(
          catchError(_error => {
            return this.logger.logError<IJobRemarks[]>('Error retrieving jobremarks')
          })
        );
    }
  }
  update( jobRemarks: IJobRemarks[]): Observable<IJobRemarks[]> {
    return this.http.put<IJobRemarks[]>(`${this.api}updatejobremarks`, jobRemarks, { headers: environment.headers })
      .pipe(
        catchError(_error => {
          return this.logger.logError<IJobRemarks[]>(`Error updating jobRemarks: ${jobRemarks}`)
        })
      );
  }

}
