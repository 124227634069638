import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HelicoptertypesComponent } from './helicoptertypes.component';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CvxActionModule } from 'src/app/shared/cvxActionButton/cvxActionButton.module';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { TableVirtualScrollModule } from 'src/app/shared/tableVirtualScroll/tableVirtualScroll.module';
import { MatTableModule } from '@angular/material/table';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { RouterModule } from '@angular/router';
import { DirectivesModule } from 'src/app/directives/directives.module';

const components = [
  HelicoptertypesComponent
];

const providers = [];



@NgModule({
  declarations: [
    components
  ],
  imports: [
    CommonModule,    
    MatTableModule,
    MatCheckboxModule,
    MatButtonToggleModule,
    MatDividerModule,
    MatExpansionModule,
    BrowserAnimationsModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatIconModule,
    MatCardModule,
    MatTabsModule,
    MatSelectModule,
    MatSortModule,
    MatDialogModule,
    MatButtonModule,
    FlexLayoutModule,
    FormsModule,
    CvxActionModule,
    ScrollingModule,
    TableVirtualScrollModule,
    RouterModule,
    DirectivesModule
  ],
  providers: [
    providers
  ],
  exports: [
    components
  ]
})


export class HelicoptertypesModule { }
