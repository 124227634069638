import { Component } from '@angular/core';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.css']
})
// This is a wrapper for MatSpinner to bypass issue when adding MatSpinner to entryComponents
export class SpinnerComponent {

  constructor() {// this is intentionally left blank as its just a wrapper component
  }

}
