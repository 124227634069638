import { ErrorHandler, Injectable } from '@angular/core';
import { MonitoringService } from '../logging/monitoring.service';

@Injectable()
export class ErrorHandlerService implements ErrorHandler  {

  constructor(private monitor: MonitoringService) {
  }


  handleError(error: any) {
      this.monitor.logException(error); // Manually log exception
  }

}