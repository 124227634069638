import { Location } from "./locations";
import { Pax } from "./pax";

export class Leg {
    public constructor(init?: Partial<Leg>) {
        Object.assign(this, init);
    }
    addedFuel?: number;
    burn: number;
    cargoBal?: number;
    cargoDrop: number;
    cargoPick: number;
    cargoWeight?: number;
    carryingFuel?: number;
    centerofGravity?: number;
    crewWeight?: number;
    flightEngineer?: number;
    destination: Location;
    distance?: number;
    ete: number;
    fuel: number;
    fuelMoment?: number;
    legNumber?: number;
    legPath?: string;
    legPax?: Pax[];
    origination: Location;
    paxBal?: number;
    paxCount?: number;
    paxPick?: number;
    paxDrop?: number
    rangeStart?: number;
    rangeEnd?: number;
    row1Weight?: number;
    row2Weight?: number;
    row3Weight?: number;
    row4Weight?: number;
    row5Weight?: number;
    row6Weight?: number;
    row7Weight?: number;
    rowsFactors?: number;
    tow?: number;
    maximumTow?: number;
}