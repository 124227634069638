import { Component, ViewChild} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FlightImportComponent } from '../flight-import/flight-import.component';

/**
 * @name FlightImportDialogComponent
 * @description Dialog Wrapper for FlightImportComponent
 */
@Component({
  selector: 'flight-import-dialog',
  templateUrl: './flight-import-dialog.component.html',
  styleUrls: ['./flight-import-dialog.component.css']
})
export class FlightImportDialogComponent {
  public isEditable: boolean;

  constructor(
    public dialogRef: MatDialogRef<FlightImportDialogComponent>
  ) { }

  /**
   * @description used to trigger import events on FlightImportComponent
   */
  @ViewChild(FlightImportComponent) flightImport: FlightImportComponent;

  /**
   * @description trigger @member flightImport::onExport()
   */
  public onTemplate(): void {
    this.flightImport.onExport();
  }

  /**
   * @description trigger @member flightImport::onSubmit()
   */
  public onSubmitClick(): void {
    this.flightImport.onSubmit();
  }

  /**
   * @description trigger @member flightImport::onReset()
   */
  public onReset(): void {
    this.flightImport.onReset();
  }

    /**
   * @description callback from @member flightImport::submitClick()
   */
     public onSubmit(): void {
      this.dialogRef.close(true);
    }

    /**
     * @description callback when edit mode is active
     */
    public onEditable(isEditable: boolean): void {
      this.isEditable = isEditable;
    }
}
