import { animate, sequence, state, style, transition, trigger } from "@angular/animations";

export const rowfadeOut = trigger('rowfadeOut', [
    state(
        'void',
        style({
            background: '#007bff',
            borderBottomColor: '#007bff',
            opacity: 0,
            transform: 'translateX(-550px)',
            'box-shadow': 'none',
        })
    ),
    transition('void => *', sequence([animate('.5s ease')]))
]);

export const rowEnter =
  trigger('rowEnter', [
    transition('void => *', [
      style({ 
          height: '*', 
          opacity: '0', 
          transform: 'translateX(-550px)', 'box-shadow': 'none' 
        }),
      sequence([
        animate(".35s ease", style({ height: '*', opacity: '.2', transform: 'translateX(0)', 'box-shadow': 'none' })),
        animate(".35s ease", style({ height: '*', opacity: 1, transform: 'translateX(0)' }))
      ])
    ])
  ]);