import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { IManifest, IManifestLeg, IManifestPassenger, IManifestSave } from 'src/app/dtms/models/manifest';
import { FlightInfoService } from 'src/app/dtms/flight-info/flight-info.service';
import { LoggingService } from 'src/app/logging/logging.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ManifestCalculationsService } from '../manifest-calculations/manifest-calculations.service';
import { ManifestCalculation } from '../models/manifest-calculation';
import { Leg } from '../models/leg';
import { HelicopterTypes } from '../models/helicoptertypes';
import { FlightSelectionService } from './flight-selection/flight-selection.service';
import { FlightSelection } from '../models/flight-selection'
import { Passenger } from '../models/passenger';
import { FuelneededComponent } from './fuelneeded/fuelneeded.component';
import { CvxProgressService } from 'src/app/shared/cvx-progress/cvx-progress.service';
import { FlightComponent } from './flight/flight.component';
import { CalAngularService } from '@cvx/cal-angular';
import { Shorebasejob } from '../models/shorebasejob';
import { environment } from 'src/environments/environment';
import { BalanceComponent } from './balance/balance.component';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ICalculationsInfo, IFlightDataSheetInfo, IManifestInfo, ImanifestLegInfo, IPassengerManifest, IWeightAndBalanceInfo } from '../models/manifestinfo';
import { ViewManifestModelComponent } from '../flight-manifest/view-manifest-model/view-manifest-model.component';
import { Location } from '../models/locations';
import { CalcDistanceModel } from '../models/calclocationdistance';
import { Remarks } from '../models/jobremarks';
import { Utils } from 'src/app/shared/Utils';
import { ActivatedRoute } from '@angular/router';
import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs';


const dialogWidth: string = '550px';

@Component({
  selector: 'flight-info',
  templateUrl: './flight-info.component.html',
  styleUrls: ['./flight-info.component.css'],
})
export class FlightInfoComponent implements OnInit, OnChanges {
  @Input() manifestInfo: IManifestInfo;

  @ViewChild('flight') flight: FlightComponent;
  @ViewChild('balance') balance: BalanceComponent;

  public manifestLegs: Leg[];
  public manifestLegInfo: ImanifestLegInfo[]
  public manifest: IManifest;
  public manifestData: IManifest;
  public manifestCreated: IManifestSave;
  public legData: Leg[];
  public manifestBalance: Leg[];
  public heliTypeDataSource: MatTableDataSource<HelicopterTypes>;
  public displayedColumns: string[] = ['helicopterTypeName', 'passengerCapacity', 'maximumTow', 'maxToFuel', 'tas', 'burnPerHour', 'lbsStop', 'minStop'];
  public manifestCalc: ManifestCalculation = new ManifestCalculation();
  public flightSelection: FlightSelection;
  public helicopterType: HelicopterTypes;
  public confirmedPax: Passenger[];
  public previousconfirmedPax: Passenger[];
  public validateFlightInfo: boolean = false;
  public isFuelWorkFlowComplete: boolean = false;
  public isManifestDisabled: boolean = true;
  public masterLocations: Location[];
  public locations: string[] = [];
  public invalidWaypoints: string[] = [];
  public confPassChgs: boolean;
  public manifestEnrouteFuel: string;
  public locDistance: CalcDistanceModel[];
  public manifestRemarks: Remarks;
  private manisfestId:number;
  public isManifestSaveDisabled:boolean = false;
  public  isCalculating: boolean = false;

  constructor(
    private flightInfoService: FlightInfoService,
    private calculationService: ManifestCalculationsService,
    private flightSelectionService: FlightSelectionService,
    private authService: CalAngularService,
    public dialog: MatDialog,
    private logger: LoggingService,
    private progress: CvxProgressService,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit() {
    try {
      this.initialize();
    } catch (error) {
      this.logger.log(`Error Loading locations: ${error}`);
    }
  }

  private initialize(): void {
    this.legData = [];
    this.manifestLegs = [];
    this.manifestBalance = [];
    this.manifestCalc = new ManifestCalculation();
    this.flightSelection = new FlightSelection();
    this.helicopterType = new HelicopterTypes();
    this.heliTypeDataSource = new MatTableDataSource<HelicopterTypes>();

    // subscribe to flight changes and update view / model appropriately
    this.flightSelectionService.onFlightSelection().subscribe({
      next: (selection) => this.updateFlightSelections(selection)
    });

    this.flightInfoService.passengersService.onPassengersConfirmed().subscribe({
      next: (confirmedPax) => {
        this.updateConfirmedPax(confirmedPax);
      }
    });

    this.flightInfoService.
      locationService.get().subscribe((locations) => {
        this.locations = locations.map(l => l.locationName)
        this.masterLocations = locations;
      }
      );

  }
  public ngOnChanges(_changes: SimpleChanges): void {
    this.activatedRoute.paramMap.subscribe(params =>{
      let id = params.get('id');
      this.manisfestId = (id != undefined)? Number(id):0;
    });
    if (this.manifestInfo) {
      this.updateFlightSelections(this.manifestInfo.flightSelection);
      let pilots = this.manifestInfo.flightDataSheetInfo.crew.split("/");
      //Flight Information
      this.manifestLegInfo = this.manifestInfo.manifestLegs;
      if(this.manifestInfo.manifestId >0){
        this.isManifestDisabled = false;
      }
      this.manifestData = {
        manifestId: this.manifestInfo.manifestId,
        manifestGUID: this.manifestInfo.manifestGUID,
        burnPerHour: this.manifestInfo.weightBalanceInfo.burn,
        cgRangeHigh: this.manifestInfo.weightBalanceInfo.highRange,
        cgRangeLow: this.manifestInfo.weightBalanceInfo.lowRange,
        centerOfGravity: this.manifestInfo.weightBalanceInfo.CG,
        lbsStop: 2,
        windDirection: this.manifestInfo.calculationInfo.windDirection,
        windSpeed: this.manifestInfo.calculationInfo.windSpeed,
        xPonder: 4,
        flightNumber: this.manifestInfo.flightDataSheetInfo.flightNumber,
        // Both takeoff && return time made null as requested by business
        takeoffTime: null,
        returnTime: null,
        pilot1: null,
        pilot2: null,
        pilot1Id: this.manifestInfo.flightDataSheetInfo.pilot1Id.toString(),
        pilot2Id: this.manifestInfo.flightDataSheetInfo.pilot2Id.toString(),
        charge: this.manifestInfo.calculationInfo.charges,
        basicWeight: this.manifestInfo.calculationInfo.basicWeight,
        articaftMoment: this.manifestInfo.flightSelection.tailNumber.airCraftMoment,
        crewWeight: this.manifestInfo.calculationInfo.pilotCoPilotWeight,
        stateTime: this.manifestInfo.flightDataSheetInfo["startTime"],
        flightTime: this.manifestInfo.flightDataSheetInfo.flightTime,
        part91: true,
        part135: false,
        leg: this.manifestInfo.legs,
        manifestLegs: this.manifestInfo.manifestLegs,
        location: "GAO",//this.manifestInfo.weightBalanceInfo.origin.toString(),
        PAX: 34,
        fuel: this.manifestInfo.weightBalanceInfo.fuel,
        fuelMoment: 4,
        cg: this.manifestInfo.weightBalanceInfo.CG,
        range: this.manifestInfo.weightBalanceInfo.highRange,
        tow: this.manifestInfo.weightBalanceInfo.TOW,
        maxtow: this.manifestInfo.flightSelection.helicopterType.maximumTow,
        crew: 4,
        cargo: 3,
        ifr: this.manifestInfo.flightDataSheetInfo.IFR,

      };
      let passengers = this.flightInfoService.passengersService.mapManifestPassengers(this.manifestInfo.passengerManifestInfo);
      this.updateConfirmedPax(passengers);
      this.manifestLegs = this.manifestInfo.legs;
      this.manifestBalance = this.manifestInfo.legs;
      this.manifestRemarks = new Remarks({
        remarks: this.manifestInfo.remarks,
        xponder: this.manifestInfo.xponder,
        enrouteFuel: this.manifestInfo.calculationInfo.enrouteFuelRemarks
      });

      //Update ManifestCalculation Service and dependent data for partial saving
      this.calculationService.manifestCalculation = new ManifestCalculation();
      this.manifestCalc.leg = this.manifestInfo.legs;
      this.manifestCalc.helicopter = this.manifestInfo.flightSelection.tailNumber;
      this.manifestCalc.helicopterType = this.manifestInfo.flightSelection.helicopterType;
      this.manifestCalc.passengers = this.flightInfoService.passengersService.mapPax(passengers);
      this.manifestCalc.remarks = this.manifestInfo.remarks;
      this.manifestCalc.xponder = this.manifestInfo.xponder;
      this.manifestCalc.wayPoints = this.manifestInfo.waypoints;
      this.calculationService.manifestCalculation = this.manifestCalc;
      this.loadPassMiles(this.manifestInfo.legs)

    }
  }
  /**
   * @function validateWaypoints
   * @summary validate waypoints to flag bad data
   * @param waypoints the list of waypoints to validate
   */
  private validateWaypoints(waypoints: string[]) {
    this.invalidWaypoints = [];
    if (this.locations.length > 0) {
      waypoints.forEach(waypoint => {
        let isValid: boolean = this.validateWaypoint(waypoint, this.locations);
        if (!isValid) {
          this.invalidWaypoints.push(waypoint);
        }
      });
    }
  }
  /**
   * @function validateWaypoint
   * @summary validate waypoint to make sure it has existing locations 
   * @param waypoint the waypoint to validate
   * @param locations list of valid locations
   * @returns true if valid
   */
  private validateWaypoint(waypoint: string, locations: string[]): boolean {
    let retVal: boolean = false;

    retVal = (locations ? locations.filter((location: string) => location.toLowerCase() === waypoint.toLowerCase()) : null).length > 0;

    return retVal;
  }
  /**
   * @function onUpdateManifest 
   * @summary Callback for flight component manifestUpdated
   * @param manifestData Updated manifest data
   * @returns void
   */
  public onUpdateManifest(data: any): void {
    // update this logic later when working on manifest 
    this.manifestCalc.crewWeight = data.manifest.crewWeight;
    this.manifestCalc.windSpeed = data.manifest.windSpeed;
    this.manifestCalc.windDirection = data.manifest.windDirection;
    this.manifestCalc.tas = this.flightSelection.helicopterType.tas;
    this.manifestCalc.burnPerHour = this.flightSelection.helicopterType.burnPerHour;
    this.validateFlightInfo = data.valid;
    this.manifest = data.manifest;
  }

  /**
 * @function onRemarksUpdated 
 * @summary Callback for remarks component when remarks updated
 * @param Remarksdata Updated remarks data
 * @returns void
 */
  public onRemarksUpdated(data: any): void {
    // update this logic later when working on manifest 
    this.manifestCalc.remarks = data.remarks;
    this.manifestCalc.xponder = data.xponder;
  }


  public updatefuelNeeded(): void {
    let dialogConfig: MatDialogConfig = {
      width: dialogWidth,
      disableClose: false,
      autoFocus: true,
      hasBackdrop: true,
      data: this.manifestLegs
    };

    const dialogRef = this.dialog.open(FuelneededComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // Updating carrying of manifest legs
        this.manifestLegs = result;
        this.manifestCalc.leg = result
        this.isFuelWorkFlowComplete = this.flightInfoService.isFuelWorkflowCompleted;
        this.logger.notify('Manifest Fuel Updated', 'Ok');
        this.manifestRemarks = new Remarks({
          remarks: this.manifestCalc.remarks,
          xponder: this.manifestCalc.xponder,
          enrouteFuel: result.map(
            (leg: Leg) => {
              if (leg.legNumber > 1 && leg.addedFuel > 0) {
                return `${leg.origination.locationName}(${leg.addedFuel});`
              }
              return "";
            }).join("")
        });
        this.calculateBalance();
      }
    })

  }


  public onManifest(): void {

    let manifestSaveData = this.createManifest(this.confirmedPax)
    let manifestData = this.getManifestData(manifestSaveData);
    manifestData.manifestId = manifestSaveData.manifestId;
    manifestData.manifestGUID = manifestSaveData.manifestGUID;
    manifestData.manifestLegs = this.manifestLegInfo;
    let dialogConfig: MatDialogConfig = {
      width: '100%',
      height: '95%',
      disableClose: false,
      autoFocus: true,
      hasBackdrop: true,
      data: manifestData
    };
    const dialogRef = this.dialog.open(ViewManifestModelComponent, dialogConfig);

  }

  public onRouteUpdated(route: string[]) {
    this.manifestCalc.wayPoints = route;
  }

  /**
   * 
   * @function updateFlightSelections 
   * @summary callback on flight selections changed 
   * update @member helicopterType and @member manifestCalc.helicopter with selected flight types
   * @param selection flight selection
   */
  private updateFlightSelections(selection: FlightSelection): void {
    // This logic can be cleaned up a bit depending on how we load the rest of the chosen flight info -> flight & helicopter (tail number)
    if (selection && selection?.helicopterType && selection.tailNumber) {
      this.flightSelection = selection;
      this.flightInfoService.shoreBaseJobService.loadShoreBaseJob();
      this.heliTypeDataSource = new MatTableDataSource<HelicopterTypes>([this.flightSelection?.helicopterType]);
      this.helicopterType = this.flightSelection?.helicopterType;

      this.manifestCalc.helicopterType = this.flightSelection?.helicopterType;
      this.manifestCalc.helicopter = this.flightSelection?.tailNumber;
    }
  }

  /**
   * 
   * @function updateConfirmedPax 
   * @summary callback on pax confirmed / changed
   * @param passengers confirmed passengers to be manifested
   */
  private updateConfirmedPax(passengers: Passenger[]): void {
    if (passengers && passengers.length > 0) {
      try {
        this.confPassChgs = Utils.trackPassChanges(this.previousconfirmedPax, passengers);
        this.confirmedPax = [...passengers];
        this.manifestCalc.passengers = this.flightInfoService.passengersService.mapPax(passengers);
        this.setWaypoints();

        if (this.flightSelection.flight) {
          this.logger.notify(`${passengers.length} confirmed PAX for flight ${this.flightSelection.flight.runName}`, 'ok');
        }
        this.previousconfirmedPax = [...this.confirmedPax];
      } catch (error) {
        this.logger.logError('Error updating confirmed PAX');
      }
    }
  }

  /**
   * 
   * @function setWaypoints
   * @summary pax locations to waypoints array
   */
  private setWaypoints(): void {
    let destinations = [...new Set(this.manifestCalc.passengers.map(passenger => passenger.destination))];
    let originations = [...new Set(this.manifestCalc.passengers.map(passenger => passenger.origination))];

    destinations = destinations.filter(m => m != environment.destination);
    originations = originations.filter(m => m != environment.origin);
    let waypoints = destinations.concat(originations);
    this.validateWaypoints(waypoints);
    // Get distinct list 
    this.manifestCalc.wayPoints = [...new Set(waypoints.map(waypoint => waypoint))];
    this.manifestCalc.wayPoints = [environment.origin].concat(this.manifestCalc.wayPoints);
    this.manifestCalc.wayPoints = this.manifestCalc.wayPoints.concat(environment.destination);
  }

  /**
   * 
   * @function calculateBalance 
   * @summary Calculate weight balance for manifest
   * @summary set @member manifestBalance
   */
  private calculateBalance(): void {
    this.isCalculating = true;

    this.calculationService.postBalance(this.manifestCalc).subscribe(
      balance => {
        this.manifestBalance = balance;
        this.manifestCalc.leg = balance;
        this.calculationService.manifestCalculation = this.manifestCalc;
        this.flight.updateFlightTime(this.calculationService.calculateFlightTime(this.manifestCalc.leg));
        this.isManifestDisabled = false;
        this.isCalculating = false;
      },
      error => {
        this.isCalculating = false;
        this.logger.log(`Errors occurred computing manifest: ${error}`);
      }
    );
  }

  private createManifest(confirmedPassengers: Passenger[]): IManifestSave {
    let manifest: IManifestSave = null;
    let shorebaseJob = this.getShoreBaseJob();
    let legsInfo = this.mapLegsToManifestLegs(this.manifestCalc.leg);
    let passengers = this.mapPassengersToManifest(confirmedPassengers, legsInfo);
    try {
      manifest = {
        manifestGUID: this.getManifestGUID(),
        manifestId: this.getManifestID(),
        shorebaseId: this.flightSelection.flight.shoreBaseID,
        shorebaseJobId: (shorebaseJob != undefined) ? shorebaseJob.shoreBaseJobID : null,
        helicopterId: this.flightSelection?.tailNumber?.helicopterID,
        runId: this.flightSelection?.flight?.runID,
        flightNumber: this.manifest?.flightNumber,
        pilot1Id: typeof (this.manifest.pilot1) != 'string' ? this.manifest.pilot1?.pilotID : parseInt(this.manifest.pilot1),
        pilot2Id: typeof (this.manifest.pilot2) != 'string' ? this.manifest.pilot2?.pilotID : parseInt(this.manifest.pilot2),
        flightEngineerId: 0,
        createdDate: (this.manifestInfo) ? new Date(this.manifestInfo.flightDataSheetInfo.date) : new Date(),
        flightPath: this.manifestCalc.wayPoints.map((value, index, array) => index != array.length - 1 ? value + " >" : value).join(" "),
        centerOfGravity: 0,
        cgRangeLow: this.manifest.cgRangeLow,
        cgRangeHigh: this.manifest.cgRangeHigh,
        stateTime: this.manifest?.stateTime,
        stoptostopFuel: this.getStoptoStopFuel(),
        flightTime: this.manifest?.flightTime,
        charge: this.manifest?.charge,
        landings: this.manifestCalc.leg.length,
        crewWeight: this.getCrewWeight(),
        basicWeight: this.manifest?.basicWeight,
        takeoffTime: this.manifest?.takeoffTime?.toString(),
        returnTime: this.manifest?.returnTime?.toString(),
        part135: this.manifest?.part135,
        part91: this.manifest?.part91,
        tas: this.flightSelection.helicopterType.tas,
        xPonder: this.manifestCalc?.xponder,
        burnPerHour: this.flightSelection.helicopterType.burnPerHour,
        lbsStop: this.flightSelection.helicopterType.lbsStop,
        minsStop: this.flightSelection.helicopterType.minStop,
        windDirection: this.manifest?.windDirection,
        windSpeed: this.manifest?.windSpeed,
        remarks: this.manifestCalc?.remarks,
        lastUpdatedBy: this.authService.cvxClaimsPrincipal.cai,
        lastUpdatedDate: new Date(),
        manifestLegs: legsInfo,
        manifestPassengers: passengers,
        isIfr: this.manifest?.ifr != null ? this.manifest.ifr : false

      }
    } catch (err) {
      console.log("error in createManifest=", manifest);
    }
    return manifest;
  }
  private getCrewWeight() {
    if (this.manifest) {
      return this.manifest?.crewWeight
    } else {
      return 0;
    }
  }
  private getStoptoStopFuel() {
    try {
      if (this.manifestCalc?.leg.length > 0) {
        return this.manifestCalc?.leg[0]?.carryingFuel?.toString();
      } else {
        return "0";
      }
    } catch (err) {
      console.log("error=", err);
      return "0";
    }
  }
  private getManifestData(manifestSaveData: IManifestSave): IManifestInfo {

    let flightDataSheetInfo: IFlightDataSheetInfo = {
      crew: this.manifest.pilot1["fullName"] + '/' + this.manifest.pilot2["fullName"],
      baseJob: manifestSaveData.shorebaseJobId,
      date: (new Date()).toLocaleDateString(), //Should it be manifest created date
      flightType: (this.manifestInfo) ? this.manifestInfo.flightDataSheetInfo.flightType.toString() : "",
      flightNumber: manifestSaveData.flightNumber,
      landings: manifestSaveData.landings,
      IFR: manifestSaveData?.isIfr,

      flightTime: manifestSaveData.flightTime,
      stateTime: manifestSaveData.stateTime,
      entries: this.manifestCalc.leg.entries.length,
      pilot1Id: manifestSaveData.pilot1Id,
      pilot2Id: manifestSaveData.pilot2Id
    };
    let weightBalanceInfo: IWeightAndBalanceInfo = {
      origin: null,
      destination: this.manifest?.location,
      distance: null,
      ETE: null,
      TOW: this.manifest?.tow,
      fuel: this.manifest?.fuel,
      highRange: manifestSaveData.cgRangeHigh,
      lowRange: manifestSaveData.cgRangeLow,
      CG: manifestSaveData.centerOfGravity,
      pax: this.manifest?.PAX,
      burn: manifestSaveData.burnPerHour
    }
    let calculationInfo: ICalculationsInfo = {
      cautionOrRemarks: (this.manifestInfo) ? this.manifestInfo.calculationInfo.cautionOrRemarks : "",
      enrouteFuelRemarks: (this.manifestInfo) ? this.manifestInfo.calculationInfo.enrouteFuelRemarks : "",
      paxMile: null,
      hourly: null,
      charges: manifestSaveData?.charge,
      part135: manifestSaveData?.part135,
      part91: manifestSaveData?.part91,
      takeOff: manifestSaveData?.takeoffTime?.toString(),
      return: manifestSaveData?.returnTime?.toString(),
      windDirection: manifestSaveData.windDirection,
      windSpeed: manifestSaveData.windSpeed,
      code: null,
      pMile: null,
      PAX: null,
      PEC: null,

      basicWeight: this.manifestInfo?.calculationInfo?.basicWeight,
      moment: this.manifestInfo?.calculationInfo?.moment,
      pilotCoPilotWeight: manifestSaveData?.crewWeight,
      takeOffFuel: this.manifestInfo?.calculationInfo.takeOffFuel,
      takeOffWeight: this.manifestInfo?.calculationInfo.takeOffWeight,
      maxtakeOffWeight: this.manifestInfo?.calculationInfo?.maxtakeOffWeight
    }

    let manifest: IManifestInfo = {
      manifestId: manifestSaveData?.manifestId,
      manifestGUID: manifestSaveData?.manifestGUID,
      flightDataSheetInfo: flightDataSheetInfo,
      weightBalanceInfo: weightBalanceInfo,
      calculationInfo: calculationInfo,
      passengerManifestInfo: this.mapPassengerData(manifestSaveData),
      manifestLegs: this.mapLegsData(manifestSaveData),
      legs: this.manifest.leg,
      flightSelection: undefined,
      waypoints: manifestSaveData.flightPath.trim().replace(" ", "").split('>'),
      remarks: manifestSaveData.remarks,
      xponder: manifestSaveData.xPonder
    }
    this.manifestLegInfo = this.mapLegsData(manifestSaveData);
    return manifest;
  }

  private mapPassengerData(manifestSaveData: IManifestSave): IPassengerManifest[] {
    let passengersList: IPassengerManifest[] = [];
    let passengers = manifestSaveData.manifestPassengers;
    if (passengers != null) {
      passengers.forEach(p => {

        try {
          passengersList.push({
            reservationID: p.reservationID,
            lastName: p.lastName,
            firstName: p.firstName,
            fullName: p.firstName + " " + p.lastName,
            companyId: p.companyID,
            from: this.masterLocations.filter(loc => loc.locationID === p.fromLocationID)[0].locationName,
            to: this.masterLocations.filter(loc => loc.locationID === p.toLocationID)[0].locationName,
            miles: this.calcPassengerMiles(manifestSaveData.manifestLegs, p.fromLocationID, p.toLocationID),
            passengerName: p.firstName + ' ' + p.lastName,
            code135: parseInt(p.part135Code),
            bodyLbs: p.passengerWeight,
            cargoLbs: p.cargo,
            totalLbs: p.cargo + p.passengerWeight,
            company: p.company,
            chargeCode: p.chargeCode,
            pickLater:p.pickLater,
            manifestPassengerId: p?.manifestPassengerID

          });




        }
        catch (error) {
          console.log(error);
        }

      });
    }
    return passengersList;
  }

  private mapLegsData(manifestSaveData: IManifestSave): ImanifestLegInfo[] {
    let legList: ImanifestLegInfo[] = [];
    if (manifestSaveData.manifestLegs != null) {
      manifestSaveData.manifestLegs.forEach(l => {
        legList.push({
          origin: this.masterLocations.filter(loc => loc.locationID === l.fromLocationID)[0].locationName,
          destination: this.masterLocations.filter(loc => loc.locationID === l.toLocationID)[0].locationName,
          distance: l.stopToStopDist,
          ETE: l.stopToStopEte,
          TOW: l.stopToStopTow,
          fuel: l.stopToStopFuel,
          range: l.weightBalanceRange,
          CG: l.weightBalanceCG,
          pax: l.weightBalancePax,
          burn: l.stopToStopBurn,
          manifestLegId: l?.manifestLegID,
          legNumber: l.legNumber

        });
      });
    }
    return legList;
  }

  private mapLegsToManifestLegs(legs: Leg[]): IManifestLeg[] {
    let manifestLegs: IManifestLeg[] = [];
    legs.forEach(l => {
      try {
        manifestLegs.push({
          fromLocation: l.origination,
          fromLocationID: l.origination?.locationID,
          lastUpdatedBy: this.authService.cvxClaimsPrincipal.cai,
          lastUpdatedDate: new Date(),
          legNumber: l.legNumber,
          manifestGUID: this.getManifestGUID(),
          manifestLegID: this.getManifestLegID(l.legNumber),
          stopToStopBurn: l.burn,
          stopToStopCarWgtDrop: l.cargoDrop,
          stopToStopCarWgtPickup: l.cargoPick,
          stopToStopDist: l.distance,
          stopToStopEte: l.ete,
          stopToStopFuel: l.fuel,
          stopToStopPaxWgtDrop: l.paxDrop,
          stopToStopPaxWgtPickup: l.paxPick,
          stopToStopTow: l.tow,
          toLocationID: l.destination.locationID,
          toLocation: l.destination,
          weightBalanceCG: l.centerofGravity,
          weightBalanceCargoWeight: l.cargoWeight,
          weightBalanceCrewWeight: l.crewWeight,
          weightBalanceFuel: l.carryingFuel,
          weightBalanceFuelMoment: l.fuelMoment,
          weightBalanceMaxTow: l.maximumTow,
          weightBalancePax: l.paxCount,
          weightBalanceRange: l.rangeStart.toString() + "-" + l.rangeEnd.toString(),
          weightBalanceRow1Weight: l.row1Weight,
          weightBalanceRow2Weight: l.row2Weight,
          weightBalanceRow3Weight: l.row3Weight,
          weightBalanceRow4Weight: l.row4Weight,
          weightBalanceRow5Weight: l.row5Weight,
          weightBalanceRow6Weight: l.row6Weight,
          weightBalanceRow7Weight: l.row7Weight,
          weightBalanceTow: l.tow
        });
      } catch (err) {
        console.log("err=", err);
      }
    })
    return manifestLegs
  }

  private mapPassengersToManifest(cnfPassengers: Passenger[], _legs: IManifestLeg[]): IManifestPassenger[] {
    let manifestPassengers: IManifestPassenger[] = [];
    cnfPassengers.forEach((p, index) => {
      manifestPassengers.push({
        cargo: p.cargo,
        chargeCode: p.chargeCode,
        companyID: p.companyID,
        distance: this.calcPassengerMiles(_legs, this.masterLocations.filter(l => l.locationName === p.from)[0]?.locationID, this.masterLocations.filter(l => l.locationName === p.to)[0]?.locationID),
        company: p.company,
        firstName: p.firstName,
        fromLocationID: this.masterLocations.filter(l => l.locationName === p.from)[0]?.locationID,
        helicopterRow: p.helicopterRow,
        lastName: p.lastName,
        lastUpdatedBy: this.authService.cvxClaimsPrincipal.cai,
        lastUpdatedDate: new Date(),
        manifestGUID: this.getManifestGUID(),
        manifestPassengerID: this.getManifestPassengerID(p.reservationID),
        part135Code: p.part135Code.toString(),
        passengerWeight: p.weight,
        paxIndex: index,
        pickLater: p.pickLater,
        reservationID: p.reservationID,
        toLocationID: this.masterLocations.filter(l => l.locationName === p.to)[0]?.locationID,
        verifyChargeCode: p.verifyChargeCode
      })
    }
    );
    return manifestPassengers;
  }

  private getShoreBaseJob(): Shorebasejob {
    let shoreBaseJob: Shorebasejob;

    let jobNumber: number = parseInt(this.flightSelection.job.split("#")[1]);
    //Assuming helicopter are already loaded in flight selection component
    let helicopterTypeID: number = this.getHelicopterTypeIDFromJob(this.flightSelection.job)
    this.flightInfoService.shoreBaseJobService.get().subscribe(
      (result) => {
        // Implementation to get Shorebasejob from a specific JobNumber if exists 

        shoreBaseJob = this.flightSelection.job.toString().includes("#") ? result.find(item => item.shoreBaseID === this.flightSelection.flight.shoreBaseID &&
          item.helicopterTypeID === helicopterTypeID &&
          item.jobNumber === jobNumber) : result.find(item => item.shoreBaseID === this.flightSelection.flight.shoreBaseID &&
            item.helicopterTypeID === helicopterTypeID);

      }
    )
    return shoreBaseJob;
  }

  private createorsaveManifest(confirmedPassengers: Passenger[]): void {
    try {
      this.flightInfoService.manifestService.add(this.createManifest(confirmedPassengers)).subscribe((result: any) => {
        this.progress.spin$.next(false);
        if (result && result.manifestGUID != "") {
          //TechDebt: the legacy service has response manifestID property hence adding manifestId
          result = { ...result, manifestId: result.manifestID };
          this.logger.notify('Manifest saved succesfully', 'ok');
          this.manifestCreated = (!this.manifestInfo) ? result : null;
          this.manifestInfo = (!this.manifestCreated) ? this.getManifestData(result) : this.manifestInfo;


        } else {
          this.logger.notify('Unable to save manifest. Try again', 'ok')
        }
      });
    }
    catch (error) {
      this.logger.log(`Errors occurred during manifest save:  ${error}`);
    }
  }

  /**
 * @function onCompute
 * @summary Compute manifest onCompute click
 */
  public onCompute(): void {
    try {
      this.progress.spin$.next(true);

      if (this.validateManifest()) {

        this.calculateStops().subscribe({
          next: (result) => {
            this.progress.spin$.next(false);
          },
          error: (error) => {
            this.logger.logError(error, `Errors occurred computing manifest: ${error}`);
            this.progress.spin$.next(false);
          }
        });

        this.confPassChgs = false;
      } 
      else {
        this.progress.spin$.next(false);
      }        
    } catch (error) {
      this.progress.spin$.next(false);
      this.logger.log(`Errors occurred computing manifest:  ${error}`);
    }
  }

  /**
* @function onSave
* @summary Save manifest on save button click
*/
  public onSave(): void {
    try {
      if (!this.confPassChgs) {
        if (this.validateManifest() && this.balance.manifestWBMaxLimitCheck(this.flightSelection)) {
            // NOTE: validate pax capacity here?
            // This is to allow manifest save even manifest has balance issues
            this.balance.manifestFuelCalcCheck();
            this.progress.spin$.next(true);
            this.createorsaveManifest(this.confirmedPax);
        }
      } else {
        this.logger.notify('Confirmed Passenger data changed. Compute Manifest to save', 'ok')
      }
    } catch (error) {
      this.progress.spin$.next(false);
      this.logger.logError(`Errors occurred creating the manifest: ${error}`);
    }
  }

  private validateManifest(): boolean {
    if (this.manifestCalc.wayPoints.length < 3) {
      this.logger.notify('Invalid Route', 'ok');
      return false;
    }

    if (this.validateFlightInfo) {
      if (this.confirmedPax.length > 0) {
        return true;
      } else {
        this.logger.notify('Please select passengers', 'ok');
        return false;
      }
    } else {
      this.logger.notify('Please add missing flight data', 'ok');
      return false
    }
  }

  /**
   * @function calculateStops 
   * @summary Calculate stop to stop for manifest
   * @summary set @member manifestLegs
   */
  private calculateStops(): Observable<Leg[]> {
    return this.calculationService.postStops(this.manifestCalc).pipe(tap(
      legs => {

        if (this.validatePassengerCapacity(legs)) {
          this.manifestLegs = legs;
          this.updatefuelNeeded();
          this.loadPassMiles(legs);
         
          if(this.manisfestId > 0){
            this.balance.manifestFuelCalcCheck();
            // disable save button - doesn't seem to work - will revisit - set isManifestSaveDisabled to !balance.manifestFuelCalcCheck()
          }
        }
      },
      error => {
        this.logger.log(`Errors occurred in calculate stops: ${error}`);
      }
    ));
  }

  /**
   * @function validatePassengerCapacity
   * @summary Validate passenger capacity of each leg of the manifest
   * @param legs: List of legs to validate for capacity
   * @returns true - the manifest capacity is ok  |  false - one or more legs of the flight exceed capacity
   */
  private validatePassengerCapacity(legs: Leg[]): boolean {
    let isValid: boolean[] = [];

    legs.forEach(leg => {
      // Check pax per leg, for each leg check pax <= max capacity
      let result = this.checkLegCapacity(leg, this.flightSelection.helicopterType.passengerCapacity);
      isValid.push(result);
    });

    return !isValid.includes(false);
  }

  /**
   * @function checkLegCapacity 
   * @summary check the passenger total for a leg against the helicopter capacity
   * @param leg the leg to verify
   * @param capacity the capcity of the aircraft
   * @returns true - the leg does not exceed capacity  |  false - the leg is over capacity
   */
  private checkLegCapacity(leg: Leg, capacity: number): boolean {
    let retVal: boolean = true;

    if (leg.paxCount > capacity) {      
      this.logger.notify(`Confirmed passengers exceeds aircraft passenger capacity: ${leg.legPath}`, 'ok');
      retVal =  false;
    } 

    return retVal;
  }

  /**
* @function onTabChange
* @summary Fuel check on manifest legs when weight/balance table is clicked click
*/
  public onTabChange(changeEvent: MatTabChangeEvent): void {
    if (changeEvent.tab.textLabel === 'weightbalance') {
      this.balance.manifestFuelCalcCheck();
    }
  }

  /**
* @function getManifestGUID
* @summary Get manifestGUID based on manifest edit or save mode
*/
  private getManifestGUID(): string {
    if (this.manifestCreated != null) {
      return this.manifestCreated.manifestGUID;
    } else {
      return this.manifestInfo != null ? this.manifestInfo.manifestGUID : "";
    }
  }

  /**
* @function getManifestID
* @summary Get manifestID based on manifest edit or save mode
*/
  private getManifestID(): number {
    if (this.manifestCreated != null) {
      return this.manifestCreated.manifestId;
    } else {
      return this.manifestInfo != null ? this.manifestInfo.manifestId : 0;
    }
  }

  /**
* @function getManifestLegID
* @summary Get manifestID based on manifest edit or save mode
*/
  private getManifestLegID(legnumber: number): number {
    if (this.manifestCreated != null) {
      return this.manifestCreated.manifestLegs.find(ml => ml.legNumber === legnumber)?.manifestLegID;
    } else {
      return this.manifestInfo && this.manifestInfo.manifestLegs.find(ml => ml.legNumber === legnumber) != null ? this.manifestInfo.manifestLegs.find(ml => ml.legNumber === legnumber).manifestLegId : 0;
    }
  }

  /**
* @function getManifestPassengerID
* @summary Get manifestPassengerID based on manifest edit or save mode
*/
  private getManifestPassengerID(reservationID: number): number {
    if (this.manifestCreated != null) {
      return this.manifestCreated.manifestPassengers.find(mp => mp.reservationID === reservationID)?.manifestPassengerID;
    } else {
      return this.manifestInfo != null ? this.manifestInfo.passengerManifestInfo.find(mp => mp.reservationID === reservationID)?.manifestPassengerId : 0;
    }
  }


  public loadPassMiles(legs: Leg[]): void {
    this.locDistance = [];
    let passLoc: string[][] = [];
    try {
      legs.forEach(
        l => l.legPax.forEach(p => {
          if (!legs.find(
            leg => (leg.origination.locationName === p.origination && leg.destination.locationName === p.destination)
              || (leg.origination.locationName === p.destination && leg.destination.locationName === p.origination)
          ) && passLoc?.indexOf([p.origination, p.destination]) < 0
          ) {
            passLoc.push([p.origination, p.destination]);
          }
        }
        )
      )
    }
    catch (error) {
      console.log(error);
    }

    passLoc.forEach(loc => {
      let fromLocation: Location = this.masterLocations.filter(l => l.locationName === loc[0])[0];
      let toLocation: Location = this.masterLocations.filter(l => l.locationName === loc[1])[0];

      this.calculationService.postDistance(
        new CalcDistanceModel({
          oLocationName: fromLocation.locationName,
          dLocationName: toLocation.locationName,
          oLocationId: fromLocation.locationID,
          dLocationId: toLocation.locationID,
          oLatitude: fromLocation.latitude,
          oLongitude: fromLocation.longitude,
          dLatitude: toLocation.latitude,
          dLongitude: toLocation.longitude
        })).subscribe(
          (c: CalcDistanceModel) => this.locDistance.push(c))
    });

  }

  public calcPassengerMiles(legs: IManifestLeg[], fromLocationID: number, toLocationID: number): number {
    let legDistanceArray = legs.filter(
      l => (l.fromLocationID === fromLocationID && l.toLocationID === toLocationID) ||
        (l.toLocationID === fromLocationID && l.fromLocationID === toLocationID));

    if (legDistanceArray.length > 0) { return legDistanceArray[0].stopToStopDist }
    else {
      return this.locDistance.filter(ld => ld.oLocationId === fromLocationID &&
        ld.dLocationId === toLocationID)[0].distance;
    }
  }

  private getHelicopterTypeIDFromJob(job: string): number {

    let jobFlightName: string = job.split("#")[0];
    return this.flightInfoService.helicopterTypesService.helicopterTypes.find(h => h.helicopterTypeName === jobFlightName).helicopterTypeID;

  }
}
