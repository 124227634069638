import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, OnInit, Output, SimpleChange } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Location } from '../../models/locations';
import { LoggingService } from 'src/app/logging/logging.service';
import { LocationsService } from '../../locations/locations.service';
import { FormControl } from '@angular/forms';
import { rowfadeOut } from 'src/app/shared/animations/animations';

@Component({
  selector: 'flight-route',
  templateUrl: './flight-route.component.html',
  styleUrls: ['./flight-route.component.css'],
  animations: [rowfadeOut]
})
export class FlightRouteComponent implements OnInit {
  @Input() route: string[];
  @Output() routeUpdated = new EventEmitter<string[]>();
  @Output() locationsUpdated = new EventEmitter<string[]>();
  
  public waypointModel: string;
  public locations$: Observable<Location[]>;
  public filteredLocations$: Observable<Location[]>;
  public locationsCtrl = new FormControl();
  public locations: Location[];
  public invalidWaypoints: string[] = [];
  private editMode: boolean;

  public readonly shorebase = 'GAO';

  constructor(
    private locationsService: LocationsService,
    private logger: LoggingService) { }

  /**
   * @function ngOnInit
   * @summary initialize @member route and @member waypointModel
   */
  public ngOnInit(): void {
    if (!this.route) {
      this.route = [];
    }

    this.waypointModel = "";
    this.editMode = false;

    this.setShorebase();
    this.load();

    this.locationsCtrl.valueChanges.subscribe(locationValue => {
      const filterValue = locationValue.toString().toUpperCase();
      this.filteredLocations$ = this.locations$.pipe(map(locations => locations.filter(location => location.locationName.toUpperCase().includes(filterValue))));
    });
  }


  public ngOnChanges(changes:SimpleChange): void{
    this.validateWaypoints(this.route);
  }
  /**
  * @function ngAfterViewInit
  * @summary Load dependencies
  */
  public ngAfterViewInit(): void {
   
    this.validateFlightRoute();
  }

  /**
   * @function isEditable
   * @summary check if the waypoint is editable
   * @param last is last waypoint in route
   * @returns true - edit mode active
   */
  public isEditable(last: boolean): boolean {
    return this.editMode && last;
  }

  /**
   * @function onAdd
   * @summary Handler to add new waypoint to route, set edit mode true for card and add blank item to route
   * @param $event 
   */
  public onAdd($event: any): void {
    this.editMode = true;
    this.route.push("Enter Destination");
    this.waypointModel = "";
  }

  /**
   * @function onSave 
   * @summary Save waypoint to route and turn off edit mode
   * @param $event 
   */
  public onSave($event: any): void {
    if (this.waypointModel) {
      this.editMode = false;
      this.route[this.route.length - 1] = this.waypointModel.toUpperCase();

      this.updateRoute();
    }
  }

  /**
   * @function onDrop
   * @summary handle card swap on drag drop
   * @param event dragdrop event 
   */
  public onDrop(event: CdkDragDrop<string[]>): void {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
    }

    this.updateRoute();
  }

  /**
   * @function onRemove
   * @summary Handler for removing waypoint from route
   * @param $event click event
   * @param i index of waypoint to remove from route
   */
  public onRemove($event: any, i: number): void {
    this.route.splice(i, 1);
    this.updateRoute();
  }

  /**
   * @function updateRoute
   * @summary emit updates to routeChanged
   */
  private updateRoute(): void {
    this.routeUpdated.emit(this.route);
    this.validateWaypoints(this.route);
  }

  /**
   * @function load
   * @summary load locations and handle any necessary data tranformations
   */
  private load(): void {
    this.locations$ = this.locationsService.get()
      .pipe(
        map(locations => this.setLocations(locations))
      )
      catchError(err => {
        this.logger.logError(err);
        return of([]);
      });
  }

  /**
   * @function setLocations
   * @summary Filter locations for distinct location names, and patch form values to
   *          set selected location
   * @param locations locations list to transform
   * @returns transformed location list
   */
  private setLocations(locations: Location[]): Location[] {
    let distinct = locations.filter(
      (location, i, array) => array.findIndex(l => l.locationName === location.locationName) === i
    );
      let locationNames = [...new Set( distinct.map(obj => obj.locationName)) ];
      this.locationsUpdated.emit(locationNames);
    return distinct;
  }

  /**
   * @function setShorebase
   * @summary if the route contains specified @property shorebase, set it as start and end to route
   */
  private setShorebase(): void {
    if (this.route.length > 0 && this.route.includes(this.shorebase)) {
      // check start
      if (this.route[0] != this.shorebase) {
        // insert shorebase as first waypoint
        this.route.unshift(this.shorebase);
      }

      if (this.route[this.route.length - 1] != this.shorebase) {
        // insert shorebase as final waypoint
        this.route.push(this.shorebase);
      }
    }
  }

  /**
   * @function validateFlightRoute
   * @summary load locations list and validate flight route to flag bad data
   */
  private validateFlightRoute(): void {
    this.locations$.subscribe({
      next: (locations) => {
        this.locations = locations;
        this.validateWaypoints(this.route);
      }
    });
  }

  /**
   * @function validateWaypoints
   * @summary validate waypoints to flag bad data
   * @param waypoints the list of waypoints to validate
   */
  private validateWaypoints(waypoints: string[]) {
    this.invalidWaypoints = [];
    if (this.locations) {
      waypoints.forEach(waypoint => {
        let isValid: boolean = this.validateWaypoint(waypoint, this.locations);
        if (!isValid) {
          this.invalidWaypoints.push(waypoint);
        }
      });
      
    }
  }

  /**
   * @function validateWaypoint
   * @summary validate waypoint to make sure it has existing locations 
   * @param waypoint the waypoint to validate
   * @param locations list of valid locations
   * @returns true if valid
   */
  private validateWaypoint(waypoint: string, locations: Location[]): boolean {
    let retVal: boolean = false;

    retVal = (locations ? locations.filter((location: Location) => location.locationName.toUpperCase() === waypoint.toUpperCase()) : null).length > 0;

    return retVal;
  }
}
