import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { JobRemarks } from '../models/jobremarks';
import { FlightSelection } from '../models/flight-selection';
import { FlightSelectionService } from '../flight-info/flight-selection/flight-selection.service';
import { Shorebasejob } from '../models/shorebasejob';
import { Shorebase } from '../models/shorebase';
import { CalAngularService } from '@cvx/cal-angular';

@Component({
  selector: 'app-addjobremarks',
  templateUrl: './addjobremarks.component.html',
  styleUrls: ['./addjobremarks.component.css'],
  exportAs: 'app-addjobremarks'
})
export class AddjobremarksComponent implements OnInit {
  public jobremarksForm: FormGroup;
  public currentSelectedFlight: FlightSelection;


  constructor(
    @Inject(MAT_DIALOG_DATA) public jobremarks: JobRemarks,
    public dialogRef: MatDialogRef<AddjobremarksComponent>,
    private formBuilder: FormBuilder,
    private flightselectionservice: FlightSelectionService,
    private authService: CalAngularService
  ) {
    this.flightselectionservice.onFlightSelection().subscribe({
      next: (flightselection) => this.currentSelectedFlight = flightselection
    });
  }

  public ngOnInit() {
    this.initialize();
  }


  private initialize(): void {
    if (!this.jobremarks) {
      this.jobremarksForm = this.createForm();
    } else {
      this.jobremarksForm = this.setForm();
    }
  }

  private createForm(): FormGroup {
    this.jobremarks = {
      jobRemarkID: 0,
      dayOfMonth: (new Date()),
      shorebase: new Shorebase(),
      shoreBaseJob: new Shorebasejob(),
      helicopter: this.currentSelectedFlight.tailNumber,
      helicopterTypes: this.currentSelectedFlight.helicopterType,
      job: "",
      tailNumber: this.currentSelectedFlight.tailNumber.tailNumber,
      ron: this.currentSelectedFlight.flight.runID,
      scheduledAOG: 0,
      unScheduledAOG: 0,
      remarks: "",
      lastUpdatedBy: this.getShortName(this.authService.getAccount().name),
      lastUpdatedDate: (new Date())
    };

    return this.setForm();
  }

  public getShortName(username: string) {
    let shortName = username.substr(0, 20);
    shortName = shortName.substring(0, shortName.lastIndexOf(' '))
    return shortName;
  }

  private setForm(): FormGroup {
    return this.formBuilder.group({
      remarks: [this.jobremarks.remarks, Validators.required]
    });
  }

  public onSubmit(): void {
    if (this.jobremarksForm.valid) {
      this.dialogRef.close(this.save());
    }
  }

  public onReset(fgd: NgForm) {
    fgd.onReset();
    this.initialize();
  }

  public closeDialog() {
    this.dialogRef.close();
  }

  private save(): JobRemarks {
    this.jobremarks.remarks = this.jobremarksForm.get('remarks').value;
    return this.jobremarks;
  }
}
