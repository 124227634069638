import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { CalGuardService } from '@cvx/cal-angular';
import { CAL_INTERCEPTOR_CONFIG } from './cal.constants';
import { CalInterceptorConfiguration } from './cal.interceptor.config';

@NgModule({
    imports: [CommonModule],
    declarations: [

    ],
    providers: []
})
export class CalInterceptorModule {
    static forRoot(
        calInterceptorConfig: CalInterceptorConfiguration
    ): ModuleWithProviders<CalInterceptorModule> {
        return {
            ngModule: CalInterceptorModule,
            providers: [
                {
                    provide: CAL_INTERCEPTOR_CONFIG,
                    useValue: calInterceptorConfig
                }
            ]
        };
    }
}