import { Shorebase } from './shorebase';

export class Runs {
    mode?: string;
    public constructor(init?: Partial<Runs>) {
        Object.assign(this, init);
    }

    heliportId: number;
    heliport: string;
    runID: number;
    runName:string;
    lastUpdatedBy:string;
    lastUpdatedDate:Date;
    shoreBaseID: number;
    shoreBaseName:string;
    flightName: string;
    runTime: string;
    activeInactive: boolean;
    isActive?: string;
    shorebase?: Shorebase; 
}