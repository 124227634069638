import { Component, Inject, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Leg } from '../models/leg';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { ManifestCalculationsService } from '../manifest-calculations/manifest-calculations.service';
import { Centroid } from '../models/centroid';
import { FlightSelectionService } from '../flight-info/flight-selection/flight-selection.service';
import { FlightSelection } from '../models/flight-selection';
import { Pax } from '../models/pax';
import { LoggingService } from 'src/app/logging/logging.service';
import { ManifestCalculation } from '../models/manifest-calculation';

@Component({
  selector: 'app-wb-compute',
  templateUrl: './wb-compute.component.html',
  styleUrls: ['./wb-compute.component.css'],
  exportAs: 'app-wb-compute',
})
export class WbComputeComponent implements OnInit {

  legpaxdisplayedcolumns: string[] = ['paxName', 'paxWeight', 'cargoWeight', 'totalWeight'];
  legPaxList = new MatTableDataSource<Pax>(this.Leg.legPax);
  public legBalanceForm: FormGroup;
  public flightCentroid: Centroid[];
  public flightSelection: FlightSelection;
  public flightRowcount: number;
  public currentLegErrors: string;
  public currentLegNumber: number;
  public manifest: ManifestCalculation


  constructor(@Inject(MAT_DIALOG_DATA) private Leg: Leg,
    private dialogref: MatDialogRef<WbComputeComponent>,
    private formBuilder: FormBuilder,
    private manifestCalcService: ManifestCalculationsService,
    private flightselected: FlightSelectionService,
    private logger: LoggingService) { }

  ngOnInit() {
    this.Initialize()
  }

  Initialize() {
    //Initialize leg from calculation service deep copy
   this.Leg = this.manifestCalcService.manifestCalculation.leg.find(l => l.legNumber === this.Leg.legNumber);
    this.currentLegNumber = this.Leg.legNumber;
    this.legBalanceForm = this.setForm();
    this.manifest = JSON.parse(JSON.stringify(this.manifestCalcService.manifestCalculation));
    this.flightselected.onFlightSelection().subscribe(
      (fs) => { this.flightSelection = fs; }
    )
    this.manifestCalcService.getCentroids(this.flightSelection.helicopterType.helicopterTypeID).subscribe
      ((centroids) => {
        this.flightCentroid = centroids;
        this.flightRowcount = this.getRowCountfromCentroid(this.flightCentroid);
      })

  }

  setForm(): FormGroup {
    return this.formBuilder.group({
      legNumber: [this.Leg.legNumber, Validators.required],
      legOrigination: [this.Leg.origination.locationName, Validators.required],
      legDestination: [this.Leg.destination.locationName, Validators.required],
      legPaxCount: [this.Leg.paxCount, Validators.required],
      legFuel: [this.Leg.carryingFuel, Validators.required],
      legRangeStart: [this.Leg.rangeStart, Validators.required],
      legRangeEnd: [this.Leg.rangeEnd, Validators.required],
      flightCG: [this.Leg.centerofGravity, Validators.required],
      pilot: [this.Leg.crewWeight, Validators.required],
      firstrowpax: [this.Leg.row1Weight, Validators.required],
      secondrowpax: [this.Leg.row2Weight, Validators.required],
      thirdrowpax: [this.Leg.row3Weight, Validators.required],
      fourthrowpax: [this.Leg.row4Weight, Validators.required],
      fifthrowpax: [this.Leg.row5Weight, Validators.required],
      sixthrowpax: [this.Leg.row6Weight, Validators.required],
      seventhrowpax: [this.Leg.row7Weight, Validators.required],
      legcargo: [this.Leg.cargoWeight, Validators.required],
      legPax: [this.Leg.legPax, Validators.required]
    })
  }

  computeCG(): void {

    this.manifest.leg = this.updateLegArray(this.manifest.leg, this.currentLegNumber);
    if (this.isLegFuelChangesValid(this.manifest.leg)) {
      this.manifestCalcService.postRecomputeLegs(this.manifest, this.Leg.legNumber).subscribe(
        (legs) => {
          // Need a relook, Api call even before fuel validation.
          this.Leg = legs.find(l => l.legNumber === this.Leg.legNumber);
          this.legBalanceForm = this.setForm();
          this.manifest.leg = legs;

        });
    }

  }

  updateLegArray(legs: Leg[], currentLegNumber: number): Leg[] {
    let legIndex = legs.findIndex(l => l.legNumber === currentLegNumber);
    legs[legIndex] = {
      ...legs[legIndex],
      carryingFuel: this.legBalanceForm.controls['legFuel'].value,
      crewWeight: this.legBalanceForm.controls['pilot'].value,
      row1Weight: this.legBalanceForm.controls['firstrowpax'].value,
      row2Weight: this.legBalanceForm.controls['secondrowpax'].value,
      row3Weight: this.legBalanceForm.controls['thirdrowpax'].value,
      row4Weight: this.legBalanceForm.controls['fourthrowpax'].value,
      row5Weight: this.legBalanceForm.controls['fifthrowpax'].value,
      row6Weight: this.legBalanceForm.controls['sixthrowpax'].value,
      row7Weight: this.legBalanceForm.controls['seventhrowpax'].value,
      cargoWeight: this.legBalanceForm.controls['legcargo'].value

    };
    return legs;
  }

  isLegFuelChangesValid(legs: Leg[]): boolean {
    this.currentLegErrors = '';
    let currentLeg: Leg = legs.find(l => l.legNumber === this.currentLegNumber);
    if (currentLeg.carryingFuel >= this.flightSelection.helicopterType.maxToFuel) {
      this.currentLegErrors = `Fuel exceeds over Max Fuel!${this.flightSelection.helicopterType.maxToFuel} lbs.`;
      return false;
    } else if (currentLeg.carryingFuel < currentLeg.fuel) {
      this.currentLegErrors = `Carrying Fuel is required below Fuel ${currentLeg.fuel} lbs.`;
      return false;
    }
    return true;
  }

 

  getRowCountfromCentroid(centroid: Centroid[]): number {
    return centroid.filter(c => c.helicopterRow.toLowerCase() != 'cargo' &&
      c.helicopterRow.toLowerCase() != 'cockpit').length;
  }

  closeDialog(): void {
    this.dialogref.close();
  }

  onSubmit(fgjb: NgForm): void {

    if (this.legBalanceForm.dirty) {
      this.logger.notify('Click ComputeCG to recompute and try saving', 'ok');
    } else {
      this.manifestCalcService.manifestCalculation.leg = this.manifest.leg;
      this.dialogref.close()
    }

  }
}