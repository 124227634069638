import { Injectable } from '@angular/core';
import { CalAngularService } from '@cvx/cal-angular';
import { Observable, EMPTY } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { LoggingService } from 'src/app/logging/logging.service';
import { Company } from '../models/companies';
import { Location } from '../models/locations';
import { Passenger } from '../models/passenger';
import { Reservation, ScheduledFlightRun } from '../models/reservation';
import { Runs } from '../models/runs';
import { ReservationsDataService } from './reservations-data.service';

@Injectable({
  providedIn: 'root'
})
/**
 * @description create and load reservations for given passenger/passengers
 */
export class ReservationsService {

  /**
  * @description the current date/time used for record lastUpdated and reservation time
  */
  private reservationDate: Date;

  private companies: Company[];
  private locations: Location[];

  private currentUser: string;

  constructor(
    private reservationsDataService: ReservationsDataService,
    private authService: CalAngularService,
    private logger: LoggingService
  ) { }

  /**
   * @description create reservations for a list of passengers for a given flight
   * @param reservedPassengers passengers to reserve
   * @param flight flight for passenger reservation
   * @param flightDate the MM/dd/yyyy for the flight
   * @param locations list of valid passenger originations & destinations
   * @param companies list of valid passenger companies
   */
  public createReservations(reservedPassengers: Passenger[], flight: Runs, flightDate: Date, locations: Location[], companies: Company[] ): Observable<Reservation[]> {
    try {
      if (reservedPassengers?.length > 0 && flight) {
        this.currentUser = this.getShortName(this.authService.getAccount().name);
        this.reservationDate = new Date();  //Set reserve date / record date etc | flightdate = run date -> just the day
        this.locations = locations;
        this.companies = companies;
        
        let reservations: Reservation[] = this.mapReservations(reservedPassengers, flightDate, flight);

        return reservations.length > 0 ? this.reservationsDataService.addList(reservations) : EMPTY;
      } else {
        return EMPTY;
      }

    } catch (error) {
      this.logger.logError(error);
      return EMPTY;
    }
  }

  /**
   * @description create a flight reservation for given passenger
   * @param passenger passenger to reserve
   * @param flight flight for passenger reservation
   * @param flightDate the MM/dd/yyyy for the flight
   * @param locations list of valid passenger originations & destinations
   * @param companies list of valid passenger companies
   */
  public createReservation(passenger: Passenger, flight: Runs, flightDate: Date, locations: Location[], companies: Company[]): Observable<Reservation> {
    try {
      this.currentUser = this.getShortName(this.authService.getAccount().name);
      this.reservationDate = new Date();   //Set reserve date / record date etc | flightdate = run date -> just the day
      this.locations = locations;
      this.companies = companies;

      let passengerReservation: Reservation = this.mapReservation(passenger, flightDate, flight);
      
      return passengerReservation ? this.reservationsDataService.add(passengerReservation) : EMPTY;
    } catch (error) {
      this.logger.logError(error);
      return EMPTY;
    }
  }

  /**
   * 
   * @param flightDate the MM/dd/yyyy for the flight
   * @param flight the flight/run - will filter for the included flight/run
   * 
   */
  public loadReservations(flightDate: Date, flight?: Runs): Observable<Reservation[]> {
    try {
      if (!flight) {
        return this.reservationsDataService.get(flightDate);
      } else {
        // Filter the list for the included run
        return this.reservationsDataService.get(flightDate)
          .pipe(map(reservations => reservations?.filter(reservation => reservation?.scheduledFlightRun?.runID === flight.runID)))
          .pipe(tap(reservations => this.logger.log(`Reservations Loaded: ${reservations?.length} for flight: ${flight.runName}`)));
      }
    } catch (error) {
      this.logger.log(error);
      return EMPTY;
    }
  }

  public deleteReservation(id: number): Observable<number> {
    try {
      if (id > 0) {
        return this.reservationsDataService.delete(id);
      } else {
        return EMPTY;
      }
    } catch (error) {
      this.logger.log(error);
      return EMPTY;
    }
  }


  private mapReservations(passengers: Passenger[], flightDate: Date, flight: Runs): Reservation[] {
    let reservations: Reservation[];
    try {
      if (passengers) {
        passengers.forEach(passenger => {
          let name: string[] = passenger.fullName.split(' ');
          passenger.firstName = name[0];
          passenger.lastName = name[name.length - 1];
        });

        reservations = passengers.map(passenger => this.mapReservation(passenger, flightDate, flight));
      }
    } catch (error) {
      this.logger.logError('Error mapping to reservation');
    }

    return reservations;
  }

  private mapReservation(passenger: Passenger, flightDate: Date, flight: Runs): Reservation {
    let company: Company = this.getCompany(passenger.company);
    let origination: Location = this.getLocation(passenger.from);
    let destination: Location = this.getLocation(passenger.to);

    return {
      cargo: passenger.cargo,
      cargoDescription: 'Passenger Baggage',
      chargeCode: passenger.chargeCode.toString(),
      company: company,
      companyID: company.companyID,//(!passenger.companyID ? 0 : passenger.companyID),
      companyName: passenger.company,
      destination: destination,
      destinationName: passenger.to,
      enteredBy: this.currentUser,
      firstName: passenger.firstName,
      flownIndicator: false,
      fullName: passenger.fullName,
      lastName: passenger.lastName,
      lastUpdatedBy: this.currentUser,
      lastUpdatedDate: this.reservationDate,
      origination: origination,
      originationName: passenger.from,
      part135Code: passenger.part135Code,
      part135: { part135Code: "CVX", part135Name: "Chevron" },
      passengerWeight: passenger.weight,
      paxIndex: (!passenger.paxIndex ? 0 : passenger.paxIndex),
      reservationDate: this.reservationDate,
      reservationID: 0,
      runDate: flightDate,
      scheduledFlightRun: this.setScheduledFlightRun(flight),
      specialRunName: '',
      totalWeight: passenger.totalWeight,
      unAccompaniedBaggage: false,
      verifyFlag: passenger.verifyChargeCode
    };
  }

  private setScheduledFlightRun(run: Runs): ScheduledFlightRun {
    let scheduledRun: ScheduledFlightRun = new ScheduledFlightRun();

    scheduledRun.activeInactive = run.activeInactive;
    scheduledRun.isActive = run.isActive;
    scheduledRun.lastupdatedby = run.lastUpdatedBy;
    scheduledRun.lastupdateddate = run.lastUpdatedDate;
    scheduledRun.runID = run.runID;
    scheduledRun.runName = run.runName;
    scheduledRun.runTime = run.runTime;
    scheduledRun.shoreBase = run.shorebase;

    return scheduledRun;
  }

  private getCompany(name: string): Company {
    try {
      let filteredCompanies: Company[];
      
      filteredCompanies = this.companies.filter(com => com.description === name);

      let company: Company = new Company();
      if (filteredCompanies?.length > 0) {
        company = filteredCompanies[0];
      } else {
        company.description = name;
      }

      return company;
    } catch (error) {
      this.logger.logError(error);
    }
  }

  private getLocation(name: string): Location {
    try {
      let filteredLocations: Location[];
      
      filteredLocations = this.locations.filter(loc => loc.locationName.toUpperCase() === name.toUpperCase());

      let location: Location = new Location();
      if (filteredLocations?.length > 0) {
        location = filteredLocations[0];
      } else {
        location.locationName = name;
      }

      return location;
    } catch (error) {
      this.logger.logError(error);
    }
  }

  private getShortName(username: string) {
    let shortName = username.substr(0, 20);
    shortName = shortName.substring(0, shortName.lastIndexOf(' '))
    return shortName;
  }
}
