import { HelicopterTypes } from "./helicoptertypes";

export class Helicopter {
    mode?: string;

    public constructor(init?: Partial<Helicopter>) {
        Object.assign(this, init);
    }
    
    helicopterID: number;
    helicopterTypes?: HelicopterTypes;
    helicopterTypeName: string;
    tailNumber: string;
    helicopterOwner: string;
    basicWeight: number;
    arm: number;
    airCraftMoment: number;
    isActive?: string;
    activeInactive: boolean;
    lastUpdatedBy?:string;
    lastUpdatedDate?:Date;
}
