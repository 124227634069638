import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { Helicopter } from '../models/helicopter';
import { SelectionModel } from '@angular/cdk/collections';
import { HelicoptersService } from './helicopter.service';
import { LoggingService } from 'src/app/logging/logging.service';
import { AddhelicopterComponent } from '../addhelicopter/addhelicopter.component';
import { cvxActionConfiguration } from 'src/app/shared/cvxActionButton/cvxActionConfiguration';
import { TableVirtualScrollDataSource } from 'src/app/shared/tableVirtualScroll/tableVirtualScrollDataSource';
import { CvxProgressService } from 'src/app/shared/cvx-progress/cvx-progress.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { environment } from 'src/environments/environment';
import { RolesService } from 'src/app/auth/roles.service';
import { rowfadeOut } from 'src/app/shared/animations/animations';

const dialogWidth: string = '550px';

@Component({
  selector: 'app-helicopter',
  templateUrl: './helicopter.component.html',
  styleUrls: ['./helicopter.component.css'],
  animations: [rowfadeOut]
})
export class HelicopterComponent implements OnInit, AfterViewInit {
  public dataSource: TableVirtualScrollDataSource<Helicopter>;
  public displayedColumns: string[] = ['select', 'helicopterTypeName', 'tailNumber', 'helicopterOwner', 'basicWeight', 'arm', 'airCraftMoment', 'isActive',"copy"];

  public selection = new SelectionModel<Helicopter>(true, []);
  public helicopters: Helicopter[];
  public selectedHelicopters: Helicopter[];
  public filterValue: string;
  public hasDeletePermissions: boolean = false;
  public actions: cvxActionConfiguration[];
  public deleteRole: string;

  constructor(private helicoptersService: HelicoptersService,
    private logger: LoggingService,
    public dialog: MatDialog,
    private progress: CvxProgressService,
    private rolesService: RolesService) { }

  @ViewChild(MatSort, { static: false }) sort: MatSort;

  public ngOnInit() {
    try {
      this.initialize();

    } catch (error) {
      this.logger.log(`Error Loading Helicopters: ${error}`);
    }
  }

  public async ngAfterViewInit() {
    try {
      this.getHelicopters();
      await this.initializeActions();

    } catch (error) {
      this.logger.log(`Error initializing data/actions: ${error}`);
    }
  }

  public onFilter(value: string): void {
    this.dataSource.filter = value.trim().toLocaleLowerCase();
  }

  public onClear(): void {
    this.filterValue = '';
    this.onFilter(this.filterValue);
  }

  private initialize() {
    this.helicopters = [];
    this.selectedHelicopters = [];

    this.dataSource = new TableVirtualScrollDataSource<Helicopter>();
  }

  private async initializeActions(): Promise<void> {
    this.deleteRole = environment.adminRole;
    this.hasDeletePermissions = await this.rolesService.isInGroup(this.deleteRole);

    this.actions = [
      {
        name: 'add',
        icon: 'add',
        display: 'insert',
        disabled: false
      },
      {
        name: 'delete',
        icon: 'delete',
        display: 'delete',
        disabled: !this.hasDeletePermissions
      }
    ];
  }

  public onAction(action: cvxActionConfiguration): void {
    if (action.name === 'add') {
      this.add();
    } else {
      this.onDelete();
    }
  }

  public onCopyClick($event: any, copyHelicopter: Helicopter) {
    $event.stopPropagation();
    //Deep copy to avoid updating passed reference
    let row:Helicopter = {...copyHelicopter};
    row.helicopterID = 0;
    row.mode = "Add";

    let dialogConfig: MatDialogConfig = {
      maxWidth: '100vw',
      maxHeight: '100vh',
      width: '975px',
      disableClose: false,
      autoFocus: true,
      hasBackdrop: true,
      data: row
    };

    const dialogRef = this.dialog.open(AddhelicopterComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        let typeData: Helicopter = result;

        this.insert(typeData);
        return true;
      }
    });
  }

  public add() {
    let dialogConfig: MatDialogConfig = {
      width: dialogWidth,
      disableClose: false,
      autoFocus: true,
      hasBackdrop: true

    };

    const dialogRef = this.dialog.open(AddhelicopterComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        let helicopter: Helicopter = result;

        this.insert(helicopter);
        return true;
      }
    });
  }

  public insert(helicopterData: Helicopter): void {
    this.progress.spin$.next(true);
    this.helicoptersService.add(helicopterData).subscribe(
      result => {
        this.helicoptersService.get(true).subscribe((helicopters) => {
          this.helicopters = [...helicopters];
          this.setTable();
          this.progress.spin$.next(false);
          this.logger.notify('Item Added', 'Dismiss');
        })
      },
      error => {
        this.progress.spin$.next(false);
        this.logger.logError<Helicopter>(`Error while inserting helicopter data |${error}`);
      }
    );
  }

  public onSelectRow($event: any, row: Helicopter) {
    if ($event.checked) {
      this.logger.log(row);
      this.selectedHelicopters.push(row);
    } else {
      this.logger.log(row);
      this.selectedHelicopters.splice(this.selectedHelicopters.indexOf(row), 1);
    }
  }

  public edit($event: any, row: Helicopter) {
    row.mode = "Edit";
    let dialogConfig: MatDialogConfig = {
      width: dialogWidth,
      disableClose: false,
      autoFocus: true,
      hasBackdrop: true,
      data: row
    };

    const dialogRef = this.dialog.open(AddhelicopterComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.helicopters[this.helicopters.indexOf(row)] = result;
        this.update(result);

        return true;
      }
    });
  }

  private setTable() {
    this.dataSource = new TableVirtualScrollDataSource<Helicopter>(this.helicopters);
    this.dataSource.sort = this.sort;
  }

  public update(helicopter: Helicopter): void {
    this.progress.spin$.next(true);
    this.helicoptersService.update(helicopter.helicopterID, helicopter).subscribe(
      result => {
        this.progress.spin$.next(false)
        this.setTable();
        this.logger.notify('Item Updated', 'Dismiss');
      },
      error => {
        this.progress.spin$.next(false);
        this.logger.logError<Helicopter>(`Error while updating helicopter data: ${helicopter.helicopterID}|${error}`);
      }
    );

  }

  public onDelete(): void {
    this.selectedHelicopters.forEach(row => {
      this.delete(row);
    });
    this.setTable();
  }

  public delete(row: Helicopter): void {
    this.helicoptersService.delete(row.helicopterID, row).subscribe(
      _ => {
        this.selectedHelicopters.splice(this.selectedHelicopters.indexOf(row), 1);
        this.helicopters.splice(this.helicopters.indexOf(row), 1);
        this.setTable();
        this.logger.notify('Item Deleted', 'Dismiss');
      },
      error => this.logger.logError<Helicopter>(`Error deleting helicopter: ${row.helicopterID}|${error}`)
    );
  }

  private getHelicopters(): void {
    this.progress.spin$.next(true);
    this.helicoptersService.get().subscribe(
      helicopters => {
        this.helicopters = helicopters;
        this.setTable();
        this.progress.spin$.next(false);
      },
      error => {
        this.logger.logError<Helicopter>(`Errors occurred loading helicopters, ${error}`);
        this.progress.spin$.next(false);
      }
    );
  }

}
