import { Helicopter } from "./helicopter";
import { HelicopterTypes } from "./helicoptertypes";
import { Runs } from "./runs";

export class FlightSelection {
    
    public constructor(init?: Partial<FlightSelection>) {
        Object.assign(this, init);
    }

    flight: Runs;
    job: string;
    helicopterType: HelicopterTypes;
    tailNumber: Helicopter;
}