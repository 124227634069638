import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddpassengersComponent } from './addpassengers.component';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDividerModule } from '@angular/material/divider';
import { MatDialogModule } from '@angular/material/dialog';
import { ReactiveFormsModule } from '@angular/forms';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

const components = [
    AddpassengersComponent
];
const providers = [    ];

@NgModule({

    declarations: [components],
    imports: [
        CommonModule, 
        MatDialogModule, 
        MatDividerModule,
        MatFormFieldModule,
        MatCheckboxModule, 
        MatSelectModule,
        MatInputModule,
        ReactiveFormsModule,
        MatButtonModule,
        ScrollingModule,
        MatSlideToggleModule
    ],
    providers: [providers],
    exports: [components]
})
export class AddpassengersModule { }
