import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlightReservationsComponent } from './flight-reservations.component';
import { PassengersModule } from '../passengers/passengers.module';
import { RouterModule } from '@angular/router';
import { FlightImportComponent } from './flight-import/flight-import.component';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FlightImportDialogComponent } from './flight-import-dialog/flight-import-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { FlightImportButtonComponent } from './flight-import-button/flight-import-button.component';
import { MatGridListModule } from '@angular/material/grid-list';
import { FlightRunSelectionComponent } from './flight-runselection/flight-runselection.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatDividerModule } from '@angular/material/divider';
import { MatSelectModule } from '@angular/material/select';




@NgModule({
  declarations: [
    FlightReservationsComponent, 
    FlightImportComponent, FlightImportDialogComponent, FlightImportButtonComponent, FlightRunSelectionComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,    
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    FlexLayoutModule,
    PassengersModule,
    PipesModule,
    MatDialogModule,
    MatGridListModule,
    MatExpansionModule,
    MatDividerModule,
    MatSelectModule
  ],
  exports: [
    FlightReservationsComponent,
    FlightImportComponent,
    FlightImportDialogComponent,
    FlightImportButtonComponent,
    FlightRunSelectionComponent
  ],
  entryComponents: [
    FlightImportDialogComponent
  ]
})
export class FlightReservationsModule { }
