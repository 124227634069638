export class MasterManifestPassenger {
    
    public constructor(init?: Partial<MasterManifestPassenger>) {
        Object.assign(this, init);
    }
    ManifestPassengerID: number;
    ManifestGUID: string;
    Cargo: number;
    ChargeCode: string;
    CompanyName:string;
    Distance: number;
    FirstName: string;
    LastName: string;
    FromLocation:string;
    ToLocation: string;
    Part135Code: string;
    PassengerWeight: number;
    PaxIndex: number;
    PickLater: boolean;
    Status: string;
}
