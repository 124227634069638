import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LoggingService } from 'src/app/logging/logging.service';
import { environment } from 'src/environments/environment';
import { IManifest, IManifestSave, ManifestSelection } from '../models/manifest';
import { IManifestInfo } from '../models/manifestinfo';

@Injectable({
  providedIn: 'root'
})
export class ManifestService {
  private api = `${environment.api}manifest/`;
  
  
  constructor(private http: HttpClient, private logger: LoggingService) { }

  getManifests(filterModel?: ManifestSelection): Observable<IManifest[]> {
    if (filterModel) {
      let manifestParams ={
        "shoreBaseId": filterModel.shoreBaseId.toString(),
        "locationPath": filterModel.locationPath.toString(),
        "tailNumber": filterModel.tailNumber.toString(),
        "flightNumber": filterModel.flightNumber.toString(),
        "runId": filterModel.runId.toString(),
        "fromManifestDate": filterModel.fromManifestDate.toISOString(), 
        "toManifestDate": filterModel.toManifestDate.toISOString()
      }
      return this.http.get<IManifest[]>(`${this.api}search`, { headers: environment.headers, params:  manifestParams})
      .pipe(
        catchError(_error => {
          return this.logger.logError<IManifest[]>('Error retrieving manifests')
        })
      );
    } else {
      return this.http.get<IManifest[]>(this.api, { headers: environment.headers })
        .pipe(
          catchError(_error => {
            return this.logger.logError<IManifest[]>('Error retrieving manifests')
          })
        );
    }
  }

  getManifestsByShoreBaseID(shoreBaseId: number, date: Date): Observable<IManifest[]> {
    if (shoreBaseId) {
      return this.http.get<IManifest[]>(`${this.api}${shoreBaseId}/${date.toISOString()}`, { headers: environment.headers })
      .pipe(
        catchError(_error => {
          return this.logger.logError<IManifest[]>('Error retrieving manifests')
        })
      );
    } else {
      return this.http.get<IManifest[]>(this.api, { headers: environment.headers })
        .pipe(
          catchError(_error => {
            return this.logger.logError<IManifest[]>('Error retrieving manifests')
          })
        );
    }
  }
    getManifestsByID(manifestID: number): Observable<IManifestInfo> {
        if (manifestID) {
            return this.http.get<IManifestInfo>(`${this.api}view/${manifestID}`, { headers: environment.headers })
                .pipe(
                    catchError(_error => {
                        return this.logger.logError<IManifestInfo>('Error retrieving manifests')
                    })
                );
        } else {
            return this.http.get<IManifestInfo>(this.api, { headers: environment.headers })
                .pipe(
                    catchError(_error => {
                        return this.logger.logError<IManifestInfo>('Error retrieving manifests')
                    })
                );
        }
    }

    print(manifestId: number): Observable<any> {

        return this.http.get<any>(`${this.api}download?manifestId=${manifestId}`, { headers: environment.headers, responseType: 'arraybuffer' as 'json' })
            ;
    }

  add(manifest: IManifestSave) : Observable<IManifestSave> {
    return this.http.post<IManifestSave>(this.api, manifest, { headers: environment.headers })
      .pipe(
        catchError(_error => {
          return this.logger.logError<IManifestSave>(`Error adding manifest: ${manifest}`)
        })
      );
  }

  update(id: number, manifest: IManifest): Observable<IManifest> {
    return this.http.put<IManifest>(`${this.api}${id}`, manifest, { headers: environment.headers })
      .pipe(
        catchError(_error => {
          return this.logger.logError<IManifest>(`Error updating manifest: ${manifest}`)
        })
      );
  }

  delete(manifest: IManifest | number): Observable<any> {
    const id = typeof manifest === 'number' ? manifest : manifest.flightNumber;

    const url = `${this.api}${id}`;

    return this.http.delete<number>(url, { headers: environment.headers })
      .pipe(
        catchError(_error => {
          return this.logger.logError<IManifest>(`Error deleting company: ${manifest}`)
        })
      );
  }

}
