import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable, of, BehaviorSubject, Subject } from 'rxjs';
import { Log } from './log';

@Injectable({providedIn: 'root'})
export class LoggingService { 

    private readonly _loggingSubject = new Subject<Log>();
    private durationInSeconds: number = 5;

    public logging$() { 
        return this._loggingSubject.asObservable(); 
    }

    constructor(private snackBar: MatSnackBar) { }

    /**
     * Log a message and persist value for subscription
     * @param msg the message to log
     */
    log (msg: any): void {
        let log : Log = { 
            date: new Date(),
            message: msg
        };
        console.log(`${log.date}:${log.message}`);
        
    }

    /**
     * Handle Http operation that failed.
     * Let the app continue.
     * @param operation - name of the operation that failed
     * @param result - optional value to return as the observable result
     */
    logError<T>(operation = 'operation', result?: T): Observable<T> {
        const log: Log = { 
            date: new Date(),
            message: operation
        }
        this._loggingSubject.next(log);

        this.notify(log.message, 'Dismiss');

        return of(result as T);
    }   
    
    public notify(message: string, action: string) {
        this.snackBar.open(message, action,  {
          duration: this.durationInSeconds * 1000
        });
      }
}