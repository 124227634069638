import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LoggingService } from 'src/app/logging/logging.service';
import { environment } from 'src/environments/environment';
import { Runs } from 'src/app/dtms/models/runs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RunsService {
  private api = `${environment.api}runs/`;
  httpClient: any;
  
  constructor(private http: HttpClient, private logger: LoggingService) { }

  get(resetCache?:boolean): Observable<Runs[]> {
    return this.http.get<Runs[]>(!resetCache?this.api:`${this.api}?resetCache=${resetCache}`, { headers: environment.headers })
        .pipe(
          catchError(error => {
            return this.logger.logError<Runs[]>('Error retrieving runs from api');
          })
        );
  }

  add(run: Runs) : Observable<Runs> {
    return this.http.post<Runs>(this.api, run, { headers: environment.headers })
    .pipe(
      catchError(error => {
        return this.logger.logError<Runs>(`Error adding run in api: ${run.runName}`);
      })
    );
  }

  update(id: number, run: Runs): Observable<Runs> {
    return this.http.put<Runs>(`${this.api}${id}`, run, { headers: environment.headers })
    .pipe(
      catchError(error => {
        return this.logger.logError<Runs>(`Error updating run in api: ${run.runName}`);
      })
    );
  }

  delete(id: number,run: Runs): Observable<any> {
    const url = `${this.api}${id}`;
    
    return this.http.delete<Runs>(url, { headers: environment.headers })
    .pipe(
      catchError( error => {
        return this.logger.logError<Runs>(`Error deleting run(s) in api`);
      })
    );
  }
}
