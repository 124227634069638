import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { LoggingService } from 'src/app/logging/logging.service';
import { cvxActionConfiguration } from 'src/app/shared/cvxActionButton/cvxActionConfiguration';
import { AddjobremarksComponent } from '../../addjobremarks/addjobremarks.component';
import { FlightSelection } from '../../models/flight-selection';
import { JobRemarks, Remarks } from '../../models/jobremarks';
import { FlightSelectionService } from '../flight-selection/flight-selection.service';
import { RemarksService } from './remarks.service';

const dialogWidth: string = '300px';

@Component({
  selector: 'flight-remarks',
  templateUrl: './remarks.component.html',
  styleUrls: ['./remarks.component.css']
})

export class RemarksComponent implements OnInit,AfterViewInit, OnChanges {

  private _enroutefuel: string;

  @Input() manifestRemarks:Remarks;
  @Output() remarksUpdated = new EventEmitter<{ xponder: number, remarks: string }>();

  public jobRemarks: Observable<JobRemarks[]>;
  public jobRemarksArray: JobRemarks[];
  public fs: Observable<FlightSelection>;
  public runName: string;
  public tailNumber: string;
  public helicopterTypename: string;
  public actions: cvxActionConfiguration[];
  public loadingRemarks: boolean = true;
  private remarksForm: FormGroup;
  private remarks: Remarks;

  constructor(private remarksservice: RemarksService,
    private fsservice: FlightSelectionService,
    private dialog: MatDialog,
    private logger: LoggingService,
    private formBuilder: FormBuilder) { }

  ngOnChanges(changes: SimpleChanges): void {
    if(this.manifestRemarks) {
      this.remarks = this.manifestRemarks;
      this.remarksForm = this.setForm();
      this.load();
    }
  }

  ngOnInit(): void {
    // To be cleaned up
    this.initialize();

  }
  ngAfterViewInit(): void {
    this.load();
  }





  private initialize(): void {

    if (!this.remarks) {
      this.remarksForm = this.createForm();
    } else {
      this.remarksForm = this.setForm();
    }

    this.actions = [
      {
        name: 'add',
        icon: 'add',
        display: 'insert',
        disabled: false
      }
    ];

  }

  public createForm(): FormGroup {
    this.remarks = {
      xponder: 0,
      remarks: "",
      enrouteFuel: ""
    }

    return this.setForm();
  }

  public setForm(): FormGroup {
    return this.formBuilder.group({
      xponder: [this.remarks.xponder],
      remarks: [this.remarks.remarks],
      enroutefuel: [this.remarks.enrouteFuel]
    })
  }

  private load(): void {
    this.remarksForm.valueChanges.subscribe((result) =>
      this.remarksUpdated.emit({
        xponder: result.xponder,
        remarks: result.remarks
      }));
  }

  public onAction(action: cvxActionConfiguration): void {
    if (action.name === 'add') {
      this.add();
    }
  }
  public add() {
    let dialogConfig: MatDialogConfig = {
      width: dialogWidth,
      disableClose: false,
      autoFocus: true,
      hasBackdrop: true

    };

    const dialogRef = this.dialog.open(AddjobremarksComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        let jobremarks: JobRemarks = result;
        this.insert(jobremarks);
        return true;
      }
    });
  }

  public insert(jobremarks: JobRemarks): void {
    this.remarksservice.save(jobremarks).subscribe(
      result => {
        if (result > 0) {
          this.updateRemarksArray(jobremarks);
          this.logger.notify('Item Added', 'Dismiss');
        }
      },
      error => this.logger.logError<JobRemarks>(`Error while inserting jobremarks data |${error}`)
    );
  }

  updateRemarksArray(jobremarks: JobRemarks): void {
    let updatedJobRemarks: JobRemarks[];
    updatedJobRemarks = this.jobRemarksArray;
    //array need to reassigned in order to trigger change detection
    this.jobRemarksArray = [jobremarks, ...updatedJobRemarks]
  }


}
