import { Leg } from './leg';
import { ImanifestLegInfo } from './manifestinfo';
import { Pilot } from './pilot';

export interface IManifestSave {
  manifestGUID:string;
  manifestId:number;
  shorebaseId:number;
  helicopterId:number;
  runId:number;
  flightNumber: number;
  shorebaseJobId:number;
  pilot1Id: number;
  pilot2Id: number;
  flightEngineerId:number;
  createdDate:Date;
  flightPath:string;
  centerOfGravity?: number;
  cgRangeLow?: number;
  cgRangeHigh?: number;
  stateTime: number;
  stoptostopFuel:string;
  flightTime: number;
  charge: string;
  landings:number;
  crewWeight: number;
  basicWeight: number;
  takeoffTime: string;
  returnTime: string;
  part135: boolean;
  part91: boolean;
  tas:number;
  xPonder?: number;
  burnPerHour?: number;
  lbsStop?: number;
  minsStop:number;
  windDirection?: number;
  windSpeed?: number;
  remarks:string;
  lastUpdatedBy:string;
  lastUpdatedDate:Date;
  manifestLegs: IManifestLeg[];
  manifestPassengers:IManifestPassenger[]
  isIfr?: boolean;
}

export interface IManifestLeg {
  fromLocation:any;
  fromLocationID:number;
  lastUpdatedBy:string;
  lastUpdatedDate:Date;
  legNumber:number;
  manifestGUID:string;
  manifestLegID:number;
  stopToStopBagWgtDrop?:number;
  stopToStopBagWgtPickup?:number;
  stopToStopBurn:number;
  stopToStopCarWgtDrop:number;
  stopToStopCarWgtPickup:number;
  stopToStopDist:number;
  stopToStopEte:number;
  stopToStopFuel:number;
  stopToStopPaxWgtDrop:number;
  stopToStopPaxWgtPickup:number;
  stopToStopStatus?:number;
  stopToStopTow:number;
  toLocationID:number;
  toLocation:any;
  weightBalanceCG:number;
  weightBalanceCargoWeight:number;
  weightBalanceCrewWeight:number;
  weightBalanceFuel:number;
  weightBalanceFuelMoment:number;
  weightBalanceMaxTow:number;
  weightBalancePax:number;
  weightBalanceRange:string;
  weightBalanceRow1Weight:number;
  weightBalanceRow2Weight:number;
  weightBalanceRow3Weight:number;
  weightBalanceRow4Weight:number;
  weightBalanceRow5Weight:number;
  weightBalanceRow6Weight:number;
  weightBalanceRow7Weight:number;
  weightBalanceTow:number;
}

export interface IManifestPassenger {
  cargo: number;
  chargeCode: string;
  companyID?: number;
  distance?: number;
  company: string;
  firstName?: string;
  fromLocationID?: number;
  helicopterRow?: number;
  lastName?: string;
  lastUpdatedBy:string;
  lastUpdatedDate:Date;
  manifestGUID?: string;
  manifestPassengerID?: number;
  part135Code?: string;
  passengerWeight: number;
  paxIndex?: number;
  pickLater?: boolean;
  reservationID?: number;
  toLocationID?: number;
  verifyChargeCode?: boolean;
}
export interface IManifest {
  manifestGUID: any;
  manifestId: number;
    burnPerHour?: number;
    cgRangeHigh?: number;
    cgRangeLow?: number;
    centerOfGravity?: number;
    lbsStop?: number;
    windDirection?: number;
    windSpeed?: number;
    xPonder?: number;
    flightNumber: number;
    takeoffTime: Date;
    returnTime: Date;
    pilot1: string | Pilot;
    pilot2: string | Pilot;
    pilot1Id: string;
    pilot2Id: string ;
    charge: string;
    basicWeight: number;
    articaftMoment: number;
    crewWeight: number;
    stateTime: number;
    flightTime: number;
    part91: boolean;
    part135: boolean;
    leg: Leg[];
    manifestLegs: ImanifestLegInfo[];
    location: string;
    PAX: number;
    fuel: number;
    fuelMoment: number;
    cg: number;
    range: number;
    tow: number;
    maxtow: number;
    crew: number;
    cargo: number;
    ifr?: boolean;
  }

  export interface IJobRemarks {
    
      date: number;
      shoreBaseId: number;
      job: number;
      helicopterTypeId: number;
      tailNumber: number;
      jobRemarkID: number;
      remarks: any;
      ron: number;
      scheduledAOG: number;
      unScheduledAOG: number;
    }

export class ManifestSelection {
  public constructor(init?: Partial<ManifestSelection>) {
    Object.assign(this, init);
}
    shoreBaseId:number;
    locationPath: string;
    tailNumber?: string;
    flightNumber: number;
    runId: number;
    fromManifestDate: Date;
    toManifestDate: Date;
    // id:number;
  }

  export class JobRemarksSelection {
    public constructor(init?: Partial<JobRemarksSelection>) {
      Object.assign(this, init);
  }
    dateWise: boolean;
    shoreBaseId: number;
    jobNumber: string;
    tailNumber?: string;
    helicopterTypeId: number;
    fromManifestDate: Date;
    }
  

  export interface IWeightbalance
  {
    leg: number;
    location: string;
    PAX: number;
    fuel: number;
    fuelmoment: number;
    cg: number;
    range: number;
    tow: number;
    maxtow: number;
    crew: number;
    row1: number;
    row2: number;
    row3: number;
    row4: number;
    cargo: number;
  }

  export interface IWeightbalance
  {
    leg: number;
    location: string;
    PAX: number;
    fuel: number;
    fuelmoment: number;
    cg: number;
    range: number;
    tow: number;
    maxtow: number;
    crew: number;
    row1: number;
    row2: number;
    row3: number;
    row4: number;
    cargo: number;
  }
  
  export interface ICGCompute1
  {
    fullname: string;
    weight: number;
    cargo: number;
    totalweight: number;
  }

  export interface ICGCompute2
  {
    leg:string;
    pax:string;
    fuel:string;
    cgRange:string;
    from: string;
    to: string;
    cg: string;
    pilot: string;
    firstrowpax:string;
    secondrowpax:string;
    thirdrowpax:string;
    cockpitcentroid:string;
    firstrowcentroid:string;
    secondrowcentroid:string;
    thirdrowcentroid:string;
    cargocentroid:string;
  }