import { Component } from '@angular/core';
import { LoggingService } from 'src/app/logging/logging.service';
import { NextPageService, NavigationLocation, PageLayout, SearchType, SliderType, MenuObject, NextPageLayoutService } from '@cvx/nextpage';
import { ITray } from 'src/app/dtms/models/tray';
import { Router } from '@angular/router';
import { MonitoringService } from './logging/monitoring.service';
import { environment } from 'src/environments/environment';
import { ICvxClaimsPrincipal, CvxClaimsPrincipal, CalAngularService } from '@cvx/cal-angular';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  public title = environment.appTitle;
  public trayslist: ITray[];
  public isIframe = false;
  public loggedIn = false;
  public profile: ICvxClaimsPrincipal;
  public manifestingRole: string;
  public menu: MenuObject[] = [];
  public searchType: SearchType = SearchType.Global;

  constructor(
    private NP: NextPageService,
    private layout: NextPageLayoutService,
    public logger: LoggingService,
    public router: Router,
    private authService: CalAngularService,
    private monitoring: MonitoringService
  ) {
    try {
      this.initialize(router);
    }
    catch (error) {
      //Log error message along with time
      this.logger.log(error);
    }
  }

  private initialize(router: Router) {    
    this.NP.options.sitename = environment.appTitle;
    this.menu = [
      {
        title: '',
        url: ''
      }
    ];
    this.NP.options.menuStructure = this.menu;
    this.router = router;

    this.profile = new CvxClaimsPrincipal();
  }

  ngOnInit() {
    this.isIframe = window !== window.parent && !window.opener;

    this.authService.isUserSignedIn().then((loggedIn: boolean) => {
      if (loggedIn) {
        this.profile = this.authService.cvxClaimsPrincipal;
      }
      else {
        this.logger.log("user not yet signed in");
      }
    });

    this.manifestingRole = environment.manifestingRole;
  }

  async logout() {
    this.authService.userInitiatedSignOut().then((success: boolean) => {
      if(success) {
        this.router.navigateByUrl('/')
      } else {
        this.logger.notify('An issue occurred logging out, please try again', 'Ok');
      }
    });
  }

   getFullName() {
    let fullName:string ="";
    fullName = this.profile.name;
    if(fullName != ""){
      fullName = fullName.split("[")[0];
    }
    return fullName;
  }

  // Show background only on main view
  public showBackground(): boolean {
    return this.router.url == '/';
  }
}
