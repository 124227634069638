import { Injectable } from '@angular/core';
import { AsyncValidator, FormControl, ValidationErrors } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { LocationsService } from '../locations/locations.service';
import { Location } from '../models/locations';

@Injectable({
    providedIn: 'root'
  })
export class LocationsValidator implements AsyncValidator {
    constructor(private locationsService: LocationsService) {  }

    validate = (control: FormControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> => {
        return this.locationsService.get().pipe(
            map(locations => this.validateLocation(locations, control)),
            catchError(() => of(null))
        );
    };

    private validateLocation(locations: Location[], control: FormControl): ValidationErrors | null {
        return (locations.findIndex((location) => location.locationName.toUpperCase() === control.value.toUpperCase()) === -1) ? { notFound: true } : null;
    }

}
