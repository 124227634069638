import { Injectable } from '@angular/core';
import { Observable, Subject} from 'rxjs';
import { Passenger } from '../models/passenger';
import { LoggingService } from 'src/app/logging/logging.service';
import { Pax } from '../models/pax';
import { Reservation } from '../models/reservation';
import { IPassengerManifest } from '../models/manifestinfo';
import { LocationsService } from '../locations/locations.service';
import { CompaniesService } from '../companies/companies.service';
import { FlighRunSelectionService } from '../flight-reservations/flight-runselection/fligh-runselection.service';
import { RunsService } from '../runs/runs.service';

@Injectable({
  providedIn: 'root'
})
export class PassengersService {
  constructor(public logger: LoggingService,
    public locationsService: LocationsService,
    public companiesService: CompaniesService,
    public runSelService:FlighRunSelectionService,
    public runsService:RunsService) { }

  private reservedPassengers$: Subject<Passenger[]> = new Subject();
  private confirmedPassengers$: Subject<Passenger[]> = new Subject();
  //This property is only for components which cannot subscribe changes to reserved passenger.
  public reservedPassengers:Passenger[];

  onPassengersReserved(): Observable<Passenger[]> {
    this.logger.log(`Reserved Passengers ${this.reservedPassengers$}`);
    return this.reservedPassengers$.asObservable();
  }

  /**
 * @description set reserved passengers
 * @param passengers passengers to reserve
 * @param useCache cache the passengers in browser tab | default: false 
 */
  setReservedPassengers(passengers: Passenger[], useCache: boolean = false) {
    this.logger.log(`Reserved Passengers Set: ${passengers}`);
    this.reservedPassengers$.next(passengers);
    this.reservedPassengers = passengers;
    if (useCache) {
      sessionStorage.setItem('reservedPassengers', JSON.stringify(passengers));
    }
  }

  onPassengersConfirmed(): Observable<Passenger[]> {
    this.logger.log(`Confirmed Passengers ${this.confirmedPassengers$}`);
    return this.confirmedPassengers$.asObservable();
  }

  /**
   * @description set confirmed passengers
   * @param passengers passengers to confirm
   * @param useCache cache the passengers in browser tab | default: true 
   */
  setConfirmedPassengers(passengers: Passenger[], useCache: boolean = true) {
    this.logger.log(`Confirmed Passengers Set: ${passengers}`);
    this.confirmedPassengers$.next(passengers);
    if (useCache) {
      sessionStorage.setItem('confirmedPassengers', JSON.stringify(passengers));
    }
  }

  mapPax(passengers: Passenger[]): Pax[] {
    const pax: Pax[] = [];
    try {
      passengers.forEach((passenger) => {
        pax.push(
          {
            cargoWeight: passenger.cargo,
            paxWeight: passenger.weight,
            totalWeight: passenger.totalWeight,
            paxName: passenger.fullName,
            origination: passenger.from,
            destination: passenger.to,
            part135Code: (passenger.part135Code != null) ? passenger.part135Code.toString() : "",
            pickLater: (passenger.pickLater ? passenger.pickLater : false)
          }
        )
      });

    } catch (error) {
      console.log("error=", error);
      this.logger.logError('Error mapping PAX');
    }

    return pax;
  }

  public mapPassengers(reservations: Reservation[]): Passenger[] {
    let passengers: Passenger[] = [];
    try {
      if (passengers) {

        passengers = reservations.map(reservation => <Passenger>this.mapPassenger(reservation));
      }
    } catch (error) {
      this.logger.logError('Error mapping reservations to passengers');
    }

    return passengers;
  }

  public mapManifestPassengers(reservations: IPassengerManifest[]): Passenger[] {
    let passengers: Passenger[] = [];
    try {
      if (passengers) {

        passengers = reservations.map(reservation => <Passenger>this.mapManifestPassenger(reservation));
      }
    } catch (error) {
      this.logger.logError('Error mapping reservations to passengers');
    }

    return passengers;
  }
  public mapPassenger(reservation: Reservation): Passenger {
    try {
      return {
        cargo: reservation.cargo,
        chargeCode: reservation.chargeCode.toString(),
        company: reservation.company.description.toString(),
        companyID: reservation.company.companyID,
        to: reservation.destination.locationName.toString(),
        firstName: reservation.firstName.toString(),
        fullName: reservation.fullName.toString(),
        lastName: reservation.lastName.toString(),
        from: reservation.origination.locationName.toString(),
        part135Code: reservation.part135?.part135Code.toString(),
        weight: reservation.passengerWeight,
        paxIndex: (!reservation.paxIndex ? 0 : reservation.paxIndex),
        reservationID: reservation.reservationID,
        totalWeight: reservation.totalWeight,
        date: new Date(reservation.reservationDate.toString()).toLocaleDateString()
      };
    } catch (error) {
      this.logger.logError(`Error mapping reservation to passenger, ${error}`);
    }
  }

  public getStringData(value: any): string {
    return (value != null) ? value.toString() : '';
  }
  public mapManifestPassenger(reservation: IPassengerManifest): Passenger {
    try {
      return {
        cargo: reservation.cargoLbs,
        chargeCode: this.getStringData(reservation.chargeCode),
        pickLater:reservation.pickLater,
        company: this.getStringData(reservation.company),
        companyID: reservation.companyId,
        to: reservation.to,
        firstName: this.getStringData(reservation.firstName),
        fullName: this.getStringData(reservation.fullName),
        lastName: this.getStringData(reservation.lastName),
        from: reservation.from,
        part135Code: this.getStringData(reservation.code135),
        weight: reservation.bodyLbs,
        paxIndex: 1,
        reservationID: reservation.reservationID,
        totalWeight: reservation.totalLbs,
        date: new Date().toLocaleDateString()
      };
    } catch (error) {
      this.logger.logError(`Error mapping reservation to passenger, ${error}`);
    }
  }
}

