import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LoggingService } from 'src/app/logging/logging.service';
import { environment } from 'src/environments/environment';
import { MasterManifestPassenger } from 'src/app/dtms/models/mastermanifestpassenger';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ManifestpassengerService {
  private api = `${environment.api}manifestpassenger/`;

  httpClient: any;
  
  constructor(private http: HttpClient, private logger: LoggingService) { }

  get(): Observable<MasterManifestPassenger[]> {
    return this.http.get<MasterManifestPassenger[]>(this.api, { headers: environment.headers })
        .pipe(
          catchError(error => {
            return this.logger.logError<MasterManifestPassenger[]>('Error retrieving passengers from api');
          })
        );
  }

  add(passenger: MasterManifestPassenger) : Observable<any> {
    return this.http.post<MasterManifestPassenger>(this.api, passenger, { headers: environment.headers })
    .pipe(
      catchError(error => {
        return this.logger.logError<MasterManifestPassenger[]>('Error adding passengers to api');
      })
    );
  }

  update(id: number, passenger: MasterManifestPassenger): Observable<any> {
    return this.http.put<MasterManifestPassenger>(`${this.api}${id}`, passenger, { headers: environment.headers })
    .pipe(
      catchError(error => {
        return this.logger.logError<MasterManifestPassenger[]>('Error while updating passengers to api');
      })
    );
  }

  // deactivate(id: number, passenger: ManifestPassenger): Observable<any> {
  //   return this.http.put<ManifestPassenger>(`${this.api}${id}`, passenger, { headers: environment.headers })
  //   .pipe(
  //     catchError(this.logger.logError<ManifestPassenger>(`Error retrieving manifestPassenger: ${passenger}`))
  //   );
  // }

  
  delete(id: number,com: MasterManifestPassenger): Observable<any> {
   
    const url = `${this.api}${id}`;

    
    return this.http.delete<MasterManifestPassenger>(url, { headers: environment.headers })
    .pipe(
      catchError(error => {
        return this.logger.logError<MasterManifestPassenger[]>('Error deleting passengers from api');
      })
    );
  }

}
