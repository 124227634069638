import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LoggingService } from 'src/app/logging/logging.service';
import { environment } from 'src/environments/environment';
import { HelicopterTypes } from 'src/app/dtms/models/helicoptertypes';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class HelicoptertypesService {
  public helicopterTypes:HelicopterTypes[] = [];
  private api = `${environment.api}helicoptertypes/`;
  
  constructor(private http: HttpClient, private logger: LoggingService) { }

  get(): Observable<HelicopterTypes[]> {
    return this.http.get<HelicopterTypes[]>(this.api, { headers: environment.headers })
        .pipe(
          catchError( error => {
            return this.logger.logError<HelicopterTypes[]>('Error retrieving helicoptertypes')
          })
        );
  }

  add(types: HelicopterTypes) : Observable<any> {
    return this.http.post<HelicopterTypes>(this.api, types, { headers: environment.headers })
    .pipe(
      catchError(error => {
        return this.logger.logError<HelicopterTypes[]>('Error while adding helicopterTypes to api');
      })
    );
  }

  update(id: number, types: HelicopterTypes): Observable<any> {
    return this.http.put<HelicopterTypes>(`${this.api}${id}`, types, { headers: environment.headers })
    .pipe(
      catchError(error => {
        return this.logger.logError<HelicopterTypes[]>('Error while updating helicopterTypes to api');
      })
    );
  }
 
  delete(id: number, type: HelicopterTypes): Observable<any> {
    const url = `${this.api}${id}`;
    
    return this.http.delete<HelicopterTypes>(url, { headers: environment.headers })
    .pipe(
      catchError(error => {
        return this.logger.logError<HelicopterTypes[]>('Error deleting helicopterTypes from api');
      })
      );
  }

}

