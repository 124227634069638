export class HelicopterTypes {
    
    public constructor(init?: Partial<HelicopterTypes>) {
        Object.assign(this, init);
    } 
        activeInactive: boolean;
        isActive?: string;
        aftCgY1: number;
        aftCgY2: number;
        aftGwX1: number;
        aftGwX2: number;
        burnPerHour: number;
        fcgY1: number;
        fcgY2: number;
        fePaxCount: number;
        fgwX1: number;
        fgwX2: number;
        hasFlightEngineer: boolean;
        helicopterTypeID: number;
        helicopterTypeName: string;
        isIFR: boolean;
        lastUpdatedBy: string;
        lastUpdatedDate: Date;
        lbsStop: number;
        maxPassengerWeight: number;
        maxToFuel: number;
        maxToCargoWeight?:number;
        maximumTow: number;
        minStop:number;
        model: string;
        passengerCapacity: number;
        tas: number;
        }