export class Passenger {
    
    public constructor(init?: Partial<Passenger>) {
        Object.assign(this, init);
    }

    fullName: string;
    date: string;
    from: string;
    to: string;
    //weight: number;
    cargo: number;
    totalWeight: number;
    company: string;
    chargeCode: string;
    weight: number;
    firstName?: string;
    lastName?: string;
    companyID?: number;
    distance?: number;
    fromLocationID?: number;
    helicopterRow?: number;
    manifestGUID?: string;
    manifestPassengerID?: number;
    part135Code?: string;
    paxIndex?: number;
    pickLater?: boolean;
    reservationID?: number;
    toLocationID?: number;
    verifyChargeCode?: boolean;
}

export enum WeightType {
    Person,
    Cargo,
    Total
}

export enum PassengerType {
    Reserved,
    Confirmed
}


