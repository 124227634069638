import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { LoggingService } from 'src/app/logging/logging.service';
import { Company } from '../models/companies';
import { CompaniesService } from './companies.service';
import { AddcompanyComponent } from '../addcompany/addcompany.component';
import { cvxActionConfiguration } from 'src/app/shared/cvxActionButton/cvxActionConfiguration';
import { TableVirtualScrollDataSource } from 'src/app/shared/tableVirtualScroll/tableVirtualScrollDataSource';
import { CvxProgressService } from 'src/app/shared/cvx-progress/cvx-progress.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { environment } from 'src/environments/environment';
import { RolesService } from 'src/app/auth/roles.service';
import { rowfadeOut } from 'src/app/shared/animations/animations';

@Component({
  selector: 'app-companies',
  templateUrl: './companies.component.html',
  styleUrls: ['./companies.component.css'],
  animations: [rowfadeOut]
})
export class CompaniesComponent implements OnInit, AfterViewInit {
  masterDataUrl: string;
  constructor(private companiesService: CompaniesService,
    public dialog: MatDialog,
    private logger: LoggingService,
    private progress: CvxProgressService,
    private rolesService: RolesService) {

  }

  public dataSource: TableVirtualScrollDataSource<Company>;

  public displayedColumns: string[] = ['select', 'description', 'isActive', "copy"];
  public selection = new SelectionModel<Company>(true, []);

  public selectedCompanies: Company[];
  public companies: Company[];
  public result: string;
  public filterValue: string;
  public hasDeletePermissions: boolean = false;
  public actions: cvxActionConfiguration[];
  public deleteRole: string;

  @ViewChild(MatSort, { static: false }) sort: MatSort;

  public ngOnInit() {
    try {
      this.initialize();  
      
    } catch (error) {
      this.logger.log(`Error Loading Companies: ${error}`);
    }
  }

  private initialize() {
    this.companies = [];
    this.selectedCompanies = [];
    this.deleteRole = environment.adminRole;
    this.hasDeletePermissions = this.rolesService.hasRole(this.deleteRole);

    // !!IMPORTANT - tvsItemSize directive will error without pre setting datasource in ngOnInit
    this.dataSource = new TableVirtualScrollDataSource<Company>();
  }

  public async ngAfterViewInit() {
    try {
      this.getCompanies();
      await this.initializeActions();

    } catch (error) {
      this.logger.log(`Error initializing data/actions: ${error}`);
    }
  }

  private async initializeActions(): Promise<void> {
    this.deleteRole = environment.adminRole;
    this.hasDeletePermissions = await this.rolesService.isInGroup(this.deleteRole);

    this.actions = [
      {
        name: 'add',
        icon: 'add',
        display: 'insert',
        disabled : false,
      },
      {
        name: 'delete',
        icon: 'delete',
        display: 'delete',
        disabled: !this.hasDeletePermissions
      }
    ];
  }

  public onAction(action: cvxActionConfiguration): void {
    if (action.name === 'add') {
      this.add();
    } else {
      this.onDelete();
    }
  }

  public onFilter(value: string): void {
    this.dataSource.filter = value.trim().toLocaleLowerCase();
  }

  public onClear(): void {
    this.filterValue = '';
    this.onFilter(this.filterValue);
  }

  public onCopyClick($event: any, copyCompanyRow: Company) {
    $event.stopPropagation();
    let row:Company = {...copyCompanyRow};
    row.companyID = 0;
    row.mode = "Add";

    let dialogConfig: MatDialogConfig = {
      maxWidth: '100vw',
      maxHeight: '100vh',
      width: '975px',
      disableClose: false,
      autoFocus: true,
      hasBackdrop: true,
      data: row
    };

    const dialogRef = this.dialog.open(AddcompanyComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        let typeData: Company = result;

        this.insert(typeData);
        return true;
      }
    });
  }

  public add() {

    let dialogConfig: MatDialogConfig = {
      width: '550px',
      disableClose: false,
      autoFocus: true,
      hasBackdrop: true
    };

    const dialogRef = this.dialog.open(AddcompanyComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        let companyData: Company = result;
        this.insert(companyData);

        return true;
      }
    });
  }

  public insert(com: Company): void {
    this.progress.spin$.next(true);
    this.companiesService.add(com).subscribe(
      result => {
        if (result !== null) {
          this.companies.push(result);
          this.setTable();
        }
        this.progress.spin$.next(false)
        this.logger.notify('Item Added', 'Dismiss');
      },
      error => {
        this.logger.logError<Company>(`Error while inserting company data: ${com.companyID}
      |${error}`);
        this.progress.spin$.next(false)
      }
    );
  }

  public edit(_$event: any, row: Company) {
    row.mode = "Edit";
    let dialogConfig: MatDialogConfig = {
      width: '550px',
      disableClose: false,
      autoFocus: true,
      hasBackdrop: true,
      data: row
    };

    const dialogRef = this.dialog.open(AddcompanyComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const i = this.companies.indexOf(row);
        this.companies[i] = result;

        this.update(result);

        return true;
      }
    });
  }

  public onSelectRow($event: any, row: Company) {
    if ($event.checked) {
      this.logger.log(row);
      this.selectedCompanies.push(row);
    } else {
      this.logger.log(row);
      this.selectedCompanies.splice(this.selectedCompanies.indexOf(row), 1);
    }
  }
  public onConfirm() {
    this.dataSource = new TableVirtualScrollDataSource<Company>(this.companies);
  }

  private getCompanies(): void {
    this.progress.spin$.next(true);
    this.companiesService.get().subscribe(
      company => {
        this.companies = company;
        this.setTable();
        this.progress.spin$.next(false);
      },
      error => {
        this.logger.log(`Errors occurred loading company, ${error}`);
        this.progress.spin$.next(false);
      }
    );
  }

  public update(com: Company): void {
    this.progress.spin$.next(true);
    this.companiesService.update(com.companyID, com).subscribe(
      _result => {
        this.setTable();
        this.progress.spin$.next(false)
        this.logger.notify('Item Updated', 'Dismiss');
      },
      error => {
        this.progress.spin$.next(false);
        this.logger.logError<Company>(`Error while updating company data: ${com.companyID}
      |${error}`);
      }
    );
  }

  public onDelete(): void {
    this.selectedCompanies.forEach(row => {
      this.delete(row);
    });
    this.selectedCompanies = [];
  }

  private setTable() {
    //this.dataSource = new TableVirtualScrollDataSource<Company>();
    this.dataSource = new TableVirtualScrollDataSource<Company>(this.companies);
    this.dataSource.sort = this.sort;
  }

  private delete(row: Company): void {
    this.companiesService.delete(row.companyID, row).subscribe(
      _ => {
        this.companies.splice(this.companies.indexOf(row), 1);
        this.selectedCompanies.splice(this.selectedCompanies.indexOf(row), 1);
        this.setTable();
        this.logger.notify('Item Deleted', 'Dismiss');
      },
      error => this.logger.logError<Company>(`Error deleting companies: ${row.companyID} | ${error}`)
    );
  }

}







