import { Injectable } from '@angular/core';
import { ITray } from 'src/app/dtms/models/tray'
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { LoggingService } from 'src/app/logging/logging.service';

@Injectable(
    {providedIn: 'root'}
)
export class TraysService {
  private api = environment.trayApi;

  constructor(private http: HttpClient, private logging: LoggingService) { }

  GetTray(id: number): Observable<ITray>
  {
    return this.http.get<ITray>(this.api, { headers: environment.headers, params: { "id": id.toString() } })
      .pipe(
        catchError(error => {
          let errorMsg: string;

          if (error.error instanceof ErrorEvent) {
            errorMsg = `${error.error.message} | Error retrieving tray id: ${id}`;
        } else {
            errorMsg = `${error.message} | Error retrieving tray id: ${id}`;
        }
        return this.logging.logError<ITray>(errorMsg); 
        })
      );
  }
 
}