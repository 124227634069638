import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { LoggingService } from 'src/app/logging/logging.service';
import { CvxProgressService } from 'src/app/shared/cvx-progress/cvx-progress.service';
import { IManifestInfo, IPassengerManifest, passengerManifestInfo, WeightType } from '../../models/manifestinfo';
import { ManifestService } from '../manifest.service';
import { rowfadeOut } from 'src/app/shared/animations/animations';
import { saveAs } from 'file-saver'

@Component({
  selector: 'app-view-manifest',
  templateUrl: './view-manifest.component.html',
  styleUrls: ['./view-manifest.component.css'],
  animations: [rowfadeOut]
})
export class ViewManifestComponent implements OnInit {
  public inboundDataSource: MatTableDataSource<IPassengerManifest>;
  public outboundDataSource: MatTableDataSource<IPassengerManifest>;
  public inFieldDataSource: MatTableDataSource<IPassengerManifest>;
  public displayedColumns: string[] = ['from', 'to', 'miles', 'passengerName', 'code', 'body', 'cargo', 'total', 'company', 'chargeCode'];
  public manifestInfo: IManifestInfo;
  public manifests: IPassengerManifest[];
  public inboundManifests: IPassengerManifest[];
  public outboundManifests: IPassengerManifest[];
  public inFieldManifests: IPassengerManifest[];
  public totalMiles: number = 0;
  public landingFuel: number = 0;
  public fuelNeeded: number = 0;
  public paxMile: boolean = false;
  public hourly: boolean = false;
  public dataLoaded: boolean;

  constructor(private manifestService: ManifestService,
    private logger: LoggingService,
    private progress: CvxProgressService,
    private route: ActivatedRoute) { }

  @ViewChild('content') content: ElementRef;

  ngOnInit(): void {
    this.inboundDataSource = new MatTableDataSource<IPassengerManifest>();
    this.outboundDataSource = new MatTableDataSource<IPassengerManifest>();
    this.inFieldDataSource = new MatTableDataSource<IPassengerManifest>();
    let manifestid = Number(this.route.snapshot.paramMap.get("id"));
    this.getManifestData(manifestid);
  }
  private getManifestData(manifestID: number): void {
    this.progress.spin$.next(true);
    this.manifestService.getManifestsByID(manifestID).subscribe(
      manifests => {
        try {
          this.manifestInfo = manifests;
          this.outboundManifests = this.manifestInfo.passengerManifestInfo.filter(a => a.from == "GAO");
          this.inboundManifests = this.manifestInfo.passengerManifestInfo.filter(a => a.to == "GAO");
          this.inFieldManifests = this.manifestInfo.passengerManifestInfo.filter(a => a.to != "GAO" && a.from != "GAO");
          this.inboundDataSource = new MatTableDataSource<IPassengerManifest>(this.inboundManifests);
          this.outboundDataSource = new MatTableDataSource<IPassengerManifest>(this.outboundManifests);
          this.inFieldDataSource = new MatTableDataSource<IPassengerManifest>(this.inFieldManifests);

          this.totalMiles = this.manifestInfo.manifestLegs.map(t => t.distance).reduce((acc, value) => acc + value, 0)
          if(this.manifestInfo.flightDataSheetInfo.flightType.slice(0,3)=="S92")
          {
          this.fuelNeeded = this.manifestInfo.manifestLegs.reduce((acc, leg) => acc + Number(leg.burn + 50) , 0)
          this.fuelNeeded= this.fuelNeeded + 750;
          }
          else if(this.manifestInfo.flightDataSheetInfo.flightType.slice(0,5)=="AW139")
          {
            this.fuelNeeded = this.manifestInfo.manifestLegs.reduce((acc, leg) => acc + Number(leg.burn + 50), 0)
            this.fuelNeeded= this.fuelNeeded + 475;
          }
          this.landingFuel = this.manifestInfo.manifestLegs.reduce((_acc, leg) => (leg.fuel - leg.burn - 50), 0)
          if (this.manifestInfo.calculationInfo.charges === "Pax Miles") {
            this.paxMile = true;
          }
          else {
            this.hourly = true;
          }
        } 
        catch (error) {
          this.logger.logError('Load manifest', error);
        }
        
        this.dataLoaded = true;
        this.progress.spin$.next(false);
      },
      error => {
        this.logger.log(`Errors occurred loading manifest, ${error}`);
        this.progress.spin$.next(false);
      }
    );
  }

  public checkboxChanged(value: boolean) {
    this.manifestInfo.flightDataSheetInfo.IFR = value;
    this.manifestInfo.calculationInfo.part135 = value;
    this.manifestInfo.calculationInfo.part91 = value;
  }

  private calculateWeightTotals(manifests: IPassengerManifest[], weightType: WeightType, _type: string): number {
    let total = 0;

    switch (weightType) {
      case WeightType.Miles: {
        total = manifests?.map(t => t.miles).reduce((acc, value) => acc + value, 0);
        break;
      }
      case WeightType.Person: {
        total = manifests?.map(t => t.bodyLbs).reduce((acc, value) => acc + value, 0);
        break;
      }
      case WeightType.Cargo: {
        total = manifests?.map(t => t.cargoLbs).reduce((acc, value) => acc + value, 0);
        break;
      }
      case WeightType.Total: {
        total = manifests?.map(t => t.totalLbs).reduce((acc, value) => acc + value, 0);
        break;
      }
      case WeightType.Pax: {
        total = manifests?.filter(a => a.bodyLbs > 0).reduce((acc, _value) => acc + 1, 0);
        break;
      }
      case WeightType.CargoCount: {
        total = manifests?.filter(a => a.bodyLbs <= 0).reduce((acc, _value) => acc + 1, 0);
        break;
      }
    }

    return total;
  }

  public calculateTotal(weightType: string, type: string): number {
    let total = 0;
    let wType: WeightType = WeightType[weightType];
    let list: passengerManifestInfo[] = [];

      if (type == "outbound") {
        total = this.calculateWeightTotals(this.outboundManifests, wType, type);
      } 
      else {
        if (type == "inbound")
        {
        total = this.calculateWeightTotals(this.inboundManifests, wType, type);
        }
        else
        {
          total = this.calculateWeightTotals(this.inFieldManifests, wType, type);
        }
      }
    return total;
  }

  public calculateWeights(weightType: string, type: string, legNumber: number): number {
    let total = 0;
    let wType: WeightType = WeightType[weightType];
    let list: passengerManifestInfo[] = [];
    let isOutbound = this.manifestInfo.manifestLegs.some(a => a.origin =="GAO" && a.legNumber==legNumber);
    let isLegNumber = this.manifestInfo.manifestLegs.some(a => a.legNumber==legNumber);
        if(isOutbound)
        {
        total = this.calculateWeightTotals(this.outboundManifests, wType, type);
        }
        else if(isLegNumber)
        {
          total = this.calculateWeightTotals(this.inboundManifests, wType, type);
        }
       
    return total;
  }

  public calculateEntries(){
    let entriesCount = 0;
    entriesCount = this.manifestInfo.passengerManifestInfo?.filter(a => a.bodyLbs > 0).reduce((acc, _value) => acc + 1, 0);
    entriesCount = entriesCount +this.manifestInfo.passengerManifestInfo?.filter(a => a.bodyLbs <= 0).reduce((acc, _value) => acc + 1, 0);
    return entriesCount;
  }
  private print() {
    this.progress.spin$.next(true);
    let manifestId = Number(this.route.snapshot.paramMap.get("id"));
    this.manifestService.print(manifestId).subscribe(
      (blobData) => {
        const file = new Blob([blobData], { type: 'application/pdf' });
        const fileUrl = URL.createObjectURL(file);
        
        // use saveAs fileUrl, fileName here to save ${manifestId}_Manifest_Resport.pdf
        // alternative is to display the pdf in the view and offer download button, this allows us to set a 
        // filename, but adds more work and gets away from using native browser behavior

        this.progress.spin$.next(false);
        window.open(fileUrl, '_blank');
      },
      (error) => {
        console.log("error=", error);
      }
    );
  }

  private download(){
    let manifestId = Number(this.route.snapshot.paramMap.get("id"));
    this.manifestService.print(manifestId).subscribe(
      (blobData) => {
        const file = new Blob([blobData],{type:'application/pdf'});
        const  fileUrl = URL.createObjectURL(file);
        let fileName = `${manifestId}_Manifest_Report.pdf`;
         saveAs(fileUrl, fileName);
      },
      (error) =>{
        console.log("error=",error);
      }
      );
   }
}
