import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { HelicoptertypesService } from './helicoptertypes.service';
import { LoggingService } from 'src/app/logging/logging.service';
import { cvxActionConfiguration } from 'src/app/shared/cvxActionButton/cvxActionConfiguration';
import { TableVirtualScrollDataSource } from 'src/app/shared/tableVirtualScroll/tableVirtualScrollDataSource';
import { HelicopterTypes } from '../models/helicoptertypes';
import { AddhelicoptertypesComponent } from '../addhelicoptertypes/addhelicoptertypes.component';
import { CvxProgressService } from 'src/app/shared/cvx-progress/cvx-progress.service';
import { MatSort } from '@angular/material/sort';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { RolesService } from 'src/app/auth/roles.service';
import { rowfadeOut } from 'src/app/shared/animations/animations';

@Component({
  selector: 'app-helicoptertypes',
  templateUrl: './helicoptertypes.component.html',
  styleUrls: ['./helicoptertypes.component.css'],
  animations: [rowfadeOut]
})
export class HelicoptertypesComponent implements OnInit, AfterViewInit {
  public dataSource: TableVirtualScrollDataSource<HelicopterTypes>;
  public displayedColumns: string[] = ['select', 'helicopterTypeName', 'burnPerHour', 'lbsStop', 'maxToFuel', 'maximumTow', 'minStop', 'model', 'passengerCapacity', 'tas'];

  public selection = new SelectionModel<HelicopterTypes>(true, []);
  public helicopterTypes: HelicopterTypes[];
  public selectedData: HelicopterTypes[];
  public filterValue: string;
  public hasDeletePermissions: boolean = false;
  public actions: cvxActionConfiguration[];
  public deleteRole: string;

  @ViewChild(MatSort, { static: false }) sort: MatSort;

  constructor(private helicoptersService: HelicoptertypesService,
    private logger: LoggingService,
    public dialog: MatDialog,
    private progress: CvxProgressService,
    private rolesService: RolesService) { }

  public ngOnInit() {
    try {
      this.initialize();

    } catch (error) {
      this.logger.log(`Error Loading Helicopter Types: ${error}`);
    }
  }

  public ngAfterViewInit(): void {
    try {
      this.getHelicopterTypes();
    } catch (error) {
      this.logger.log(`Error initializing data: ${error}`);
    }    
  }
  
  public onFilter(value: string): void {
    this.dataSource.filter = value.trim().toLocaleLowerCase();
  }

  public onClear(): void {
    this.filterValue = '';
    this.onFilter(this.filterValue);
  }

  private initialize() {
    this.helicopterTypes = [];
    this.selectedData = [];

    this.dataSource = new TableVirtualScrollDataSource<HelicopterTypes>();
  }

  public add() {
    let dialogConfig: MatDialogConfig = {
      maxWidth: '100vw',
      maxHeight: '100vh',
      width: '975px',
      disableClose: false,
      autoFocus: true,
      hasBackdrop: true

    };

    const dialogRef = this.dialog.open(AddhelicoptertypesComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        let typeData: HelicopterTypes = result;

        this.insert(typeData);
        return true;
      }
    });
  }

  public insert(types: HelicopterTypes): void {
    this.progress.spin$.next(true);
    this.helicoptersService.add(types).subscribe(
      result => {
        this.helicopterTypes.push(result);
        this.setTable();
        this.progress.spin$.next(false);
        this.logger.notify('Item Added', 'Dismiss');

        this.getHelicopterTypes();
      },
      error => {
        this.progress.spin$.next(false);
        this.logger.logError<HelicopterTypes>(`Error while inserting helicoptertypes data |${error}`);
      }
    );
  }

  public onSelectRow($event: any, row: HelicopterTypes) {
    if ($event.checked) {
      this.logger.log(row);
      this.selectedData.push(row);
    } else {
      this.logger.log(row);
      this.selectedData.splice(this.selectedData.indexOf(row), 1);
    }
  }

  public edit($event: any, row: HelicopterTypes) {
    let dialogConfig: MatDialogConfig = {
      maxWidth: '100vw',
      maxHeight: '100vh',
      width: '975px',
      disableClose: false,
      autoFocus: true,
      hasBackdrop: true,
      data: row
    };

    const dialogRef = this.dialog.open(AddhelicoptertypesComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.helicopterTypes[this.helicopterTypes.indexOf(row)] = result;
        this.update(result);

        return true;
      }
    });
  }

  private setTable() {
    this.dataSource = new TableVirtualScrollDataSource<HelicopterTypes>(this.helicopterTypes);
    this.dataSource.sort = this.sort;
  }

  public update(helicopter: HelicopterTypes): void {
    this.progress.spin$.next(true);
    this.helicoptersService.update(helicopter.helicopterTypeID, helicopter).subscribe(
      result => {
        this.setTable();
        this.progress.spin$.next(false)
        this.logger.notify('Item Updated', 'Dismiss');
      },
      error => {
        this.progress.spin$.next(false);
        this.logger.logError<HelicopterTypes>(`Error while updating helicopter data: ${helicopter.helicopterTypeID}|${error}`);
      }
    );

  }

  public onDelete(): void {
    this.selectedData.forEach(row => {
      this.delete(row);
    });
    this.setTable();
  }

  public delete(row: HelicopterTypes): void {
    this.helicoptersService.delete(row.helicopterTypeID, row).subscribe(
      _ => {
        this.selectedData.splice(this.selectedData.indexOf(row), 1);
        this.helicopterTypes.splice(this.helicopterTypes.indexOf(row), 1);
        this.setTable();
        this.logger.notify('Item Deleted', 'Dismiss');
      },
      error => this.logger.logError<HelicopterTypes>(`Error deleting helicopter: ${row.helicopterTypeID}|${error}`)
    );
  }
  
  private getHelicopterTypes(): void {
    this.progress.spin$.next(true);
    this.helicoptersService.get().subscribe(
      typesData => {
        this.helicopterTypes = typesData;
        this.setTable();
        this.progress.spin$.next(false);
      },
      error => {
        this.logger.log(`Errors occurred loading helicoptertypes, ${error}`);
        this.progress.spin$.next(false);
      }
    );
  }

}
