import { Component, OnInit } from '@angular/core';
import { LoggingService } from './logging.service';
import { Log } from './log';

@Component({
  selector: 'app-logging',
  templateUrl: './logging.component.html',
  styleUrls: ['./logging.component.css']
})
export class LoggingComponent implements OnInit {
  public log: Log;

  constructor(private logger: LoggingService) { }

  ngOnInit() {
    this.getLog();
  }

  private getLog(): void {
    this.logger.logging$().subscribe((result) => {
      this.log = <Log>result;
    })
  }

}
