import { Component, OnInit, ViewChild } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { LoggingService } from 'src/app/logging/logging.service';
import { Location } from '../models/locations';
import { LocationsService } from './locations.service';
import { AddlocationComponent } from '../addlocation/addlocation.component';
import { cvxActionConfiguration } from 'src/app/shared/cvxActionButton/cvxActionConfiguration';
import { TableVirtualScrollDataSource } from 'src/app/shared/tableVirtualScroll/tableVirtualScrollDataSource';
import { CvxProgressService } from 'src/app/shared/cvx-progress/cvx-progress.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { RolesService } from 'src/app/auth/roles.service';
import { environment } from 'src/environments/environment';
import { rowfadeOut } from 'src/app/shared/animations/animations';
@Component({
  selector: 'app-locations',
  templateUrl: './locations.component.html',
  styleUrls: ['./locations.component.css'],
  animations: [rowfadeOut]
})
export class LocationsComponent implements OnInit {

  filterValues = {};
  public dataSource: MatTableDataSource<Location>;

  public displayedColumns: string[] = ['select', 'locationName', 'latitude', 'longitude', 'variation', 'isActive', "copy"];
  public selection = new SelectionModel<Location>(true, []);
  public dataSource_confirmLocation: MatTableDataSource<Location>;

  public locations: Location[];
  public selectedLocations: Location[];
  public step = 1;
  filterSelectObj = [];
  public filterValue = '';
  public hasDeletePermissions: boolean = false;
  public actions: cvxActionConfiguration[];
  public deleteRole: string;

  @ViewChild(MatSort, { static: false }) sort: MatSort;

  constructor(
    private LocationsService: LocationsService,
    public dialog: MatDialog,
    private logger: LoggingService,
    private progress: CvxProgressService,
    private rolesService: RolesService,
  ) { }

  public ngOnInit() {
    try {
      this.initialize();

    } catch (error) {
      this.logger.log(`Error Loading Locations: ${error}`);
    }
  }

  private initialize() {
    this.selectedLocations = [];
    this.locations = [];

    this.dataSource = new TableVirtualScrollDataSource<Location>();
  }

  public async ngAfterViewInit() {
    try {
      this.getLocations();
      await this.initializeActions();

    } catch (error) {
      this.logger.log(`Error initializing data/actions: ${error}`);
    }
  }

  private async initializeActions(): Promise<void> {
    this.deleteRole = environment.adminRole;
    this.hasDeletePermissions = await this.rolesService.isInGroup(this.deleteRole);

    this.actions = [
      {
        name: 'add',
        icon: 'add',
        display: 'insert',
        disabled: false,
      },
      {
        name: 'delete',
        icon: 'delete',
        display: 'delete',
        disabled: !this.hasDeletePermissions
      }
    ];
  }

  public onAction(action: cvxActionConfiguration): void {
    if (action.name === 'add') {
      this.add();
    } else {
      this.onDelete();
    }
  }
  public onFilter(value: string): void {
    this.dataSource.filter = value.trim().toLocaleLowerCase();
  }

  public onClear(): void {
    this.filterValue = '';
    this.onFilter(this.filterValue);
  }

  public onCopyClick($event: any, copyLocationRow: Location) {
    $event.stopPropagation();
    let row:Location = {...copyLocationRow};
    row.locationID = 0;
    row.mode = "Add";
    let dialogConfig: MatDialogConfig = {
      maxWidth: '100vw',
      maxHeight: '100vh',
      width: '975px',
      disableClose: false,
      autoFocus: true,
      hasBackdrop: true,
      data: row
    };

    const dialogRef = this.dialog.open(AddlocationComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        let typeData: Location = result;
        this.insert(typeData);
        return true;
      }
    });
  }

  public add() {

    let dialogConfig: MatDialogConfig = {
      width: '550px',
      disableClose: false,
      autoFocus: true,
      hasBackdrop: true,
      data: null
    };

    const dialogRef = this.dialog.open(AddlocationComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        let location: Location = result;
        this.insert(location);
      }
    });
  }

  public insert(loc: Location): void {
    this.progress.spin$.next(true)
    this.LocationsService.add(loc).subscribe(
      (location) => {

        this.LocationsService.get(true).subscribe((locations) => {
          this.locations = [...locations];
          this.setTable();
          this.progress.spin$.next(false)
          this.logger.notify('Item Added', 'Dismiss');
        });
      },
      error => {
        this.progress.spin$.next(false);
        this.logger.logError<Location>(`Error while inserting company data: ${loc.locationName}
      |${error}`);
      }
    );

  }

  public update(loc: Location): void {
    this.progress.spin$.next(true)
    this.LocationsService.update(loc.locationID, loc).subscribe(
      result => {

        // Had to make this refresh to force expire cache, need to be refactored
        this.LocationsService.get(true).subscribe((locations) => {
          this.locations = [...locations];
                  // alternative update - https://stackoverflow.com/a/48500099/12233405
          this.locations[this.locations.findIndex(item => item.locationID === loc.locationID)] = loc;
          this.setTable();
          this.progress.spin$.next(false);
          this.logger.notify('Item Updated', 'Dismiss');
        });
      },
      error => {
        this.progress.spin$.next(false);
        this.logger.logError<Location>(`Error while updating pilot data: ${loc.heliportId}
        |${error}`);
      }

    );


  }

  public edit($event: any, row: Location) {
    row.mode = "Edit";
    let dialogConfig: MatDialogConfig = {
      width: '550px',
      disableClose: false,
      autoFocus: true,
      hasBackdrop: true,
      data: row
    };

    const dialogRef = this.dialog.open(AddlocationComponent, dialogConfig);


    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        row = result;
        this.update(row);
      }
    });
  }

  /** Whether the number of selected elements matches the total number of rows. */
  public isAllSelected($event) {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  public onCompleteColumn(dataSource) {
    dataSource.data.forEach(element => {
      this.logger.log(element);
    });
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  public masterToggle($event) {
    if ($event.checked) {
      this.dataSource.data.forEach(row => this.selection.select(row));
      this.selectedLocations = this.locations;
    } else {
      this.dataSource.data.forEach(row => this.selection.deselect(row));
      this.selectedLocations = [];
    }
  }

  public onSelectRow($event: any, row: Location) {
    if ($event.checked) {
      this.logger.log(row);
      this.selectedLocations.push(row);
    } else {
      this.logger.log(row);
      this.selectedLocations.splice(this.selectedLocations.indexOf(row), 1);
    }
  }

  private setTable() {
    this.dataSource = new TableVirtualScrollDataSource<Location>(this.locations);
    this.dataSource.sort = this.sort;
  }

  public setStep(index: number) {
    this.step = index;
  }

  public nextStep() {
    this.step--;
  }

  private getLocations(): void {
    this.progress.spin$.next(true);
    this.LocationsService.get().subscribe(
      location => {
        this.locations = location;
        this.setTable();
        this.progress.spin$.next(false);
      },
      error => {
        this.logger.log(`Errors occurred loading location, ${error}`);
        this.progress.spin$.next(false);
      }
    );
  }

  public onDelete(): void {
    this.selectedLocations.forEach(row => {
      this.delete(row);
    });
  }

  public delete(row: Location): void {
    this.LocationsService.delete(row.locationID, row).subscribe(
      _ => {
        this.selectedLocations.splice(this.selectedLocations.indexOf(row), 1);
        this.locations.splice(this.locations.indexOf(row), 1);
        this.setTable();
        this.logger.notify('Item Deleted', 'Dismiss');
      },
      error => this.logger.logError<Location>(`Error deleting pilot: ${row.locationID}|${error}`)
    );
  }

}






