import { AfterViewInit, Component, Input, NgZone, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { HelicoptersService } from '../../helicopter/helicopter.service';
import { HelicoptertypesService } from '../../helicoptertypes/helicoptertypes.service';
import { Helicopter } from '../../models/helicopter';
import { HelicopterTypes } from '../../models/helicoptertypes';
import { FlightSelection } from '../../models/flight-selection';
import { Runs } from '../../models/runs';
import { RunsService } from '../../runs/runs.service';
import { FlightSelectionService } from './flight-selection.service';
import { map, switchMap, mergeMap, filter } from 'rxjs/operators';
import { ShoreBaseJobsService } from '../../shorebasejobs/shorebasejobs.service';
import { Shorebasejob } from '../../models/shorebasejob';
import { ActivatedRoute, Router } from '@angular/router';




@Component({
  selector: 'flight-selection',
  templateUrl: './flight-selection.component.html',
  styleUrls: ['./flight-selection.component.css'],
  exportAs: 'flight-selection'
})
export class FlightSelectionComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() flightSelection: FlightSelection;
  //  @Output() flightUpdated = new EventEmitter<FlightSelection>();

  // public flightSelection: FlightSelection;
  public flightSelectionForm: FormGroup;
  public helicopters$: Observable<Helicopter[]>;             // Obervable list of helictopers for tail number dropdown
  public helicopterTypes$: Observable<HelicopterTypes[]>;     // Obervable list of helictoper types for helicopter dropdown
  public run$: Observable<Runs[]>;
  public shorebaseJobs$: Observable<Shorebasejob[]>;
  public selectedFlight: Runs;
  public selectedHelicopterType: HelicopterTypes;
  public selectedTailNumber: Helicopter;

  public runs: Runs[];
  public shorebaseJobs: Shorebasejob[];
  public helicopterTypes: HelicopterTypes[];
  public helicopters: Helicopter[];

  public jobs: string[];
  public helicoptersByhelicopterTypes: Helicopter[];

  public jobs$: Observable<string[]>;
  public manisfestId: number;

  constructor(private formBuilder: FormBuilder,
    private helicoptersService: HelicoptersService,
    private runService: RunsService,
    private helicopterTypesService: HelicoptertypesService,
    private flightSelectionService: FlightSelectionService,
    private shoreBaseJobService: ShoreBaseJobsService,
    private activatedRoute: ActivatedRoute ) { }

  public ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe(params => {
      let id = params.get('id');
      this.manisfestId = (id != undefined) ? Number(id) : 0;
    });
    this.initialize();
    this.subcribeToFormChanges();
    this.flightSelectionForm = this.formBuilder.group({
      flight: [''],
      job: [''],
      helicopterType: [''],
      tailNumber: ['']
    });
    this.flightSelectionForm.controls.flight.valueChanges.subscribe((seletedvalue)=>{
      this.onChangeFlightSelection(seletedvalue);
    });
    this.flightSelectionForm.controls.job.valueChanges.subscribe((seletedvalue)=>{
      this.flightSelectionService.setFlightSelection(this.saveChanges());
    });
    this.flightSelectionForm.controls.helicopterType.valueChanges.subscribe((seletedvalue)=>{
      this.flightSelectionService.setFlightSelection(this.saveChanges());
    });
    this.flightSelectionForm.controls.tailNumber.valueChanges.subscribe((seletedvalue)=>{
      this.flightSelectionService.setFlightSelection(this.saveChanges());
    });
     
    //this.flightSelectionForm.controls.flight.setValue('Bigfoot')
    //this.flightSelectionForm.controls.helicopterType.setValue('S92')
  }
  public ngOnChanges(changes: SimpleChanges): void {
    if (this.flightSelection) {
      this.flightSelectionForm?.controls.flight.setValue(this.flightSelection.flight.runName);
      if( this.manisfestId>0){
        this.flightSelectionForm?.controls.flight.disable();
      }
      this.onChangeFlightSelection(this.flightSelection.flight.runName);
    }
  }

  public ngAfterViewInit(): void {
    this.load();
  }

  private initialize(): void {
    if (!this.flightSelection) {
      this.flightSelectionForm = this.createForm();
    } else {
      this.flightSelectionForm = this.setForm();
    }
  }

  private createForm(): FormGroup {
    this.flightSelection = new FlightSelection();

    return this.setForm();
  }

  private setForm(): FormGroup {
    return this.formBuilder.group({
      flight: [this.flightSelection.flight, Validators.required],
      job: [{ value: this.flightSelection.job, disabled: false }, Validators.required],
      helicopterType: [this.flightSelection.helicopterType, Validators.required],
      tailNumber: [{ value: this.flightSelection.tailNumber, disabled: false }, Validators.required]
    });
  }

  private load(): void {
    this.run$ = this.runService.get();
    this.helicopterTypes$ = this.helicopterTypesService.get();
    this.shorebaseJobs$ = this.shoreBaseJobService.get();
    this.helicopters$ = this.helicoptersService.get();

    this.run$.subscribe(runs => {
      this.runs = runs;
    });

    this.helicopterTypes$.subscribe(helicopterTypes => {
      this.helicopterTypes = helicopterTypes;
      this.helicopterTypesService.helicopterTypes = helicopterTypes;
    });

    this.shorebaseJobs$.subscribe(shorebaseJobs => {
      this.shorebaseJobs = shorebaseJobs;
    });

    this.helicopters$.subscribe(helicopters => {
      this.helicopters = helicopters;
      this.helicoptersService.helicopters = helicopters;
    });

  }

  private onChangeFlightSelection(selectedValue): void {
      this.run$?.pipe(
      map((runs: Runs[]) => {
        return runs.filter(a => a.runName === selectedValue)
      }
      )).subscribe((runs: Runs[]) => {
        let selectedFlight = runs[0];
        this.flightSelection.flight =selectedFlight;
        this.jobs = this.setJobs(selectedFlight, this.helicopterTypes, this.shorebaseJobs).sort();
        if (this.flightSelection) {
          this.flightSelectionForm.controls.job.setValue(this.flightSelection.job);
          if(this.flightSelection.helicopterType) {
            this.flightSelectionForm.controls.helicopterType.setValue(this.flightSelection.helicopterType.helicopterTypeID)
          }
          this.onHelicopterTypeSelection(this.flightSelection.helicopterType.helicopterTypeID);
        }
      });
  }

  private onHelicopterTypeSelection(selectedValue) {

    this.helicopterTypes$.pipe(
      map((helicopterTypes: HelicopterTypes[]) => {
        return helicopterTypes.filter(a => a.helicopterTypeID === selectedValue)
      }
      )).subscribe((helicopterTypes: HelicopterTypes[]) => {
        let selectedhelicopterTypes = helicopterTypes[0];
        this.helicoptersByhelicopterTypes = this.setHelicopters(selectedhelicopterTypes, this.helicopters).sort();
        if (this.flightSelection) {
          this.flightSelectionForm.controls.tailNumber.setValue(this.flightSelection.tailNumber.tailNumber);
          this.flightSelectionService.setFlightSelection(this.saveChanges());
        }
      });
  }

  private subcribeToFormChanges(): void {
    
    this.flightSelectionForm
      .valueChanges
      .subscribe(result => {
        this.flightSelectionService.setFlightSelection(this.saveChanges());
      });
    // Set job types based on selceted run
    this.jobs$ = this.flightSelectionForm.get('flight')
      .valueChanges.pipe(
        switchMap((flight: Runs) => this.helicopterTypes$.pipe(
          mergeMap(helicopterTypes => this.shoreBaseJobService.get().pipe(
            map(shoreBaseJobs => {
              return this.setJobs(flight, helicopterTypes, shoreBaseJobs).sort()
            })
          ))
        )
        ))

    this.flightSelectionForm.get('flight')
      .valueChanges.subscribe((e) => console.log("FLIGHT CHANGED", e));



    // Set helicopters$ based on selected type
    this.helicopters$ = this.flightSelectionForm.get('helicopterType').valueChanges
      .pipe(
        switchMap(type => this.helicoptersService.get().pipe(
          map(helicopters => this.setHelicopters(type, helicopters)))));
  }

  /**
   * @function setHelicopters
   * @summary Filter helicopters by type
   * @param type helicopter type
   * @param helicopters list of helicopters to filter
   * @returns transformed helicopter list
   */
  private setHelicopters(type: HelicopterTypes, helicopters: Helicopter[]): Helicopter[] {
    let filtered = helicopters.filter(helicopter => helicopter.helicopterTypes.helicopterTypeID === type.helicopterTypeID);

    this.flightSelectionForm.get('tailNumber').enable();

    return filtered;
  }

  /**
  * @function setJobs
  * @summary Filter job by selected Run
  * @param runs runs type
  * @returns transformed jobs array
  */
  private setJobs(runs: Runs, helicopterTypes: HelicopterTypes[], shoreBaseJobs: Shorebasejob[]): string[] {
    return shoreBaseJobs.filter(sb => (sb.shoreBaseID === runs.shoreBaseID) &&
      helicopterTypes.map(h => h.helicopterTypeID).includes(sb.helicopterTypeID))
      .map(
        s => {
          return helicopterTypes.find(h => h.helicopterTypeID === s.helicopterTypeID).helicopterTypeName + "#" + s.jobNumber
        })

  }

  private saveChanges(): FlightSelection {
    let flights = this.runs.filter(a => a.runName == this.flightSelectionForm.controls.flight.value);
    if (flights.length > 0) {
      this.selectedFlight = flights[0];
    }
    
    let helicoptertypes = this.helicopterTypes.filter(a => a.helicopterTypeID == this.flightSelectionForm.controls.helicopterType.value);
    if (helicoptertypes.length > 0) {
      this.selectedHelicopterType= helicoptertypes[0];
    }
    if(  this.helicoptersByhelicopterTypes){
    let tailnumbers = this.helicoptersByhelicopterTypes.filter(a => a.tailNumber == this.flightSelectionForm.controls.tailNumber.value);
    if (tailnumbers.length > 0) {
      this.selectedTailNumber = tailnumbers[0];
    }
  }
    const selection: FlightSelection = {
      flight: this.selectedFlight,
      job: this.flightSelectionForm.controls.job.value ,
      helicopterType:this.selectedHelicopterType,
      tailNumber: this.selectedTailNumber
    }
    return selection;
  }
}